import React, { useState } from 'react';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { Select } from 'chakra-react-select';
import { researcherStudySiteRoles } from '../data/types';
import { studies } from '../api/api-wrapper';

const initialValues = {
  role: {
    value: 3,
    label: 'Patient Facing',
  },
};

const JoinStudySiteModal = ({
  studySiteToJoin,
  isOpen,
  onClose,
  mutate,
}) => {
  const initialRef = React.useRef(null);
  const [serverError, setServerError] = useState('');
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: initialValues,
  });

  const onSubmit = async (data) => {
    try {
      const res = await studies.linkResearcherToStudySite(
        studySiteToJoin.id,
        {
          role: data.role.value,
        },
      );

      if (res.status !== 201) {
        setServerError(res.data?.[0]);
      } else {
        mutate();
        onClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      {studySiteToJoin && (
        <Modal
          isOpen={isOpen}
          onClose={handleClose}
          size="md"
          initialFocusRef={initialRef}
          closeOnOverlayClick={false}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Join Study Site</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <form id="join-form" onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={4}>
                  <FormControl>
                    <FormLabel>Site</FormLabel>
                    <Text>{studySiteToJoin.siteName}</Text>
                  </FormControl>
                  <FormControl isInvalid={!!serverError}>
                    <FormLabel>Site Role</FormLabel>
                    <Controller
                      as={Select}
                      focusBorderColor="primary.400"
                      selectedOptionColor="primary"
                      control={control}
                      name="role"
                      options={researcherStudySiteRoles}
                      isSearchable
                    />
                    <FormErrorMessage>{serverError}</FormErrorMessage>
                  </FormControl>
                </Stack>
              </form>
            </ModalBody>

            <ModalFooter>
              <Button onClick={onClose} mr={4}>
                Cancel
              </Button>
              <Button
                colorScheme="secondary"
                isLoading={isSubmitting}
                type="submit"
                form="join-form"
              >
                Join
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default JoinStudySiteModal;
