import React from 'react';
import SquareLoader from './SquareLoader';
import { Flex } from '@chakra-ui/react';

const Loader = ({ loadingText }) => {
  return (
    <Flex h="full" direction="column" justify="center" align="center">
      <SquareLoader />
      <div>{loadingText}</div>
    </Flex>
  );
};

export default Loader;
