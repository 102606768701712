import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAuth } from '../context/authContext';
import { Box, Flex, Heading, Link, Text } from '@chakra-ui/react';
import Logo from '../components/Logo';

const AccountActivation = () => {
  const { push } = useHistory();
  const { activateAccount } = useAuth();
  const [counter, setCounter] = useState(5);
  const [isActivating, setIsActivating] = useState(true);
  const [error, setError] = useState(false);
  const { uid, token } = useParams();

  const activate = useCallback(async () => {
    try {
      await activateAccount({ uid, token });
    } catch (error) {
      setError(true);
    } finally {
      setIsActivating(false);
    }
  }, [activateAccount, uid, token]);

  useEffect(() => {
    activate();
  }, [activate]);

  useEffect(() => {
    if (!isActivating && !error) {
      if (counter > 0) {
        setTimeout(() => setCounter(counter - 1), 1000);
      } else {
        push('/login');
      }
    }
  }, [counter, isActivating, error, push]);

  return (
    <Box
      maxW={{ base: 'xl', md: '7xl' }}
      mx="auto"
      px={{
        base: '6',
        md: '12',
      }}
      py={{
        base: '8',
        md: '16',
      }}
    >
      <Flex direction="column" align="center">
        <Logo h="32" mb={10} />
        {isActivating ? (
          <Heading size="lg">Activating your account...</Heading>
        ) : error ? (
          <>
            <Heading size="lg" mb="8">
              Sorry, we're having trouble activating your account!
            </Heading>
            <Text>
              Please try again or contact{' '}
              <Link
                href="mailto:help@healtwallet.app"
                color="secondary.accent-200"
              >
                help@healthwallet.app
              </Link>{' '}
              if the problem persists.
            </Text>
          </>
        ) : (
          <>
            <Heading size="lg" mb="8">
              Your account has been activated!
            </Heading>
            <Text>
              You will be redirected automatically in {counter}{' '}
              seconds.
            </Text>
          </>
        )}
      </Flex>
    </Box>
  );
};

export default AccountActivation;
