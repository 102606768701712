import React from 'react';
import { createPortal } from 'react-dom';
import Loader from './Loader';
import { Box } from '@chakra-ui/react';

const LoadingScreen = ({ loadingText, show }) => {
  const domEl = document.getElementById('loader-root');

  if (!domEl) {
    return null;
  }

  return createPortal(
    show && (
      <Box
        position="absolute"
        top={0}
        left={0}
        zIndex={5}
        h="100vh"
        w="100vw"
        overflow="hidden"
        bg="white"
      >
        <Loader loadingText={loadingText} />
      </Box>
    ),
    domEl,
  );
};

export default LoadingScreen;
