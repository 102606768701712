import React from 'react';
import styled, { keyframes, css } from 'styled-components/macro';

const SquareLoader = () => {
  // prevent jumping of animation if loaders render sequentially
  // https://www.selbekk.io/blog/2019/08/how-to-stop-your-spinner-from-jumping-in-react/
  const mountTime = React.useRef(Date.now() % 9000);

  return (
    <LoadingWrapper
      style={{ '--spinner-delay': `-${mountTime.current}ms` }}
    >
      <LoadingSquare />
      <LoadingSquare />
      <LoadingSquare />
      <LoadingSquare />
      <LoadingSquare />
      <LoadingSquare />
      <LoadingSquare />
    </LoadingWrapper>
  );
};

const size = '20px';
const padding = `calc(${size} / 8)`;

const squareAnimation = keyframes`
  0% {left: 0; top: 0}

  10.5% {left: 0; top: 0;}
  12.5% {left: ${size}; top: 0;}

  23% {left: ${size}; top: 0;}
  25% {left: ${`calc(${size} * 2)`}; top: 0;}

  35.5% {left: ${`calc(${size} * 2)`}; top: 0;}
  37.5% {left: ${`calc(${size} * 2)`}; top: ${size};}

  48% {left: ${`calc(${size} * 2)`}; top: ${size};}
  50% {left: ${size}; top: ${size};}

  60.5% {left: ${size}; top: ${size};}
  62.5% {left: ${size}; top: ${`calc(${size} * 2)`};}

  73% {left: ${size}; top: ${`calc(${size} * 2)`};}
  75% {left: 0; top: ${`calc(${size} * 2)`};}

  85.5% {left: 0; top: ${`calc(${size} * 2)`};}
  87.5% {left: 0; top: ${size};}

  98% {left: 0; top: ${size};}
  100% {left: 0; top: 0;}
`;

const hueRotate = keyframes`
  0% {filter: hue-rotate(0deg)}
  100% {filter: hue-rotate(360deg)}
`;

const LoadingWrapper = styled.div`
  position: relative;
  width: ${`calc(${size} * 3)`};
  height: ${`calc(${size} * 3)`};
  transform: rotate(45deg);
  animation: ${hueRotate} 9s linear infinite both;
  animation-delay: var(--spinner-delay);
  margin-bottom: 15px;
`;

const animateSquares = () => {
  let styles = '';

  for (let i = 0; i <= 7; i += 1) {
    styles += `
      &:nth-of-type(${i}) {
        animation-delay: calc(var(--spinner-delay) - ${
          (9 / 7) * i * 1000
        }ms)
      }
     `;
  }

  return css`
    ${styles}
  `;
};

const LoadingSquare = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${`calc(${size} - ${padding})`};
  height: ${`calc(${size} - ${padding})`};
  margin: 2px;
  border-radius: 2px;
  background: #07a;
  background-image: linear-gradient(45deg, #fa0 40%, #0c9 60%);
  background-image: -moz-linear-gradient(#fa0, #fa0);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  animation: ${squareAnimation} 9s ease-in-out infinite both;
  animation-delay: var(--spinner-delay);

  ${animateSquares()}
}`;

export default SquareLoader;
