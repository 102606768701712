import React from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Textarea,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { studies } from '../api/api-wrapper';

const EditStudyModal = ({ study, isOpen, onClose, mutate }) => {
  const initialRef = React.useRef(null);

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm();

  const onSubmit = async (data) => {
    try {
      const res = await studies.editResearcherStudy(
        study.healthwallet_id,
        {
          ...study,
          ...data,
        },
      );

      if (res.status !== 200) {
        console.log({ res });
      } else {
        mutate(); // can mutate with the local data here
        onClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      size="xl"
      initialFocusRef={initialRef}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Study</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <form
            id="edit-study-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Stack spacing={4}>
              <FormControl>
                <FormLabel>Nickname</FormLabel>
                <Controller
                  as={Input}
                  control={control}
                  name="nickname"
                  defaultValue={study?.nickname}
                  focusBorderColor="primary.400"
                  borderColor="primary.300"
                  shadow="sm"
                  _hover={{
                    borderColor: 'primary.400',
                  }}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Layman Description</FormLabel>
                <Controller
                  as={Textarea}
                  control={control}
                  name="layman_description"
                  defaultValue={study?.layman_description}
                  focusBorderColor="primary.400"
                  borderColor="primary.300"
                  shadow="sm"
                  _hover={{
                    borderColor: 'primary.400',
                  }}
                />
              </FormControl>
            </Stack>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose} mr={4}>
            Cancel
          </Button>
          <Button
            colorScheme="secondary"
            isLoading={isSubmitting}
            type="submit"
            form="edit-study-form"
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditStudyModal;
