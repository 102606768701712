import React, { useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAuth } from '../context/authContext';
import { isAfter, parseISO } from 'date-fns';
import { useUser } from '../context/userContext';
import NavBar from './NavBar';
import { useLoader } from '../hooks/useLoader';

const PrivateRoute = ({
  component: Component,
  path,
  showMenu,
  ...props
}) => {
  const {
    isAuthenticated,
    authExpiration,
    setAuthenticated,
    setAuthExpiration,
  } = useAuth();

  const { isLoading: userLoading, user } = useUser();
  const { show, hide, RenderLoader } = useLoader();

  useEffect(() => {
    if (userLoading || !user || Object.keys(user).length < 1) {
      show();
    } else {
      hide();
    }
  }, [userLoading, user, show, hide]);

  useEffect(() => {
    if (
      !isAuthenticated ||
      (authExpiration &&
        isAfter(new Date(), parseISO(authExpiration)))
    ) {
      // console.log('private route check failed');
      setAuthExpiration('');
      setAuthenticated(false);
      localStorage.setItem('token', JSON.stringify(''));
      localStorage.setItem('active-profile-id', JSON.stringify(''));
    }
  }, [
    isAuthenticated,
    authExpiration,
    setAuthExpiration,
    setAuthenticated,
  ]);

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  const render = (props) => {
    return (
      <>
        {userLoading ||
        !user ||
        Object.keys(user).length < 1 ? null : (
          <>
            <NavBar />
            <Component {...props} />
          </>
        )}
        <RenderLoader loadingText="Retrieving your account..." />
      </>
    );
  };

  return <Route path={path} render={render} {...props} />;
};

export default PrivateRoute;
