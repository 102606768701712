import React, { useState } from 'react';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  CloseButton,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  ListItem,
  Stack,
  Textarea,
  UnorderedList,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { studies } from '../api/api-wrapper';

const initialErrors = [];
const initialFormValues = {
  formalName: '',
  nickname: '',
  description: '',
  laymanDescription: '',
};

//TODO: isNct should be query string?  both params should?
const CreateStudy = () => {
  const { id, isNct } = useParams();
  const { push } = useHistory();

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      ...initialFormValues,
    },
  });
  const [serverError, setServerError] = useState(initialErrors);

  const resetErrors = () => {
    setServerError(initialErrors);
  };

  const onSubmit = async (data) => {
    const req = {
      ...(!!isNct ? { nct_id: id } : { study_id: id }),
      nickname: data.nickname,
      formal_name: data.formalName,
      description: data.description,
      layman_description: data.laymanDescription,
    };

    try {
      const res = await studies.createResearcherStudy(req);

      if (res.status !== 201) {
        setServerError(Object.entries(res.data));
      } else {
        push(`/researcher/study/${res.data.healthwallet_id}`);
      }
    } catch (error) {
      console.log('handle submit error', error.message);
    }
  };

  //TODO: what's required here
  return (
    <Flex direction="column" w="full">
      <Heading mb="8">{`Create Study ${id}`}</Heading>
      {serverError.length > 0 && (
        <Alert status="error" mb="8">
          <Flex flexDirection="column">
            <Flex flexDirection="row">
              <AlertIcon />
              <AlertTitle>Sorry, there was an error</AlertTitle>
            </Flex>
            <UnorderedList px="8">
              {serverError.map((error, index) => {
                return (
                  <ListItem key={index}>
                    {error[0] + ' - ' + error[1]}
                  </ListItem>
                );
              })}
            </UnorderedList>
          </Flex>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={resetErrors}
          />
        </Alert>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4} mb={8}>
          <FormControl>
            <FormLabel>Formal Name</FormLabel>
            <Controller
              as={Input}
              control={control}
              name="formalName"
              focusBorderColor="primary.400"
              borderColor="primary.300"
              shadow="sm"
              _hover={{
                borderColor: 'primary.400',
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Nickname</FormLabel>
            <Controller
              as={Input}
              control={control}
              name="nickname"
              focusBorderColor="primary.400"
              borderColor="primary.300"
              shadow="sm"
              _hover={{
                borderColor: 'primary.400',
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Description</FormLabel>
            <Controller
              as={Textarea}
              control={control}
              name="description"
              focusBorderColor="primary.400"
              borderColor="primary.300"
              shadow="sm"
              _hover={{
                borderColor: 'primary.400',
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Layman Description</FormLabel>
            <Controller
              as={Textarea}
              control={control}
              name="laymanDescription"
              focusBorderColor="primary.400"
              borderColor="primary.300"
              shadow="sm"
              _hover={{
                borderColor: 'primary.400',
              }}
            />
          </FormControl>
        </Stack>
        <Flex justify="end">
          <Button
            w="fit-content"
            type="submit"
            colorScheme="secondary"
            size="lg"
            isLoading={isSubmitting}
            loadingText="Saving..."
          >
            Create Study
          </Button>
        </Flex>
      </form>
    </Flex>
  );
};

export default CreateStudy;
