import {
  Box,
  chakra,
  HStack,
  Text,
  useId,
  useRadio,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { CheckCircle, Circle } from 'phosphor-react';

const RadioBox = chakra('div', {
  baseStyle: {
    borderWidth: '3px',
    py: '4',
    borderRadius: 'md',
    cursor: 'pointer',
    transition: 'all 0.2s',
  },
});

const CheckboxIcon = ({ checked }) => (
  <Box fontSize="2xl" color={checked ? 'secondary.600' : 'gray.300'}>
    {checked ? <CheckCircle /> : <Circle />}
  </Box>
);

export const ButtonRadio = ({ label, icon, ...rest }) => {
  const { getCheckboxProps, getInputProps, getLabelProps, state } =
    useRadio(rest);
  const id = useId();
  const checkedStyles = {
    bg: 'secondary.50',
    borderColor: 'secondary.600',
  };
  return (
    <label
      style={{
        width: '100%',
        minWidth: '175px',
      }}
      {...getLabelProps()}
    >
      <input {...getInputProps()} aria-labelledby={id} />
      <RadioBox
        {...getCheckboxProps()}
        _checked={checkedStyles}
        id={id}
      >
        <VStack spacing="4">
          <HStack>
            <Box
              aria-hidden
              fontSize="3xl"
              color={state.isChecked ? 'secondary.600' : undefined}
            >
              {icon}
            </Box>
            <Text fontWeight="extrabold" fontSize="md">
              {label}
            </Text>
          </HStack>
          <CheckboxIcon checked={state.isChecked} />
        </VStack>
      </RadioBox>
    </label>
  );
};
