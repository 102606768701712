export const colors = {
  primary: {
    50: '#EAF0F0',
    100: '#CBD9D8',
    200: '#ABC2C1',
    300: '#8CABAA',
    400: '#6D9593',
    500: '#4D7E7B',
    600: '#2E6764',
    700: '#275855',
    800: '#204846',
    900: '#193937',
    'accent-50': '#DDF9ED',
    'accent-100': '#C4F5DF',
    'accent-200': '#AAF0D1',
    'accent-300': '#77A892',
  },
  secondary: {
    50: '#E8EAF6',
    100: '#C5CAE9',
    200: '#9FA8DA',
    300: '#7986CB',
    400: '#5C6BC0',
    500: '#3F51B5',
    600: '#3949AB',
    700: '#303F9F',
    800: '#283593',
    900: '#1A237E',
    'accent-100': '#8C9EFF',
    'accent-200': '#536DFE',
    'accent-400': '#3D5AFE',
    'accent-700': '#304FFE',
  },
  gray: {
    50: '#FAFAFA',
    100: '#F5F5F5',
    200: '#EEEEEE',
    300: '#E0E0E0',
    400: '#BDBDBD',
    500: '#9E9E9E',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
  },
  'blue-gray': {
    50: '#ECEFF1',
    100: '#CFD8DC',
    200: '#B0BEC5',
    300: '#90A4AE',
    400: '#78909C',
    500: '#607D8B',
    600: '#546E7A',
    700: '#455A64',
    800: '#37474F',
    900: '#263238',
  },
  red: {
    50: '#FFEEEE',
    100: '#FACDCD',
    200: '#F29B9B',
    300: '#E66A6A',
    400: '#D64545',
    500: '#BA2525',
    600: '#A61B1B',
    700: '#911111',
    800: '#780A0A',
    900: '#610404',
  },
  yellow: {
    50: '#FFFAEB',
    100: '#FCEFC7',
    200: '#F8E3A3',
    300: '#F9DA8B',
    400: '#F7D070',
    500: '#E9B949',
    600: '#C99A2E',
    700: '#A27C1A',
    800: '#7C5E10',
    900: '#513C06',
  },
  blue: {
    50: '#DCEEFB',
    100: '#B6E0FE',
    200: '#84C5F4',
    300: '#62B0E8',
    400: '#4098D7',
    500: '#2680C2',
    600: '#186FAF',
    700: '#0F609B',
    800: '#0A558C',
    900: '#003E68',
  },
  purple: {
    50: '#EAE2F8',
    100: '#CFBCF2',
    200: '#A081D9',
    300: '#8662C7',
    400: '#724BB7',
    500: '#653CAD',
    600: '#51279B',
    700: '#421987',
    800: '#34126F',
    900: '#240754',
  },
};
