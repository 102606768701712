import React, { useState } from 'react';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  chakra,
  CloseButton,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  UnorderedList,
} from '@chakra-ui/react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { admin } from '../api/api-wrapper';
import {
  gender as sexAtBirthOptions,
  months,
  states,
} from '../data/types';
import ConditionGroupInput from './ConditionGroupInput';
import { Plus } from 'phosphor-react';
import { generateYearOptions } from '../util/helpers';
import { mutate } from 'swr';
import { Select } from 'chakra-react-select';

const initialErrors = [];
const initialFormValues = {
  label: '',
  birthMonth: null,
  birthYear: null,
  sexAtBirth: null,
  state: '',
  condition: [{ name: null }],
};

const CreateProfileModal = ({ isOpen, onClose }) => {
  const {
    handleSubmit,
    errors,
    control,
    formState: { isSubmitting },
    reset,
    clearErrors,
  } = useForm({
    defaultValues: initialFormValues,
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'condition',
  });
  const [serverError, setServerError] = useState(initialErrors);

  const resetErrors = () => {
    setServerError(initialErrors);
  };

  const handleOnCancel = () => {
    reset();
    clearErrors();
    onClose();
  };

  const handleSaveProfile = async (data) => {
    try {
      //transform condition array into just string array of names
      let conditionArr = data.condition.reduce((acc, c) => {
        acc.push(c.name.value);
        return acc;
      }, []);

      // filter out duplicate or falsey values
      conditionArr = [...new Set(conditionArr)].filter(Boolean);

      const req = {
        label: data.label,
        sex_at_birth: data.sexAtBirth.value,
        state: data.state.value,
        partial_birthdate:
          data.birthYear.value + '-' + data.birthMonth.value,
        conditions: conditionArr,
      };

      const res = await admin.createSeekerProfile(req);

      if (res.error) {
        setServerError(res.error);
      } else {
        mutate('profiles/', null, true);
        onClose();
      }
    } catch (error) {
      console.log('create profile error', error.message);
      setServerError(error.message);
    }
  };

  const addCondition = () => {
    append({ name: [] });
  };

  const removeCondition = (index) => () => {
    remove(index);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create a profile</ModalHeader>
        <form
          id="create-profile-form"
          onSubmit={handleSubmit(handleSaveProfile)}
        >
          <ModalBody pb={6}>
            {serverError.length > 0 && (
              <Alert status="error" mb="8">
                <Flex flexDirection="column">
                  <Flex flexDirection="row">
                    <AlertIcon />
                    <AlertTitle>Sorry, there was an error</AlertTitle>
                  </Flex>
                  <UnorderedList px="8">
                    {serverError.map((error, index) => (
                      <ListItem key={index}>{error}</ListItem>
                    ))}
                  </UnorderedList>
                </Flex>
                <CloseButton
                  position="absolute"
                  right="8px"
                  top="8px"
                  onClick={resetErrors}
                />
              </Alert>
            )}
            <Stack spacing={4}>
              <FormControl
                id="label"
                isInvalid={errors.label}
                isRequired
              >
                <FormLabel>Label</FormLabel>
                <Controller
                  as={Input}
                  control={control}
                  rules={{ required: 'Label is required' }}
                  name="label"
                  focusBorderColor="primary.400"
                  borderColor="primary.300"
                  shadow="sm"
                  _hover={{
                    borderColor: 'primary.400',
                  }}
                />
                <FormErrorMessage>
                  {errors.label?.message}
                </FormErrorMessage>
              </FormControl>
              <FormLabel>
                Birthdate <chakra.span color="red.500">*</chakra.span>
              </FormLabel>
              <Stack
                direction={{ base: 'column', md: 'row' }}
                spacing="4"
              >
                <FormControl
                  id="birthMonth"
                  isInvalid={errors.birthMonth}
                >
                  <Controller
                    as={Select}
                    control={control}
                    name="birthMonth"
                    rules={{
                      required: 'Month is required',
                    }}
                    error={errors.birthMonth}
                    options={months}
                    isSearchable
                  />
                  <FormErrorMessage>
                    {errors.birthMonth?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  id="birthYear"
                  isInvalid={errors.birthYear}
                >
                  <Controller
                    as={Select}
                    control={control}
                    name="birthYear"
                    rules={{
                      required: 'Year is required',
                    }}
                    error={errors.birthYear}
                    options={generateYearOptions()}
                    isSearchable
                  />
                  <FormErrorMessage>
                    {errors.birthYear?.message}
                  </FormErrorMessage>
                </FormControl>
              </Stack>
              <FormControl
                id="sexAtBirth"
                isInvalid={errors.sexAtBirth}
                isRequired
              >
                <FormLabel>Sex At Birth</FormLabel>
                <Controller
                  as={Select}
                  control={control}
                  name="sexAtBirth"
                  rules={{
                    required: 'Sex at birth is required',
                  }}
                  error={errors.sexAtBirth}
                  options={sexAtBirthOptions}
                />
                <FormErrorMessage>
                  {errors.sexAtBirth?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                id="state"
                isInvalid={errors.state}
                isRequired
              >
                <FormLabel>State</FormLabel>
                <Controller
                  as={Select}
                  control={control}
                  name="state"
                  rules={{
                    required: 'State is required',
                  }}
                  error={errors.state}
                  options={states}
                  isSearchable
                />
                <FormErrorMessage>
                  {errors.state?.message}
                </FormErrorMessage>
              </FormControl>
              {fields.map((item, index) => {
                return (
                  <ConditionGroupInput
                    item={item}
                    control={control}
                    error={
                      errors.condition?.length &&
                      errors.condition[index]
                    }
                    key={item.id}
                    index={index}
                    removable={fields.length > 1}
                    remove={removeCondition(index)}
                  />
                );
              })}
              <Button
                w={{ base: 'full', md: 'fit-content' }}
                variant="outline"
                colorScheme="secondary"
                size="md"
                leftIcon={<Box as={Plus} fontSize="2xl" />}
                disabled={fields.length >= 3}
                onClick={addCondition}
              >
                Add Condition
              </Button>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              mr={4}
              variant="outline"
              size="lg"
              disabled={isSubmitting}
              onClick={handleOnCancel}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              colorScheme="primary"
              size="lg"
              isLoading={isSubmitting}
              loadingText="Saving..."
            >
              Save
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default CreateProfileModal;
