import React from 'react';

export const generateYearOptions = () => {
  const arr = [];

  const startYear = 1900;
  const endYear = new Date().getFullYear();

  for (let i = endYear; i >= startYear; i--) {
    arr.push({ value: i, label: i });
  }

  return arr;
};

export const createSelectOptions = (list) => {
  let options = list.map((v) => (
    <option key={v.value} value={v.value}>
      {v.displayName}
    </option>
  ));

  options.unshift(
    <option key="select" disabled defaultValue value="">
      Select an Option
    </option>,
  );

  return options;
};

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const isEmailValid = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const isAllLetter = (string) => {
  return /^[A-Za-z]+$/.test(string);
};

export const isAllNumber = (number) => {
  return /^([0-9]{10})+$/.test(number);
};
