import { apiClient } from '../../api/api-wrapper';
import { useAuth } from '../../context/authContext';
import useSWR from 'swr';

const fetcher = (...args) =>
  apiClient.get(...args).then((res) => res.data);

const multiFetcher = (...urls) => {
  const f = (u) => apiClient.get(u).then((r) => r.data);

  if (urls.length >= 1) {
    return Promise.all(urls.map(f));
  }

  return f(urls);
};

export const useGetProtocolsByStudyId = (studyId) => {
  const { isAuthenticated, token } = useAuth();
  const { data, error, isValidating, mutate } = useSWR(
    isAuthenticated && token && studyId
      ? `protocols/?study=${studyId}`
      : null,
    fetcher,
    {
      revalidateOnFocus: false,
    },
  );

  return {
    protocols: data?.results || [],
    isLoading: !data || isValidating,
    isError: error,
    mutate,
  };
};

export const useGetProtocolById = (protocolId) => {
  const { isAuthenticated, token } = useAuth();
  const {
    data: protocol,
    error,
    isValidating,
    mutate,
  } = useSWR(
    isAuthenticated && token && protocolId
      ? `protocols/${protocolId}/`
      : null,
    fetcher,
    {
      revalidateOnFocus: false,
    },
  );

  //TODO: other states
  const { data: arms } = useSWR(
    () => protocol.arms.map((arm) => `/arms/${arm}/`),
    multiFetcher,
    {
      revalidateOnFocus: false,
    },
  );

  return {
    protocol: protocol || {},
    arms: arms || [],
    isLoading: !protocol || isValidating,
    isError: error,
    mutate,
  };
};
