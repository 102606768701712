export const preferredLanguage = [
  { value: 0, label: 'English' },
  { value: 1, label: 'Spanish' },
];

export const gender = [
  { value: 0, label: 'Male' },
  { value: 1, label: 'Female' },
  // { value: 3, label: 'Non-Binary' },
  // { value: 4, label: 'Prefer not to say' },
  // { value: 8, label: 'Other' },
];

export const race = [
  { value: 0, label: 'Black or African' },
  { value: 1, label: 'East Asian (i.e. Japanese, Chinese, Korean)' },
  {
    value: 2,
    label: 'South East Asian (i.e. Filipino, Vietnamese, Thai)',
  },
  {
    value: 3,
    label: 'South Asian (i.e. Indian, Pakistani, Bangladeshi)',
  },
  { value: 4, label: 'Native American' },
  { value: 5, label: 'Pacific Islander or Hawaiian' },
  { value: 6, label: 'White' },
  { value: 7, label: 'Prefer Not to Say' },
  { value: 8, label: 'Unknown' },
  { value: 9, label: 'Other' },
];

export const ethnicity = [
  { value: 0, label: 'Hispanic or Latino' },
  { value: 1, label: 'Not Hispanic or Latino' },
  { value: 2, label: 'Prefer Not to say' },
  { value: 3, label: 'Unknown' },
  { value: 4, label: 'Other' },
];

export const yesNo = [
  { value: 'true', label: 'Yes' },
  { value: 'false', label: 'No' },
];

export const contactPreference = [
  { value: 0, label: 'Email' },
  { value: 1, label: 'Text' },
  { value: 2, label: 'Phone' },
];

export const referral = [
  { value: 0, label: 'Internet Search' },
  { value: 1, label: 'Online Advertisement or Social Media' },
  { value: 2, label: 'Hospital or Healthcare provider' },
  { value: 3, label: 'Another Health Wallet user' },
  { value: 4, label: 'Friend or Family Member' },
  { value: 5, label: 'Poster/Postcard/Flyer' },
  { value: 6, label: 'Community Group' },
  { value: 7, label: 'Postal Mail' },
  { value: 8, label: 'Other' },
];

export const states = [
  { value: 'AL', label: 'Alabama' },
  { value: 'AK', label: 'Alaska' },
  { value: 'AZ', label: 'Arizona' },
  { value: 'AR', label: 'Arkansas' },
  { value: 'CA', label: 'California' },
  { value: 'CO', label: 'Colorado' },
  { value: 'CT', label: 'Connecticut' },
  { value: 'DE', label: 'Delaware' },
  { value: 'DC', label: 'District Of Columbia' },
  { value: 'FL', label: 'Florida' },
  { value: 'GA', label: 'Georgia' },
  { value: 'HI', label: 'Hawaii' },
  { value: 'ID', label: 'Idaho' },
  { value: 'IL', label: 'Illinois' },
  { value: 'IN', label: 'Indiana' },
  { value: 'IA', label: 'Iowa' },
  { value: 'KS', label: 'Kansas' },
  { value: 'KY', label: 'Kentucky' },
  { value: 'LA', label: 'Louisiana' },
  { value: 'ME', label: 'Maine' },
  { value: 'MD', label: 'Maryland' },
  { value: 'MA', label: 'Massachusetts' },
  { value: 'MI', label: 'Michigan' },
  { value: 'MN', label: 'Minnesota' },
  { value: 'MS', label: 'Mississippi' },
  { value: 'MO', label: 'Missouri' },
  { value: 'MT', label: 'Montana' },
  { value: 'NE', label: 'Nebraska' },
  { value: 'NV', label: 'Nevada' },
  { value: 'NH', label: 'New Hampshire' },
  { value: 'NJ', label: 'New Jersey' },
  { value: 'NM', label: 'New Mexico' },
  { value: 'NY', label: 'New York' },
  { value: 'NC', label: 'North Carolina' },
  { value: 'ND', label: 'North Dakota' },
  { value: 'OH', label: 'Ohio' },
  { value: 'OK', label: 'Oklahoma' },
  { value: 'OR', label: 'Oregon' },
  { value: 'PA', label: 'Pennsylvania' },
  { value: 'PR', label: 'Puerto Rico' },
  { value: 'RI', label: 'Rhode Island' },
  { value: 'SC', label: 'South Carolina' },
  { value: 'SD', label: 'South Dakota' },
  { value: 'TN', label: 'Tennessee' },
  { value: 'TX', label: 'Texas' },
  { value: 'UT', label: 'Utah' },
  { value: 'VT', label: 'Vermont' },
  { value: 'VI', label: 'Virgin Islands' },
  { value: 'VA', label: 'Virginia' },
  { value: 'WA', label: 'Washington' },
  { value: 'WV', label: 'West Virginia' },
  { value: 'WY', label: 'Wyoming' },
  { value: 'WI', label: 'Wisconsin' },
];

export const countries = [
  { value: 'US', label: 'United States of America' },
];

export const months = [
  { value: '01', label: 'January' },
  { value: '02', label: 'February' },
  { value: '03', label: 'March' },
  { value: '04', label: 'April' },
  { value: '05', label: 'May' },
  { value: '06', label: 'June' },
  { value: '07', label: 'July' },
  { value: '08', label: 'August' },
  { value: '09', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' },
];

export const distances = [
  { value: '50', label: '50 miles' },
  { value: '100', label: '100 miles' },
  { value: '200', label: '200 miles' },
  { value: '500', label: '500 miles' },
  { value: '-1', label: 'Any' },
];

export const questionTypes = [
  { value: 'Boolean', label: 'Yes/No' },
  { value: 'Number', label: 'Number' },
  { value: 'Date', label: 'Date' },
  { value: 'Dropdown', label: 'Dropdown' },
];

export const researcherStudySiteRoles = [
  // {
  //   value: 0,
  //   label: 'Contact',
  // },
  // {
  //   value: 1,
  //   label: 'Principal Investigator',
  // },
  // {
  //   value: 2,
  //   label: 'Sub-Investigator',
  // },
  {
    value: 3,
    label: 'Patient Facing',
  },
  {
    value: 4,
    label: 'Manager',
  },
  // {
  //   value: 5,
  //   label: 'Purchaser',
  // },
  // {
  //   value: 6,
  //   label: 'Study Chair',
  // },
];

export const armTypes = [
  {
    value: 0,
    label: 'In-Clinic',
  },
  {
    value: 1,
    label: 'At Home',
  },
  {
    value: 2,
    label: 'Mixed/Hybrid',
  },
  {
    value: 3,
    label: 'At home with one clinic visit',
  },
  {
    value: 4,
    label: 'At home with multiple clinic visits',
  },
  {
    value: 5,
    label: 'Nurse comes to you',
  },
  {
    value: 6,
    label: 'Temporary clinic near you',
  },
];

export const EA_ANSWER_TYPES = [
  {
    value: 'BOOL',
    label: 'Yes/No',
    valueKey: 'booleanAnswerValue', //makes it easier when creating tree in useQuestions.js
  },
  {
    value: 'DATE',
    label: 'Date',
    valueKey: 'dateAnswerValue',
  },
  // {
  //   value: 'Date Range',
  //   label: 'Date Range',
  // },
  {
    value: 'DEC',
    label: 'Decimal',
    valueKey: 'decimalAnswerValue',
  },
  {
    value: 'INT',
    label: 'Integer',
    valueKey: 'integerAnswerValue',
  },
  // {
  //   value: 'Integer Range',
  //   label: 'Integer Range',
  // },
];

export const SCREENING_PR_STATUS = [
  {
    value: 'A_0',
    label: 'New',
  },
  {
    value: 'A_1',
    label: 'Assigned',
  },
  {
    value: 'A_2',
    label: 'Screening',
  },
  {
    value: 'A_3',
    label: 'Not Contacted',
  },
  {
    value: 'A_4',
    label: 'Contacted',
  },
  {
    value: 'A_5',
    label: 'Eligible',
  },
  {
    value: 'A_6',
    label: 'Not Eligible',
  },
  {
    value: 'A_7',
    label: 'Not Determined',
  },
  {
    value: 'A_8',
    label: 'Enrolled',
  },
  {
    value: 'A_9',
    label: 'Not Enrolled for Other Reason',
  },
  {
    value: 'A_10',
    label: 'Withdrew desire to proceed',
  },
];

export const EA_OPERATOR_TYPES = [
  {
    value: 'EQ',
    label: 'Equals',
  },
  {
    value: 'NEQ',
    label: 'Not Equals',
  },
  {
    value: 'LT',
    label: 'Less than',
  },
  {
    value: 'LTE',
    label: 'Less than or equals',
  },
  {
    value: 'GT',
    label: 'Greater than',
  },
  {
    value: 'GTE',
    label: 'Greater than or equals',
  },
  {
    value: 'IN',
    label: 'In range',
  },
  {
    value: 'NIN',
    label: 'Not in range',
  },
];
