import { Stack, useRadioGroup } from '@chakra-ui/react';
import React from 'react';
import { ButtonRadio } from './ButtonRadio';

const ButtonRadioGroup = ({ options, ...rest }) => {
  const { getRadioProps, getRootProps } = useRadioGroup(rest);

  return (
    <Stack
      direction={{
        base: 'column',
        md: 'row',
      }}
      spacing="3"
      {...getRootProps()}
    >
      {options.map((option) => (
        <ButtonRadio
          key={option.value}
          icon={option.icon}
          label={option.label}
          {...getRadioProps({
            value: option.value,
          })}
        />
      ))}
    </Stack>
  );
};

export default ButtonRadioGroup;
