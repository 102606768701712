import useSWR from 'swr';
import { apiClient } from '../../api/api-wrapper';
import { useAuth } from '../../context/authContext';
import laggy from '../../util/swr-laggy';

const fetcher = (...args) =>
  apiClient.get(...args).then((res) => res.data);

export const useSearchStudies = (id, isNct, shouldSearch) => {
  const { isAuthenticated, token } = useAuth();
  const { data, error, isValidating, isLagging, resetLaggy } = useSWR(
    isAuthenticated && token && shouldSearch
      ? '/studies/?' + (isNct ? 'nct_id=' : 'study_id=') + id
      : null,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      use: [laggy],
    },
  );

  return {
    searchStudies: data?.results || [],
    isValidating,
    isError: error,
    isLagging,
    resetLaggy,
  };
};
