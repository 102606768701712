import React from 'react';
import { Controller } from 'react-hook-form';
import { FormControl, FormLabel } from '@chakra-ui/react';
import { AsyncSelect } from 'chakra-react-select';
import { apiClient } from '../api/api-wrapper';
import { useDebouncedCallback } from 'use-debounce';

const SiteSelect = ({ control }) => {
  const getOptions = (inputText) => {
    return apiClient
      .get('/sites/' + (inputText ? `?name=${inputText}` : ''))
      .then((res) => {
        return res.data.results.features.map((site) => {
          return { value: site.id, label: site.properties.name };
        });
      })
      .catch((e) => console.log(e));
  };

  const loadOptions = useDebouncedCallback((inputText, callback) => {
    getOptions(inputText).then((options) => callback(options));
  }, 750);

  return (
    <>
      <FormControl id="protocol">
        <FormLabel>Sites</FormLabel>
        <Controller
          control={control}
          name="site"
          render={({ value, onChange }) => (
            <AsyncSelect
              value={value}
              isClearable
              defaultOptions
              loadOptions={loadOptions}
              defaultValue={[]}
              onChange={onChange}
              focusBorderColor="primary.400"
              selectedOptionColor="primary"
            />
          )}
        />
      </FormControl>
    </>
  );
};

export default SiteSelect;
