import React, { useState } from 'react';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  CloseButton,
  Flex,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  UnorderedList,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import PasswordInput from './PasswordInput';
import { useAuth } from '../context/authContext';

const initialErrors = [];
const initialFormValues = {
  currentPassword: '',
  newPassword: '',
};

const ChangePasswordModal = ({ isOpen, onClose }) => {
  const { setPassword } = useAuth();
  const {
    handleSubmit,
    errors,
    control,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: initialFormValues,
  });
  const [serverError, setServerError] = useState(initialErrors);

  const resetErrors = () => {
    setServerError(initialErrors);
  };

  const handleChangePassword = async (data) => {
    try {
      const res = await setPassword(data);

      if (res.error) {
        setServerError(res.error);
      } else {
        onClose();
      }
    } catch (error) {
      console.log('reset password error', error.message);
      setServerError(error.message);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Change your password</ModalHeader>
        <form
          id="change-password-form"
          onSubmit={handleSubmit(handleChangePassword)}
        >
          <ModalBody pb={6}>
            {serverError.length > 0 && (
              <Alert status="error" mb="8">
                <Flex flexDirection="column">
                  <Flex flexDirection="row">
                    <AlertIcon />
                    <AlertTitle>Sorry, there was an error</AlertTitle>
                  </Flex>
                  <UnorderedList px="8">
                    {serverError.map((error, index) => (
                      <ListItem key={index}>{error}</ListItem>
                    ))}
                  </UnorderedList>
                </Flex>
                <CloseButton
                  position="absolute"
                  right="8px"
                  top="8px"
                  onClick={resetErrors}
                />
              </Alert>
            )}
            <Stack spacing={4}>
              <Controller
                as={PasswordInput}
                forgotPassword={false}
                label={'Current Password'}
                control={control}
                rules={{ required: 'Password is required' }}
                name="currentPassword"
                errors={errors.currentPassword}
              />
              <Controller
                as={PasswordInput}
                forgotPassword={false}
                label="New Password"
                control={control}
                rules={{ required: 'Password is required' }}
                name="newPassword"
                errors={errors.newPassword}
              />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button
              mr={4}
              variant="outline"
              size="lg"
              disabled={isSubmitting}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              colorScheme="primary"
              size="lg"
              isLoading={isSubmitting}
              loadingText="Saving..."
            >
              Save
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default ChangePasswordModal;
