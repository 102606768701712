import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useAuth } from '../context/authContext';
import {
  Avatar,
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  SlideFade,
  Spacer,
  Stack,
  Text,
  useBoolean,
  useDisclosure,
} from '@chakra-ui/react';
import { CaretDown, Gear, List, User, Users } from 'phosphor-react';
import { UserMenuItem } from './UserMenuItem';
import NavBarLogo from './NavBarLogo';
import { MobileNavContent } from './MobileNavContent';
import { ACCOUNT_TYPES } from '../api/types';
import { useUser } from '../context/userContext';

const studySeekerMenuLinks = [
  {
    href: '/study-seeker',
    title: 'Dashboard',
    description: "See studies you've matched with",
    icon: <User />,
  },
  {
    href: '/profiles',
    title: 'Profiles',
    description: 'Manage profiles to search for studies',
    icon: <Users />,
  },
  {
    href: '/settings',
    title: 'Account',
    description: 'Control your account settings',
    icon: <Gear />,
  },
];

const researcherMenuLinks = [
  {
    href: '/researcher',
    title: 'Dashboard',
    description: 'See your studies',
    icon: <User />,
  },
  {
    href: '/researcher/matches',
    title: 'Matches',
    description: 'See matched candidates for your studies',
    icon: <Users />,
  },
  {
    href: '/settings',
    title: 'Account',
    description: 'Control your account settings',
    icon: <Gear />,
  },
];

const NavBar = ({ showMenu = true }) => {
  const { logout } = useAuth();
  const { push } = useHistory();
  const [show, { toggle }] = useBoolean(false);
  const mobileNav = useDisclosure();
  const { user } = useUser();

  const handleLogout = async (e) => {
    e.preventDefault();

    try {
      logout();
      push('/');
    } catch (error) {
      //TODO: notify user
      console.log('handle logout error', error.message);
    }
  };

  return (
    <Flex
      py={2}
      px={{
        base: 4,
        md: 6,
        lg: 8,
      }}
      bg="white"
      boxShadow="md"
    >
      <Box
        as={Link}
        to="/"
        _hover={{
          cursor: 'pointer',
        }}
      >
        <NavBarLogo h={12} />
      </Box>
      <Spacer />
      <>
        {showMenu && (
          <HStack
            as="button"
            fontWeight="semibold"
            color="gray.600"
            onClick={toggle}
            display={{
              base: 'none',
              md: 'flex',
            }}
          >
            <Avatar
              bg="secondary.400"
              color="white"
              size="sm"
              name={user?.fullName}
            />
            {user && (
              <Box as={CaretDown} fontSize="lg" color="gray.500" />
            )}
          </HStack>
        )}
        <Box
          as={SlideFade}
          in={show}
          pos="absolute"
          top={14}
          right={2}
          bg="white"
          pt="2"
          w="full"
          maxW="sm"
          rounded="md"
          overflow="hidden"
          border="1px"
          borderColor="primary.200"
          zIndex={1}
          unmountOnExit={true}
        >
          <Box as="ul" listStyleType="none" px="2" pb="2">
            {user &&
            user.userprofile.account_type.toLowerCase() ===
              ACCOUNT_TYPES.STUDY_SEEKER
              ? studySeekerMenuLinks.map((link, idx) => (
                  <Box as="li" key={idx} onClick={() => toggle()}>
                    <UserMenuItem
                      href={link.href}
                      title={link.title}
                      icon={link.icon}
                    >
                      {link.description}
                    </UserMenuItem>
                  </Box>
                ))
              : researcherMenuLinks.map((link, idx) => (
                  <Box as="li" key={idx} onClick={() => toggle()}>
                    <UserMenuItem
                      href={link.href}
                      title={link.title}
                      icon={link.icon}
                    >
                      {link.description}
                    </UserMenuItem>
                  </Box>
                ))}
          </Box>
          <Flex align="center" p="3">
            <Button
              colorScheme="primary"
              variant="outline"
              w="full"
              onClick={handleLogout}
            >
              Sign Out
            </Button>
          </Flex>
        </Box>
        <Flex align="center">
          <IconButton
            display={{
              base: 'flex',
              md: 'none',
            }}
            size="sm"
            aria-label="Open menu"
            fontSize="20px"
            variant="ghost"
            onClick={mobileNav.onOpen}
            icon={<List />}
          />
        </Flex>
        <MobileNavContent
          isOpen={mobileNav.isOpen}
          onClose={mobileNav.onClose}
        >
          <Stack spacing={4}>
            <Box
              as={Link}
              to="/"
              _hover={{
                cursor: 'pointer',
              }}
            >
              <NavBarLogo h={12} />
            </Box>
            <HStack spacing={4} flex="1" px={{ base: 2, md: 0 }}>
              <Avatar bg="secondary.400" color="white" size="sm" />
              <Flex
                direction="column"
                display={{
                  base: 'flex',
                  md: 'none',
                }}
              >
                <Text fontWeight="bold" lineHeight="shorter">
                  {user?.fullName}
                </Text>
                <Text
                  fontSize="sm"
                  lineHeight="shorter"
                  opacity={0.7}
                >
                  {user?.email}
                </Text>
              </Flex>
            </HStack>
            <Box as="ul" listStyleType="none" px="2" pb="2">
              {user &&
              user.userprofile.account_type.toLowerCase() ===
                ACCOUNT_TYPES.STUDY_SEEKER
                ? studySeekerMenuLinks.map((link, idx) => (
                    <Box
                      as="li"
                      key={idx}
                      onClick={mobileNav.onClose}
                    >
                      <UserMenuItem
                        href={link.href}
                        title={link.title}
                        icon={link.icon}
                      >
                        {link.description}
                      </UserMenuItem>
                    </Box>
                  ))
                : researcherMenuLinks.map((link, idx) => (
                    <Box
                      as="li"
                      key={idx}
                      onClick={mobileNav.onClose}
                    >
                      <UserMenuItem
                        href={link.href}
                        title={link.title}
                        icon={link.icon}
                      >
                        {link.description}
                      </UserMenuItem>
                    </Box>
                  ))}
            </Box>
            <Flex align="center" p="3">
              <Button
                colorScheme="primary"
                variant="outline"
                w="full"
                onClick={handleLogout}
              >
                Sign Out
              </Button>
            </Flex>
          </Stack>
        </MobileNavContent>
      </>
    </Flex>
  );
};

export default NavBar;
