import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import AccountActivation from './pages/AccountActivation';
import Home from './pages/Home';
import Login from './pages/Login';
import MfaCodeEntry from './pages/MfaCodeEntry';
import MfaSelect from './pages/MfaSelect';
import PasswordReset from './pages/PasswordReset';
import PasswordResetConfirm from './pages/PasswordResetConfirm';
import Register from './pages/Register';
import RegistrationSuccess from './pages/RegistrationSuccess';
import { Box } from '@chakra-ui/react';
import Account from './pages/Account';
import Profiles from './pages/Profiles';
import ResearcherDash from './components/ResearcherDash';
import StudySeekerDash from './components/StudySeekerDash';
import StudyDetail from './pages/StudyDetail';

const App = () => {
  const body = (
    <Switch>
      <Route path="/register" component={Register} />
      <Route
        path="/registration-success"
        component={RegistrationSuccess}
      />
      <Route
        path="/account-activation/:uid/:token"
        component={AccountActivation}
      />
      <Route
        path="/password/reset/confirm/:uid/:token"
        component={PasswordResetConfirm}
      />
      <Route path="/reset-password" component={PasswordReset} />
      <Route path="/login" component={Login} />
      <Route path="/mfa-select" component={MfaSelect} />
      <Route path="/mfa-code" component={MfaCodeEntry} />
      <PrivateRoute path="/researcher" component={ResearcherDash} />
      <PrivateRoute
        path="/study-seeker"
        component={StudySeekerDash}
      />
      <PrivateRoute path="/settings" component={Account} />
      <PrivateRoute path="/profiles" component={Profiles} />
      <PrivateRoute path="/" exact component={Home} />
      <Route path="/study/:id" component={StudyDetail} />
    </Switch>
  );

  return (
    <>
      <Box className="app-body" minH="100vh" minW="100vw">
        {body}
      </Box>
      <div id="loader-root" />
    </>
  );
};

App.propTypes = {};

export default App;
