import { GraphQLClient } from 'graphql-request';

//https://github.com/jasonkuhrt/graphql-request/blob/main/examples/other-middleware.ts
const getToken = () =>
  Promise.resolve(JSON.parse(localStorage.getItem('token')));

const requestMiddleware = async (request) => {
  const token = await getToken();
  return {
    ...request,
    headers: {
      authorization: `Token ${token}`,
      accept: 'application/json',
      'content-type': 'application/json',
    },
  };
};

export const client = new GraphQLClient(
  process.env.REACT_APP_HW_GRAPHQL_URL,
  { requestMiddleware },
);
