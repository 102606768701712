import React, { useEffect } from 'react';
import { useUserStudies } from '../hooks/api/useUserStudies';
import { useLoader } from '../hooks/useLoader';
import Card from './Card';
import EmptyState from './EmptyState';
import { useUser } from '../context/userContext';
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  SimpleGrid,
  Spinner,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { MagnifyingGlass } from 'phosphor-react';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { useProfiles } from '../hooks/api/useProfiles';
import {
  Link as RouterLink,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import StudySeekerStudyDetail from '../pages/StudySeekerStudyDetail';
import Questionnaire from './OnboardingQuestionnaire';
import { useNextQuestion } from '../hooks/api/useNextQuestion';

const ProfileCard = ({ profile, onClick }) => {
  return (
    <Box
      p="6"
      flex="1"
      rounded="lg"
      boxShadow="0 0 0 1px #e4e7f0"
      overflow="hidden"
      _hover={{
        cursor: 'pointer',
        boxShadow: '0 0 0 2px #8CABAA',
      }}
      transition="all .2s ease"
      onClick={onClick}
    >
      <Text fontWeight="bold">{profile.label}</Text>
      <Text
        color={useColorModeValue('gray.500', 'whiteAlpha.700')}
        fontWeight="medium"
        fontSize="sm"
      >
        {profile.conditions.join(', ')}
      </Text>
    </Box>
  );
};

const SelectProfile = ({ profiles, setActiveProfileId }) => {
  return (
    <Box>
      <Stack spacing={6}>
        <Heading textAlign="center">
          Whose results do you want to see?
        </Heading>
        <SimpleGrid
          mt={{
            base: '8',
            md: '0',
          }}
          columns={{
            base: 1,
            md: 3,
          }}
          spacing="10"
        >
          {profiles.map((profile) => (
            <ProfileCard
              key={profile.id}
              profile={profile}
              onClick={() => setActiveProfileId(profile.id)}
            />
          ))}
        </SimpleGrid>
        <Flex justify="center">
          <Button
            as={RouterLink}
            variant="outline"
            colorScheme="secondary"
            to="/profiles"
          >
            Manage Profiles
          </Button>
        </Flex>
      </Stack>
    </Box>
  );
};

const StudyList = () => {
  const { show, hide, RenderLoader } = useLoader();

  const { pathname, search } = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(search);
  const currentPage = Number(queryParams.get('page')) || 1;

  const {
    profileMatches,
    count,
    pages,
    position,
    isLoading,
    isError,
  } = useUserStudies(currentPage);
  const { question, isLoading: isNextQuestionLoading } =
    useNextQuestion();

  useEffect(() => {
    if (isError || isLoading) {
      show();
    } else {
      hide();
    }
  }, [isLoading, isError, show, hide]);

  const handlePageChange = (newPage) => {
    // update query parameters with new page number
    queryParams.set('page', newPage);
    history.push({
      pathname,
      search: queryParams.toString(),
    });
  };

  return (
    <>
      <Box>
        {isLoading ? null : (
          <>
            <Flex
              alignItems={{ base: 'flex-start', md: 'center' }}
              mb={{ base: 3, md: 8 }}
              justifyContent="space-between"
              direction={{ base: 'column', md: 'row' }}
            >
              <Heading as="h3" size="lg">
                Your Results ({position})
              </Heading>
              <Box maxW="300px">
                {isNextQuestionLoading ? (
                  <Flex alignItems="center">
                    <Spinner
                      thickness="4px"
                      speed="0.65s"
                      emptyColor="gray.200"
                      color="primary.500"
                      size="md"
                    />
                    <Text ml={4}>
                      Searching for ways to personalize your
                      results...
                    </Text>
                  </Flex>
                ) : (
                  question && (
                    <Button
                      as={RouterLink}
                      variant="outline"
                      colorScheme="secondary"
                      to="/study-seeker/questions"
                    >
                      Personalize My Results
                    </Button>
                  )
                )}
              </Box>
            </Flex>
            <>
              {count === 0 ? (
                <EmptyState
                  icon={MagnifyingGlass}
                  text="We couldn't find any matches for you right now, but we'll let you know as soon as we do"
                />
              ) : (
                <>
                  {profileMatches.map((match, index) => {
                    return <Card key={index} match={match} />;
                  })}
                  <HStack spacing={4} mt={4}>
                    <Button
                      colorScheme="secondary"
                      disabled={currentPage === 1}
                      onClick={() =>
                        handlePageChange(currentPage - 1)
                      }
                    >
                      Previous
                    </Button>
                    <Button
                      colorScheme="secondary"
                      disabled={currentPage === pages}
                      onClick={() =>
                        handlePageChange(currentPage + 1)
                      }
                    >
                      Next
                    </Button>
                  </HStack>
                </>
              )}
            </>
          </>
        )}
      </Box>
      <RenderLoader loadingText="Searching for studies..." />
    </>
  );
};

const StudySeekerDash = () => {
  return (
    <Box
      maxW={{ base: 'xl', md: '7xl' }}
      mx="auto"
      px={{
        base: 0,
        md: 8,
      }}
      py={{
        base: 3,
        md: 8,
      }}
      display="flex"
    >
      <Switch>
        <Route
          path="/study-seeker/study/:studyId"
          component={StudySeekerStudyDetail}
        />
        <Route
          path="/study-seeker/questions"
          component={Questionnaire}
        />
        <Route path="/study-seeker" exact component={Dash} />
        <Route
          path="/study-seeker/results" //"?page=1
          exact
          component={StudyList}
        />
      </Switch>
    </Box>
  );
};

const Dash = () => {
  const { user } = useUser();
  const { profiles, isLoading: isProfilesLoading } = useProfiles();
  const [activeProfileId, setActiveProfileId] = useLocalStorage(
    JSON.parse(localStorage.getItem('active-profile-id')) ?? '',
    'active-profile-id',
  );
  const { show, hide, RenderLoader } = useLoader();

  useEffect(() => {
    if (isProfilesLoading || !user || Object.keys(user).length < 1) {
      show();
    } else {
      if (profiles?.length === 1) {
        setActiveProfileId(profiles[0].id);
      }
      hide();
    }
  }, [
    isProfilesLoading,
    user,
    show,
    hide,
    profiles,
    setActiveProfileId,
  ]);

  return (
    <>
      <Box
        maxW={{ base: 'xl', md: '7xl' }}
        mx="auto"
        px={{
          base: 0,
          md: '12',
        }}
        py={{
          base: 3,
          md: 12,
        }}
      >
        {isProfilesLoading ? null : activeProfileId ? (
          <StudyList />
        ) : profiles && profiles.length ? (
          <SelectProfile
            profiles={profiles}
            setActiveProfileId={setActiveProfileId}
          />
        ) : (
          <Redirect to="/profiles" />
        )}
      </Box>
      <RenderLoader loadingText="Loading profiles..." />
    </>
  );
};

export default StudySeekerDash;
