export const GetScreeningQuestions = `
  query ($armUuid: UUID) {
    screeningQuestions(armUuid: $armUuid) {
      id
      question {
        id
        displayText
        helpText
      }
      andScreeningQuestions {
        id
      }
      andSqs {
        id
      }
      orScreeningQuestions {
        id
      }
      orSqs {
        id
      }
      expectedAnswer {
        id
        operator
        answerType
        value
      }
      isRoot
    }
  }`;

/*
  input {
    $armUuid: UUID!
    $expectedAnswer: ExpectedAnswerInput!
    $question: QuestionInput!
    $parentUuid: UUID
    $parentRelation: ScreeningQuestionParentRelationEnum
  }
 */
export const CreateScreeningQuestion = `
  mutation ($input: CreateScreeningQuestionInput) {
    createScreeningQuestion(input: $input) {
      screeningQuestion {
        id
      }
    }
  }`;

/*
  input {
    $id: ID
    $armUuid: UUID!
    $question: ID!
    $expectedAnswer: ID!
  }
 */
export const UpdateScreeningQuestion = `
  mutation ($input: ScreeningQuestionModelFormMutationInput) {
    updateScreeningQuestion(input: $input) {
      screeningQuestion {
        id
      }
    }
  }`;

export const DeleteScreeningQuestion = `
  mutation ($id: UUID) {
    deleteScreeningQuestion (id: $id) {
      ok
    }
  }`;

export const GetNextPersonalizationQuestion = `
  query ($profileUuid: UUID!) {
    nextPersonalizationQuestion(profileUuid: $profileUuid) {
      id
      displayText
      helpText
      answerType
    }
  }`;

export const UpdatePersonalizationResponse = `
  mutation ($input: PersonalizationResponseInput!) {
    updatePersonalizationResponse(input: $input) {
      personalizationResponse {
        id
      }
      created
    }
  }`;
