import React from 'react';
import {
  Box,
  Flex,
  Icon,
  Link,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useResearcherStudies } from '../hooks/api/useResearcherStudies';
import { Link as RouterLink, Route, Switch } from 'react-router-dom';
import { useTable } from 'react-table';
import StudyDetail from '../pages/StudyDetail';
import StudySiteDetail from '../pages/StudySiteDetail';
import CreateStudy from '../pages/CreateStudy';
import SearchStudies from '../pages/SearchStudies';
import { CaretRight } from 'phosphor-react';
import ProtocolDetail from '../pages/ProtocolDetail';
import Matches from '../pages/Matches';
import SqieTree from './SqieTree';

const ResearcherDash = () => {
  return (
    <Box
      maxW={{ base: 'xl', md: '7xl' }}
      mx="auto"
      px={{
        base: 0,
        md: 8,
      }}
      py={{
        base: 3,
        md: 8,
      }}
      display="flex"
    >
      <Switch>
        <Route
          path="/researcher/study/search"
          component={SearchStudies}
        />
        <Route
          path="/researcher/study/create/:id?/:isNct?"
          component={CreateStudy}
        />
        <Route
          path="/researcher/study/protocol/:protocolId"
          component={ProtocolDetail}
        />
        <Route
          path="/researcher/study/site/:studySiteId"
          component={StudySiteDetail}
        />
        <Route
          path="/researcher/study/:studyId"
          component={StudyDetail}
        />
        <Route path="/researcher/sqie/:armId" component={SqieTree} />
        <Route path="/researcher/matches" component={Matches} />
        <Route path="/researcher" exact component={Dash} />
      </Switch>
    </Box>
  );
};

const Dash = () => {
  const { studies, isLoading, isError } = useResearcherStudies();

  const columns = React.useMemo(
    () => [
      {
        Header: 'Study Name',
        accessor: 'study',
        Cell: ({ value }) => (
          <Link
            as={RouterLink}
            color="secondary.500"
            to={`/researcher/study/${value?.uuid}`}
          >
            {value?.nickname ?? value?.formal_name}
          </Link>
        ),
      },
      {
        Header: 'Study Site',
        accessor: 'site',
        Cell: ({ value, row }) => {
          return (
            <Link
              as={RouterLink}
              color="secondary.500"
              to={`/researcher/study/site/${row.original.uuid}`}
            >
              {value.properties.name}
            </Link>
          );
        },
      },
      {
        Header: 'Roles',
        accessor: 'roles',
        Cell: ({ value }) => {
          const tagList = value.map((role) => role.name).join(', ');
          return <span>{tagList}</span>;
        },
      },
    ],
    [],
  );

  //TODO: drive tabs with data array, base data array on feature flipper
  return (
    <Tabs w="full" orientation="vertical" variant="unstyled">
      <TabList pr={8}>
        <Tab
          justifyContent="flex-start"
          _selected={{
            borderLeft: '1px solid',
            borderLeftColor: 'secondary.200',
            color: 'secondary.500',
            fontWeight: 'semibold',
          }}
        >
          Studies
        </Tab>
        <Tab
          justifyContent="flex-start"
          _selected={{
            borderLeft: '1px solid',
            borderLeftColor: 'secondary.200',
            color: 'secondary.500',
            fontWeight: 'semibold',
          }}
        >
          Matches
        </Tab>
      </TabList>
      <TabPanels w="full">
        <TabPanel p={0}>
          <Box as="main" w="full">
            {/*TODO: loading states*/}
            <StudyList studies={studies} columns={columns} />
          </Box>
        </TabPanel>
        <TabPanel>
          <Box as="main" w="full">
            <Matches />
          </Box>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

const StudyList = ({ studies, columns, onSearchOpen }) => {
  // const data = React.useMemo(() => studies, [studies]);

  const { getTableProps, getTableBodyProps, rows, prepareRow } =
    useTable({ columns, data: studies });

  return (
    <>
      <Link
        as={RouterLink}
        color="secondary.500"
        to="/researcher/study/search"
      >
        <Flex alignItems="center" justifyContent="end">
          Search for a study to join{' '}
          <Icon as={CaretRight} weight="bold" fontSize="lg" ml={1} />
        </Flex>
      </Link>
      <Table
        borderWidth="1px"
        fontSize="sm"
        mt={4}
        {...getTableProps()}
      >
        <Thead bg="gray.50">
          <Tr>
            {columns.map((column, index) => (
              <Th whiteSpace="nowrap" scope="col" key={index}>
                {column.Header}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {(rows.length > 0 &&
            rows.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <Td {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })) || (
            <Tr>
              <Td colSpan={5} textAlign="center">
                <Link
                  as={RouterLink}
                  color="secondary.500"
                  to="/researcher/study/search"
                >
                  Find a study to link to your account
                </Link>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </>
  );
};

export default ResearcherDash;
