import React, { useEffect } from 'react';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import { useNextQuestion } from '../hooks/api/useNextQuestion';
import { useLoader } from '../hooks/useLoader';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
} from '@chakra-ui/react';
import { client } from '../api/graphql-client';
import { UpdatePersonalizationResponse } from '../api/graphql/screening-questions';
import { format } from 'date-fns';
import { Controller, useForm } from 'react-hook-form';
import HWSelect from './HWSelect';
import ReactDatePicker from 'react-datepicker';

const Questionnaire = () => {
  const profileUuid = JSON.parse(
    localStorage.getItem('active-profile-id'),
  );

  const {
    handleSubmit,
    errors,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm();

  const {
    question,
    mutate: mutateQuestion,
    isLoading,
    isError,
  } = useNextQuestion();

  const { show, hide, RenderLoader } = useLoader();

  useEffect(() => {
    if (isLoading || isSubmitting || isError) {
      show();
    } else {
      hide();
    }
  }, [isLoading, isSubmitting, isError, show, hide]);

  const onUpdate = async (data) => {
    let answerValue;
    switch (question.answerType) {
      case 'BOOL':
        answerValue = data.booleanAnswerValue.value;
        break;
      case 'DATE':
        answerValue = format(data.dateAnswerValue, 'yyyy-MM-dd');
        break;
      case 'DEC':
        answerValue = data.decimalAnswerValue;
        break;
      case 'INT':
        answerValue = data.integerAnswerValue;
        break;
      default:
        answerValue = null;
    }

    try {
      await client.request(UpdatePersonalizationResponse, {
        input: {
          questionUuid: question.id,
          profileUuid,
          answerType: question.answerType,
          value: answerValue,
        },
      });
    } catch (e) {
      console.error(e);
    } finally {
      mutateQuestion();
    }
  };

  //TODO: figure out how to combine with onUpdate - manipulate data param
  const onNotSure = async () => {
    try {
      await client.request(UpdatePersonalizationResponse, {
        input: {
          questionUuid: question.id,
          profileUuid,
          answerType: 'NONE',
          isUnsure: true,
        },
      });
    } catch (e) {
      console.error(e);
    } finally {
      mutateQuestion();
    }
  };

  if (question === null) {
    return <Redirect to="/study-seeker" />;
  }

  return (
    <>
      {isLoading ? null : (
        <Box
          maxW={{ base: 'xl', md: '7xl' }}
          mx="auto"
          px={{
            base: 6,
            md: '12',
          }}
          py={{
            base: 3,
            md: 12,
          }}
        >
          <Stack spacing={{ base: 4, md: 8 }}>
            <Button
              as={RouterLink}
              alignSelf="end"
              colorScheme="secondary"
              variant="outline"
              size="md"
              width="fit-content"
              to="/study-seeker"
            >
              I'm done for now
            </Button>
            <Box
              border="1px"
              borderColor="secondary.accent-200"
              bg="gray.100"
              rounded={5}
              p={8}
            >
              <Heading as="h2" size="lg">
                {question.displayText}
              </Heading>
            </Box>
            <form id="update-personalization-form">
              <AnswerTypeInput
                control={control}
                answerType={question.answerType}
              />
              <Stack
                w="full"
                mt={8}
                align="center"
                justify="flex-end"
                direction={{ base: 'column', md: 'row' }}
                spacing={{ base: 4, md: 8 }}
              >
                <Button
                  colorScheme="secondary"
                  type="submit"
                  form="update-personalization-form"
                  onClick={handleSubmit(onUpdate)}
                >
                  Save
                </Button>
                <Button
                  colorScheme="secondary"
                  type="submit"
                  form="update-personalization-form"
                  onClick={handleSubmit(onNotSure)}
                >
                  I'm Not Sure
                </Button>
              </Stack>
            </form>
          </Stack>
        </Box>
      )}
      <RenderLoader loadingText="Loading next question..." />
    </>
  );
};

const AnswerTypeInput = ({ answerType, control }) => {
  const renderControl = () => {
    switch (answerType) {
      case 'BOOL':
        return (
          <>
            <FormControl id={'boolean-answer'}>
              <FormLabel>Answer Value</FormLabel>
              <Controller
                control={control}
                name={'booleanAnswerValue'}
                defaultValue={{ value: 'True', label: 'Yes' }}
                render={({ value, onChange }) => {
                  return (
                    <HWSelect
                      options={[
                        {
                          value: 'True',
                          label: 'Yes',
                        },
                        {
                          value: 'False',
                          label: 'No',
                        },
                      ]}
                      value={value}
                      onChange={onChange}
                    />
                  );
                }}
              />
            </FormControl>
          </>
        );
      case 'DATE':
        return (
          <>
            <FormControl id={'date-answer'}>
              <FormLabel>Answer Value</FormLabel>
              <Controller
                control={control}
                name={'dateAnswerValue'}
                type="date"
                render={({ value, onChange }) => {
                  return (
                    <ReactDatePicker
                      dateFormat="yyyy-MM-dd"
                      placeholderText="Select date"
                      onChange={onChange}
                      selected={
                        value instanceof Date &&
                        !isNaN(value.valueOf())
                          ? value
                          : null
                      }
                      customInput={
                        <Input
                          focusBorderColor="primary.400"
                          borderColor="primary.300"
                          shadow="sm"
                          _hover={{
                            borderColor: 'primary.400',
                          }}
                        />
                      }
                      shouldCloseOnSelect
                      showPopperArrow={false}
                      popperPlacement="bottom-start"
                    />
                  );
                }}
              />
            </FormControl>
          </>
        );
      case 'DEC':
        return (
          <>
            <FormControl id={'decimal-answer'}>
              <FormLabel>Answer Value</FormLabel>
              <Controller
                as={Input}
                type="number"
                control={control}
                name={'decimalAnswerValue'}
              />
            </FormControl>
          </>
        );
      case 'INT':
        return (
          <>
            <FormControl id={'integer-answer'}>
              <FormLabel>Answer Value</FormLabel>
              <Controller
                as={Input}
                type="number"
                step="1"
                control={control}
                name={'integerAnswerValue'}
              />
            </FormControl>
          </>
        );
      default:
        return null;
    }
  };

  return <>{renderControl()}</>;
};

export default Questionnaire;
