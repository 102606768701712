export const ACCOUNT_TYPES = {
  STUDY_SEEKER: 'study seeker',
  RESEARCHER: 'researcher',
};

export const CONTACT_RESULTS = {
  NO_ANSWER: { value: 'no answer', displayText: 'No Answer' },
  NO_ANSWER_VM: {
    value: 'no answer vm',
    displayText: 'No Answer - Voicemail',
  },
  DISCUSSED_RESCHEDULED: {
    value: 'discussed rescheduled',
    displayText: 'Discussed - Rescheduled',
  },
  DISCUSSED_ELIGIBILITY: {
    value: 'discussed eligibility',
    displayText: 'Discussed Eligibility',
  },
};

export const MFA_TYPES = {
  EMAIL: { value: 'email', displayText: 'E-mail' },
  SMS: { value: 'sms', displayText: 'SMS Text' },
};

export const USER_STUDIES_ERRORS = {
  HAS_QUESTIONS: 'User has unanswered questions',
  NO_CONDITIONS: 'User has no conditions set',
};

export const MATCH_STATUS = {
  MATCHED: 'Matched',
  MAYBE_MATCHED: 'Maybe Matched',
  NOT_MATCHED: 'Not Matched',
};

export const QUESTION_TYPE = {
  STATEMENT: 'Statement Form',
};

export const NEXT_QUESTION_RESPONSE = {
  NO_NEXT: 'No next question',
};

export const MFA_ACTIONS = {
  ACTIVATE: 'ACTIVATE',
  DEACTIVATE: 'DEACTIVATE',
};

export const SQ_PARENT_RELATION_TYPE = {
  AND: 'AND',
  OR: 'OR',
  POSITIVE_FOLLOWUP: 'POSITIVE_FOLLOWUP',
  NEGATIVE_FOLLOWUP: 'NEGATIVE_FOLLOWUP',
  UNSURE_FOLLOWUP: 'UNSURE_FOLLOWUP',
};
