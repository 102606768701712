import useSWR from 'swr';
import { apiClient } from '../../api/api-wrapper';
import { useAuth } from '../../context/authContext';

const url = 'profiles/';

const fetcher = (...args) =>
  apiClient.get(...args).then((res) => res.data);

export const useProfiles = () => {
  const { isAuthenticated, token } = useAuth();
  const { data, error, isValidating, mutate } = useSWR(
    isAuthenticated && token ? url : null,
    fetcher,
    {
      revalidateOnFocus: false,
      dedupingInterval: 900000, // 15 min
    },
  );

  const { results } = data || {};

  const res =
    results &&
    results.map((profile) => ({
      id: profile.id,
      label: profile.label,
      age: profile.age,
      partialBirthdate: profile.partial_birthdate,
      sexAtBirth: profile.sex_at_birth,
      conditions: profile.conditions,
      state: profile.state,
      birthSplit: profile.partial_birthdate.split('-'),
    }));

  return {
    profiles: res || [],
    isLoading: !res || isValidating,
    isError: error,
    mutate,
  };
};
