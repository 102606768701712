import React, { useEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  ButtonGroup,
  Divider,
  Flex,
  Heading,
  IconButton,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { useLoader } from '../hooks/useLoader';
import { Pencil } from 'phosphor-react';
import { useGetStudySitesById } from '../hooks/api/useGetStudySitesById';
import EditStudySiteModal from '../components/EditStudySiteModal';

const Breadcrumbs = ({ studyId, studySiteId }) => {
  return (
    <Breadcrumb
      spacing="8px"
      mb={4}
      fontWeight="medium"
      fontSize="sm"
      color="secondary.400"
    >
      <BreadcrumbItem>
        <BreadcrumbLink as={RouterLink} to="/researcher">
          Home
        </BreadcrumbLink>
      </BreadcrumbItem>

      <BreadcrumbItem>
        <BreadcrumbLink
          as={RouterLink}
          to={`/researcher/study/${studyId}`}
        >
          Study
        </BreadcrumbLink>
      </BreadcrumbItem>

      <BreadcrumbItem isCurrentPage>
        <BreadcrumbLink
          as={RouterLink}
          to={`/researcher/study/site/${studySiteId}`}
        >
          Study Site
        </BreadcrumbLink>
      </BreadcrumbItem>
    </Breadcrumb>
  );
};

const StudySiteDetail = () => {
  const { studySiteId } = useParams();
  const { studySite, isLoading, isError, mutate } =
    useGetStudySitesById(studySiteId);
  const { show, hide, RenderLoader } = useLoader();

  useEffect(() => {
    if (isError || isLoading) {
      show();
    } else {
      hide();
    }
  }, [isLoading, isError, show, hide]);

  return (
    <>
      <Flex flexDirection="column" w="full">
        <Breadcrumbs
          studySiteId={studySiteId}
          studyId={studySite?.study_uuid}
        />
        <Overview studySite={studySite} mb={8} mutate={mutate} />
      </Flex>
      <RenderLoader loadingText="Getting study site information..." />
    </>
  );
};

const Overview = ({ studySite, mutate, ...rest }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Stack direction="column" spacing={4} {...rest}>
        <Flex alignItems="flex-start">
          <Box>
            <Heading as="h3">
              {studySite?.site?.properties?.name}
            </Heading>
          </Box>
          <ButtonGroup ml={{ base: 0, md: 'auto' }} variant="none">
            <Tooltip label="Edit">
              <IconButton
                color="primary.300"
                aria-label="More Info"
                onClick={onOpen}
                icon={<Pencil size={32} />}
                _hover={{
                  color: 'primary.500',
                }}
              />
            </Tooltip>
          </ButtonGroup>
        </Flex>
        <TextSet
          title={'Study'}
          text={
            studySite?.study?.nickname ??
            studySite?.study?.formal_name
          }
        />
        <TextSet
          title={'Current Protocol'}
          text={studySite?.current_protocol?.version}
        />
        <TextSet
          title={'Researchers'}
          text={studySite?.researchers?.join(', ')}
        />
      </Stack>
      <EditStudySiteModal
        studySite={studySite}
        isOpen={isOpen}
        onClose={onClose}
        mutate={mutate}
      />
    </>
  );
};

const TextSet = ({ title, text }) => {
  return (
    <>
      <Divider />
      <Stack spacing={1}>
        <Text fontSize="xs" fontWeight="bold" color="primary.400">
          {title.toUpperCase()}
        </Text>
        {text ? <Text>{text}</Text> : null}
      </Stack>
    </>
  );
};

export default StudySiteDetail;
