import React, { useRef, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Text,
  Textarea,
  Tooltip,
} from '@chakra-ui/react';
import {
  ArrowLineUpRight,
  FloppyDisk,
  Pencil,
  XSquare,
} from 'phosphor-react';
import ReadMoreText from '../components/ReadMoreText';
import { client } from '../api/graphql-client';
import { CreatePr } from '../api/graphql/matches';
import { useStudySeekerStudy } from '../hooks/api/useStudySeekerStudy';
import {
  CreateStudyNote,
  UpdateStudyNote,
} from '../api/graphql/study-notes';

const openStudyLink = (link) => () => window.open(link, '_blank');

const StudySeekerStudyDetail = () => {
  const { studyId } = useParams();
  const { match, mutate } = useStudySeekerStudy(studyId);

  return (
    <>
      <Flex flexDirection="column" w="full">
        <Breadcrumb
          spacing="8px"
          mb={4}
          fontWeight="medium"
          fontSize="sm"
          color="secondary.400"
        >
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="/study-seeker">
              Dashboard
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink
              as={RouterLink}
              to={`/study-seeker/study/${studyId}`}
            >
              Study
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        {match ? <Overview match={match} mutate={mutate} /> : null}
      </Flex>
    </>
  );
};

const Overview = ({ match, mutate }) => {
  const studyLink = `https://clinicaltrials.gov/ct2/show/${match.study.nctId}`;
  const profileId = JSON.parse(
    localStorage.getItem('active-profile-id'),
  );

  const notesRef = useRef();
  const [notes, setNotes] = useState(match?.notes[0]?.text || '');
  const [isEditNotes, setEditNotes] = useState(false);

  const handleNotesChange = (e) => {
    setNotes(e.target.value);
  };

  const handleSaveNotes = async () => {
    setEditNotes(false);
    try {
      if (match.notes[0]?.id) {
        await client.request(UpdateStudyNote, {
          id: match.notes[0]?.id,
          text: notes,
        });
      } else {
        await client.request(CreateStudyNote, {
          profileUuid: profileId,
          studyUuid: match.study.healthwalletId,
          text: notes,
        });
      }
    } catch (error) {
      console.log({ error });
      //TODO: on error, need to retry saving note?
    }
  };
  const handleEditNotesClick = () => {
    setEditNotes(true);
    const notesInput = notesRef.current;
    notesInput.focus({
      preventScroll: true,
    });

    const length = notesInput.value?.length * 2 ?? 0;
    requestAnimationFrame(() => {
      notesInput.setSelectionRange(length, length);
    });
  };

  const handleCancelNotes = () => {
    //TODO: possible situation where they save note, then edit again and cancel? mutate should preserve the saved note...probably won't do this fast enough anyway
    setNotes(match.notes[0] ?? '');
    setEditNotes(false);
  };

  const handleCreatePr = async (studySiteUuid) => {
    try {
      const res = await client.request(CreatePr, {
        input: {
          studySiteUuid,
          profileUuid: profileId,
        },
      });

      //TODO: toast
      if (res.createParticipationRequest.errors) {
        console.error({
          errors: res.createParticipationRequest.errors,
        });
      } else {
        //TODO: optimistic update
        mutate();
      }
    } catch (e) {
      //here if request is malformed, etc
      console.error({ e });
    }
  };

  return (
    <Box maxW="5xl" mx="auto">
      <Flex
        alignItems={{ base: 'flex-start' }}
        px={{ base: 3, md: 6 }}
        py={{ base: 3, md: 4 }}
      >
        <Box width={{ base: 'full' }}>
          <Text
            as="h3"
            fontWeight="bold"
            fontSize="lg"
            noOfLines={2}
            mb={4}
          >
            {match.study.formalName}
          </Text>
          {match.study.description && (
            <ReadMoreText
              id={match.study.nctId}
              text={match.study.description}
              numLines={3}
              mb={6}
            />
          )}
          <Flex direction={{ base: 'column', md: 'row' }} mb={4}>
            <ButtonGroup
              w={24}
              mr={4}
              variant="none"
              isAttached
              display="flex"
              alignItems={isEditNotes ? 'flex-end' : 'flex-start'}
            >
              {isEditNotes ? (
                <>
                  <Tooltip label="Save" placement="top">
                    <IconButton
                      color="secondary.300"
                      aria-label="Save"
                      onClick={handleSaveNotes}
                      icon={<FloppyDisk size={32} />}
                      _hover={{
                        color: 'secondary.500',
                      }}
                    />
                  </Tooltip>
                  <Tooltip label="Cancel" placement="top">
                    <IconButton
                      color="red.300"
                      aria-label="Cancel"
                      onClick={handleCancelNotes}
                      icon={<XSquare size={32} />}
                      _hover={{
                        color: 'red.500',
                      }}
                    />
                  </Tooltip>
                </>
              ) : (
                <Tooltip label="Edit Notes">
                  <IconButton
                    ml={{ base: 0, md: 'auto' }}
                    color="primary.300"
                    aria-label="Edit Notes"
                    onClick={handleEditNotesClick}
                    icon={<Pencil size={32} />}
                    _hover={{
                      color: 'primary.500',
                    }}
                  />
                </Tooltip>
              )}
            </ButtonGroup>
            <Textarea
              ref={notesRef}
              value={notes}
              onChange={handleNotesChange}
              placeholder="Add notes about this study"
              resize="none"
              isReadOnly={!isEditNotes}
              borderColor="primary.100"
              focusBorderColor="primary.500"
              color={isEditNotes ? 'inherit' : 'gray.400'}
              _hover={{
                borderColor: 'primary.200',
              }}
            />
          </Flex>
          <Box mb={4}>
            <Text as="h3" fontWeight="bold" fontSize="lg" mb={4}>
              Preferred Study Site
            </Text>
            <Text>{match.preferredStudySite?.site?.name}</Text>
            <Text>{match.preferredStudySite?.site?.address.raw}</Text>
          </Box>
          {match.screeningQuestionsUnanswered > 0 ? (
            'Complete the survey'
          ) : (
            <Box mb={4}>
              <Text as="h3" fontWeight="bold" fontSize="lg" mb={4}>
                Participation Request Status
              </Text>
              {match.participationRequest ? (
                "You've requested to participate"
              ) : (
                <Button
                  variant="outline"
                  colorScheme="secondary"
                  onClick={() =>
                    handleCreatePr(match.preferredStudySite?.uuid)
                  }
                >
                  Request to Join
                </Button>
              )}
            </Box>
          )}
        </Box>
        <Box w="200px">
          <ButtonGroup variant="none">
            <Tooltip label="More Info">
              <IconButton
                color="primary.300"
                aria-label="More Info"
                onClick={openStudyLink(studyLink)}
                icon={<ArrowLineUpRight size={32} />}
                _hover={{
                  color: 'primary.500',
                }}
              />
            </Tooltip>
          </ButtonGroup>
        </Box>
      </Flex>
    </Box>
  );
};

export default StudySeekerStudyDetail;
