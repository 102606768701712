import { apiClient } from '../../api/api-wrapper';
import { useAuth } from '../../context/authContext';
import useSWR from 'swr';

const fetcher = (...args) =>
  apiClient.get(...args).then((res) => res.data);

export const useResearcherStudy = (studyId) => {
  const { isAuthenticated, token } = useAuth();
  const { data, error, isValidating, mutate } = useSWR(
    isAuthenticated && token && studyId
      ? `/studies/?healthwallet_id=${studyId}`
      : null,
    fetcher,
    {
      revalidateOnFocus: false,
    },
  );

  let study = {};
  if (data?.results?.length > 0) {
    study = data.results[0];
  }

  return {
    study,
    isLoading: !study && isValidating,
    isError: error,
    mutate,
  };
};
