import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Link as RouterLink,
  Redirect,
  useHistory,
} from 'react-router-dom';
import { useAuth } from '../context/authContext';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  chakra,
  CloseButton,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Link,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import PasswordInput from '../components/PasswordInput';
import Logo from '../components/Logo';
import { mutate } from 'swr';

const initialErrors = [];
const initialLoginValues = {
  username: '',
  password: '',
};

//TODO: password restrictions, clean up structure
const Login = () => {
  const { push } = useHistory();
  const { isAuthenticated, login } = useAuth();
  const {
    handleSubmit,
    errors,
    control,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: initialLoginValues,
  });
  const [serverError, setServerError] = useState(initialErrors);
  const [redirect, setRedirect] = useState('');

  //fix for setting state on unmounted component
  useEffect(() => {
    if (redirect) {
      push(redirect);
    }
  }, [redirect, push]);

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  const resetErrors = () => {
    setServerError(initialErrors);
  };

  const onSubmit = async (data) => {
    try {
      const res = await login(data);

      if (res.error) {
        setServerError(res.error);
      } else if (res.method) {
        setRedirect('/mfa-code');
      } else {
        mutate('userstudies/', null, true);
        mutate('researcher/studies/', null, true);
        setRedirect('/');
      }
    } catch (error) {
      console.log('handle submit error', error.message);
      setServerError([error.message]);
    }
  };

  return (
    <Box
      bg="gray.50"
      minH="100vh"
      py={{ base: '4', md: '12' }}
      px={{ base: '4', lg: '8' }}
    >
      <Box maxW="md" mx="auto">
        <Logo
          mx="auto"
          h={{ base: '16', md: '32' }}
          mb={{ base: '4', md: '10' }}
        />
        <Heading
          textAlign="center"
          size="xl"
          fontWeight="bold"
          mb="8"
        >
          Sign in to Health Wallet
        </Heading>
        {serverError.length > 0 && (
          <Alert status="error" mb="8">
            <Flex flexDirection="column">
              <Flex flexDirection="row">
                <AlertIcon />
                <AlertTitle>Sorry, there was an error</AlertTitle>
              </Flex>
              <UnorderedList px="8">
                {serverError.map((error, index) => (
                  <ListItem key={index}>{error}</ListItem>
                ))}
              </UnorderedList>
            </Flex>
            <CloseButton
              position="absolute"
              right="8px"
              top="8px"
              onClick={resetErrors}
            />
          </Alert>
        )}
        <Box
          bg="white"
          py="8"
          px={{
            base: '4',
            md: '10',
          }}
          shadow="base"
          rounded={{
            sm: 'lg',
          }}
        >
          <chakra.form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={4} mb={8}>
              <FormControl id="username" isInvalid={errors.username}>
                <FormLabel>Email</FormLabel>
                <Controller
                  as={Input}
                  control={control}
                  rules={{ required: 'Email is required' }}
                  name="username"
                  type="email"
                  autoComplete="email"
                  focusBorderColor="primary.400"
                  borderColor="primary.300"
                  shadow="sm"
                  _hover={{
                    borderColor: 'primary.400',
                  }}
                />
                <FormErrorMessage>
                  {errors.username?.message}
                </FormErrorMessage>
              </FormControl>
              <Controller
                as={PasswordInput}
                control={control}
                rules={{ required: 'Password is required' }}
                name="password"
                focusBorderColor="primary.400"
                borderColor="primary.300"
                shadow="sm"
                _hover={{
                  borderColor: 'primary.400',
                }}
                errors={errors.password}
              />
            </Stack>
            <Button
              w="full"
              type="submit"
              colorScheme="primary"
              size="lg"
              isLoading={isSubmitting}
              loadingText="Signing in..."
            >
              Sign in
            </Button>
          </chakra.form>
        </Box>
        <Text mt="4" align="center" maxW="md" fontWeight="medium">
          <Text as="span">Don&apos;t have an account?</Text>{' '}
          <Link as={RouterLink} color="secondary.500" to="/register">
            Register here
          </Link>
        </Text>
      </Box>
    </Box>
  );
};

export default Login;
