import useSWR from 'swr';
import { apiClient } from '../../api/api-wrapper';
import { useAuth } from '../../context/authContext';

const url = 'auth/mfa/user-active-methods/';

const fetcher = (...args) =>
  apiClient.get(...args).then((res) => res.data);

export const useMfaMethods = () => {
  const { isAuthenticated, token } = useAuth();
  const { data, error, mutate, isValidating } = useSWR(
    isAuthenticated && token ? url : null,
    fetcher,
    {
      revalidateOnFocus: false,
    },
  );

  const primaryMethod = data?.results?.find(
    (method) => method.is_primary,
  );

  return {
    mfaMethods: data?.results,
    primaryMethod,
    isMfaActive: data?.results && data?.results?.length > 0,
    isLoading: isValidating,
    isError: error,
    mutate,
  };
};
