import useSWR from 'swr';
import { useAuth } from '../../context/authContext';
import { client } from '../../api/graphql-client';
import { GetUserProfileMatches } from '../../api/graphql/studies';

const PAGE_LIMIT = 15;

const fetcherGql = (query, variables) =>
  client.request(query, variables);

export const useUserStudies = (page) => {
  const profileId = JSON.parse(
    localStorage.getItem('active-profile-id'),
  );
  const { isAuthenticated, token } = useAuth();

  const { data, error, mutate, isValidating } = useSWR(
    isAuthenticated && token && profileId
      ? [
          GetUserProfileMatches,
          {
            profileUuid: profileId,
            skip: (page - 1) * PAGE_LIMIT,
            limit: PAGE_LIMIT,
          },
        ]
      : null,
    fetcherGql,
    {
      revalidateOnFocus: false,
    },
  );

  const count = data?.profileMatches.count ?? 0;
  const pages = Math.ceil(count / PAGE_LIMIT);
  const position = `${(page - 1) * PAGE_LIMIT + 1}-${Math.min(
    (page - 1) * PAGE_LIMIT + PAGE_LIMIT,
    count,
  )} of ${count}`;

  return {
    position,
    count,
    pages,
    profileMatches: data?.profileMatches.matches ?? [],
    isLoading: isValidating,
    isError: error,
    mutate,
  };
};
