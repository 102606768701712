import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import SiteSelect from './SiteSelect';
import ProtocolSelect from './ProtocolSelect';
import { studies } from '../api/api-wrapper';

const initialValues = {
  site: null,
  currentProtocol: null,
};

const AddStudySiteModal = ({ studyId, isOpen, onClose, mutate }) => {
  const {
    isOpen: isOpenAddress,
    onOpen: onOpenAddress,
    onClose: onCloseAddress,
  } = useDisclosure();

  const [serverError, setServerError] = useState('');
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: initialValues,
  });

  const onSubmit = async (data) => {
    const req = {
      study_uuid: studyId,
      site_uuid: data.site.value,
      current_protocol_uuid: data.protocol.value,
    };

    try {
      const res = await studies.createResearcherStudySite(req);

      if (res.status !== 201) {
        setServerError(res.data?.[0]);
      } else {
        mutate();
        onClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Study Site</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <form
              id="add-studysite-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Stack spacing={4}>
                <SiteSelect control={control} />
                <ProtocolSelect
                  studyId={studyId}
                  control={control}
                  defaultProtocol={null}
                />
              </Stack>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose} mr={4}>
              Cancel
            </Button>
            <Button
              colorScheme="secondary"
              isLoading={isSubmitting}
              loadingText="Saving..."
              type="submit"
              form="add-studysite-form"
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddStudySiteModal;
