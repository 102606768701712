import React from 'react';
import { useUser } from '../context/userContext';
import { ACCOUNT_TYPES } from '../api/types';
import { Redirect } from 'react-router-dom';

const Home = () => {
  const { isLoading: userLoading, user } = useUser();

  const renderDash = () => {
    switch (user.userprofile.account_type.toLowerCase()) {
      case ACCOUNT_TYPES.RESEARCHER:
        return <Redirect to="/researcher" />;
      case ACCOUNT_TYPES.STUDY_SEEKER:
        return <Redirect to="/study-seeker" />;
      default:
        return null;
    }
  };

  return (
    <>
      {userLoading || !user || Object.keys(user).length < 1
        ? null
        : renderDash()}
    </>
  );
};

export default Home;
