import React, { useEffect, useState } from 'react';
import {
  Link as RouterLink,
  Redirect,
  useHistory,
} from 'react-router-dom';
import { useAuth } from '../context/authContext';
import { Controller, useForm } from 'react-hook-form';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  chakra,
  Checkbox,
  CloseButton,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  UnorderedList,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import Logo from '../components/Logo';
import PasswordInput from '../components/PasswordInput';
import PhoneInput from 'react-phone-number-input/react-hook-form-input';
import TermsOfService from './TermsOfService';
import { Flask, Users } from 'phosphor-react';

const initialErrors = [];
const initialRegisterValues = {
  username: '',
  password: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
};

const studySeekerText = () => {
  return (
    <>
      <Text fontSize="sm" fontWeight="bold">
        Your free Personal account will allow you to find the clinical
        trial matches relevant to you.
      </Text>
      <Text fontSize="sm" fontWeight="bold">
        Make sure to use a personal (not a work) email account to
        create this.
      </Text>
    </>
  );
};

const researcherText = () => {
  return (
    <>
      <Text fontSize="sm" fontWeight="bold">
        Your Researcher account will allow you to manage and screen
        matched subjects for your subscribed trials.
      </Text>
      <Text fontSize="sm" fontWeight="bold">
        You can sign up at anytime, and we will contact you if you do
        not yet have an active subscription.
      </Text>
      <Text fontSize="sm" fontWeight="bold">
        Make sure to use a work (not a personal) email account to
        create this.
      </Text>
    </>
  );
};

const Register = () => {
  const { push } = useHistory();
  const { register, isAuthenticated } = useAuth();
  const {
    handleSubmit,
    errors,
    control,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: initialRegisterValues,
  });
  const [serverError, setServerError] = useState(initialErrors);
  const [redirect, setRedirect] = useState('');
  const [agreeTermsOfUse, setAgreedTermsOfUse] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [chosenRole, setChosenRole] = useState('');

  //fix for setting state on unmounted component
  useEffect(() => {
    if (redirect) {
      push(redirect);
    }
  }, [redirect, push]);

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  const resetErrors = () => {
    setServerError(initialErrors);
  };

  const handleCheckTermsOfUse = () => {
    setAgreedTermsOfUse(!agreeTermsOfUse);
  };

  const onSubmit = async (data) => {
    try {
      const res = await register({
        ...data,
        isResearcher: chosenRole === 'researcher',
      });

      if (res.error) {
        setServerError([res.error]);
      } else {
        setRedirect('/registration-success');
      }
    } catch (error) {
      console.log('register errors:', error.message);
      setServerError([error.message]);
    }
  };

  return (
    <Box
      bg="gray.50"
      minH="100vh"
      py={{ base: '4', md: '12' }}
      px={{ base: '4', lg: '8' }}
    >
      <Box maxW="md" mx="auto">
        <Logo
          mx="auto"
          h={{ base: '16', md: '32' }}
          mb={{ base: '4', md: '10' }}
        />
        {chosenRole ? (
          <>
            <VStack spacing={4} mb={8} alignItems="flex=start">
              {chosenRole === 'researcher'
                ? researcherText()
                : studySeekerText()}
              <Text fontSize="sm">
                <Button
                  variant="link"
                  color="secondary.500"
                  mt={'-1px'}
                  fontSize="sm"
                  onClick={() => setChosenRole('')}
                >
                  Click here
                </Button>{' '}
                to change account type.
              </Text>
            </VStack>
            {serverError.length > 0 && (
              <Alert status="error" mb="8">
                <Flex flexDirection="column">
                  <Flex flexDirection="row">
                    <AlertIcon />
                    <AlertTitle>Sorry, there was an error</AlertTitle>
                  </Flex>
                  <UnorderedList px="8">
                    {serverError.map((error, index) => (
                      <ListItem key={index}>{error}</ListItem>
                    ))}
                  </UnorderedList>
                </Flex>
                <CloseButton
                  position="absolute"
                  right="8px"
                  top="8px"
                  onClick={resetErrors}
                />
              </Alert>
            )}
            <Box
              bg="white"
              py="8"
              px={{
                base: '4',
                md: '10',
              }}
              shadow="base"
              rounded={{
                sm: 'lg',
              }}
            >
              <chakra.form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={4} mb={4}>
                  <FormControl
                    id="username"
                    isInvalid={errors.username}
                  >
                    <FormLabel>Email</FormLabel>
                    <Controller
                      as={Input}
                      control={control}
                      rules={{ required: 'Email is required' }}
                      name="username"
                      type="email"
                      autoComplete="email"
                      focusBorderColor="primary.400"
                      borderColor="primary.300"
                      shadow="sm"
                      _hover={{
                        borderColor: 'primary.400',
                      }}
                    />
                    <FormErrorMessage>
                      {errors.username?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <Stack direction="row" spacing="4">
                    <FormControl isInvalid={errors.firstName}>
                      <FormLabel>First name</FormLabel>
                      <Controller
                        as={Input}
                        control={control}
                        rules={{ required: 'First name is required' }}
                        name="firstName"
                        type="firstName"
                        focusBorderColor="primary.400"
                        borderColor="primary.300"
                        shadow="sm"
                        _hover={{
                          borderColor: 'primary.400',
                        }}
                      />
                      <FormErrorMessage>
                        {errors.firstName?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={errors.lastName}>
                      <FormLabel>Last name</FormLabel>
                      <Controller
                        as={Input}
                        control={control}
                        rules={{ required: 'Last name is required' }}
                        name="lastName"
                        type="lastName"
                        focusBorderColor="primary.400"
                        borderColor="primary.300"
                        shadow="sm"
                        _hover={{
                          borderColor: 'primary.400',
                        }}
                      />
                      <FormErrorMessage>
                        {errors.lastName?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </Stack>
                  <FormControl isInvalid={errors.phoneNumber}>
                    <FormLabel>
                      Mobile number (used for SMS)
                    </FormLabel>
                    <PhoneInput
                      name="phoneNumber"
                      control={control}
                      rules={{
                        required: 'Phone number is required',
                      }}
                      defaultCountry="US"
                      inputComponent={Input}
                      focusBorderColor="primary.400"
                      borderColor="primary.300"
                      shadow="sm"
                      _hover={{
                        borderColor: 'primary.400',
                      }}
                    />
                    <FormErrorMessage>
                      {errors.phoneNumber?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <Controller
                    as={PasswordInput}
                    forgotPassword={false}
                    control={control}
                    rules={{ required: 'Password is required' }}
                    name="password"
                    focusBorderColor="primary.400"
                    borderColor="primary.300"
                    shadow="sm"
                    _hover={{
                      borderColor: 'primary.400',
                    }}
                    errors={errors.password}
                  />
                  <FormControl id="termsOfUse">
                    <Checkbox
                      colorScheme="primary"
                      isChecked={agreeTermsOfUse}
                      onChange={handleCheckTermsOfUse}
                    >
                      <Text>
                        I have read and agreed to the{' '}
                        <Button
                          variant="link"
                          color="secondary.500"
                          mt={'-1px'}
                          onClick={onOpen}
                        >
                          Terms of Service and Privacy Policy
                        </Button>
                      </Text>
                    </Checkbox>
                  </FormControl>
                </Stack>
                <Button
                  w="full"
                  type="submit"
                  colorScheme="primary"
                  size="lg"
                  isLoading={isSubmitting}
                  loadingText="Submitting..."
                  disabled={!agreeTermsOfUse || isSubmitting}
                >
                  Create account
                </Button>
              </chakra.form>
            </Box>
          </>
        ) : (
          <>
            <Text mb={8} fontWeight="bold" textAlign="center">
              What kind of account would you like to create?
            </Text>
            <Stack
              w="full"
              spacing={5}
              direction={{ base: 'column', md: 'row' }}
              h={180}
            >
              <Box
                rounded="lg"
                bg="white"
                flex={1}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                p={5}
                boxShadow="0 0 0 1px #e4e7f0"
                overflow="hidden"
                _hover={{
                  boxShadow: '0 0 0 2px #8CABAA',
                  cursor: 'pointer',
                  color: 'primary.500',
                }}
                transition="all .2s ease"
                onClick={() => setChosenRole('studySeeker')}
              >
                <Text mb={2}>Study Seeker</Text>
                <Icon as={Users} fontSize="6xl" />
              </Box>
              <Box
                rounded="lg"
                bg="white"
                flex={1}
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                p={5}
                boxShadow="0 0 0 1px #e4e7f0"
                overflow="hidden"
                _hover={{
                  boxShadow: '0 0 0 2px #8CABAA',
                  cursor: 'pointer',
                  color: 'primary.500',
                }}
                transition="all .2s ease"
                onClick={() => setChosenRole('researcher')}
              >
                <Text mb={2}>Researcher</Text>
                <Icon as={Flask} fontSize="6xl" />
              </Box>
            </Stack>
          </>
        )}
        <Text mt="8" align="center" maxW="md" fontWeight="medium">
          <Text as="span">Already have an account?</Text>{' '}
          <Link as={RouterLink} color="secondary.500" to="/login">
            Sign in
          </Link>
        </Text>
      </Box>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside"
        size="3xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Terms of Service</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TermsOfService />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="primary" mr={4} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Register;
