import React, { useEffect, useState } from 'react';
import { Box, Button, Text } from '@chakra-ui/react';
import { CaretDown, CaretUp } from 'phosphor-react';

const ReadMoreText = ({ text, numLines, id, ...rest }) => {
  const [isOpen, setIsOpen] = useState(false);
  const reference = React.useRef();
  const [isClamped, setIsClamped] = useState(false);

  useEffect(() => {
    if (reference.current) {
      // detect if line clamped
      if (
        reference.current.scrollHeight >
        reference.current.clientHeight
      ) {
        setIsClamped(true);
      }
    }
  }, []);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Box {...rest}>
      <Text
        ref={reference}
        noOfLines={isOpen ? 'none' : numLines}
        textOverflow="ellipsis"
        id={`read-more-${id}`}
        fontSize={16}
      >
        {text}
      </Text>
      {isClamped && (
        <Button
          mt={2}
          variant="link"
          color="secondary.400"
          rightIcon={isOpen ? <CaretUp /> : <CaretDown />}
          onClick={handleClick}
          aria-controls={`read-more-${id}`}
          aria-expanded={isClamped ? isOpen : undefined}
        >
          {isOpen ? 'Read Less' : 'Read More'}
        </Button>
      )}
    </Box>
  );
};

export default ReadMoreText;
