import React, { useEffect } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
  Link,
  Stack,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useLoader } from '../hooks/useLoader';
import { useGetProtocolById } from '../hooks/api/useGetProtocols';
import { useTable } from 'react-table';

const Breadcrumbs = ({ studyId, protocolId }) => {
  return (
    <Breadcrumb
      spacing="8px"
      mb={4}
      fontWeight="medium"
      fontSize="sm"
      color="secondary.400"
    >
      <BreadcrumbItem>
        <BreadcrumbLink as={RouterLink} to="/researcher">
          Home
        </BreadcrumbLink>
      </BreadcrumbItem>

      <BreadcrumbItem>
        <BreadcrumbLink
          as={RouterLink}
          to={`/researcher/study/${studyId}`}
        >
          Study
        </BreadcrumbLink>
      </BreadcrumbItem>

      <BreadcrumbItem isCurrentPage>
        <BreadcrumbLink
          as={RouterLink}
          to={`/researcher/study/protocol/${protocolId}`}
        >
          Protocol
        </BreadcrumbLink>
      </BreadcrumbItem>
    </Breadcrumb>
  );
};

const ProtocolDetail = () => {
  const { protocolId } = useParams();
  const { protocol, arms, isLoading, isError, mutate } =
    useGetProtocolById(protocolId);
  const { show, hide, RenderLoader } = useLoader();

  useEffect(() => {
    if (isError || isLoading) {
      show();
    } else {
      hide();
    }
  }, [isLoading, isError, show, hide]);

  return (
    <>
      <Flex flexDirection="column" w="full">
        <Breadcrumbs
          protocolId={protocolId}
          studyId={protocol?.study}
        />
        <Overview protocol={protocol} mb={8} />
        <Tabs w="full" isLazy={true} lazyBehavior="keepMounted">
          <TabList>
            <Tab>Arms</Tab>
          </TabList>
          <TabPanels w="full">
            <TabPanel px={0}>
              <ArmsPanel
                arms={arms}
                protocol={protocol}
                mutate={mutate}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
      <RenderLoader loadingText="Getting protocol information..." />
    </>
  );
};

const Overview = ({ protocol, ...rest }) => {
  return (
    <>
      <Stack direction="column" spacing={4} {...rest}>
        <Flex alignItems="flex-start">
          <Box>
            <Heading as="h3">{protocol?.version}</Heading>
          </Box>
        </Flex>
      </Stack>
    </>
  );
};

const ArmsPanel = ({ arms, protocol, mutate }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Type',
        accessor: 'arm_type_display',
      },
      {
        Header: 'Min Age',
        accessor: 'min_age',
      },
      {
        Header: 'Max Age',
        accessor: 'max_age',
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: '',
        accessor: 'sq_management',
        Cell: ({ row }) => {
          return (
            <Link
              as={RouterLink}
              color="secondary.500"
              fontWeight="semibold"
              fontSize="sm"
              to={`/researcher/sqie/${row.original.uuid}`}
            >
              Screening Questions
            </Link>
          );
        },
      },
    ],
    [],
  );

  const { getTableProps, getTableBodyProps, rows, prepareRow } =
    useTable({
      columns,
      data: arms,
      initialState: {
        hiddenColumns: columns
          .filter((col) => col.show === false)
          .map((col) => col.accessor),
      },
    });

  return (
    <>
      {/*<Button onClick={onOpen}>Edit Arms</Button>*/}
      <Table
        borderWidth="1px"
        fontSize="sm"
        mt={4}
        {...getTableProps()}
      >
        <Thead bg="gray.50">
          <Tr>
            {columns.map((column, index) => (
              <Th whiteSpace="nowrap" scope="col" key={index}>
                {column.Header}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {(rows.length > 0 &&
            rows.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <Td {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })) || (
            <Tr>
              <Td colSpan={5} textAlign="center">
                No results
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      {/*<EditProtocolArmsModal*/}
      {/*  isOpen={isOpen}*/}
      {/*  onClose={onClose}*/}
      {/*  mutate={mutate}*/}
      {/*  protocol={protocol}*/}
      {/*/>*/}
    </>
  );
};

export default ProtocolDetail;
