import { Box, HStack, Square, Text } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

export const UserMenuItem = ({ icon, title, children, href }) => {
  return (
    <HStack
      as={Link}
      spacing="4"
      py={3}
      px={{ base: 0, md: 3 }}
      rounded="md"
      className="group"
      to={href}
      transition="0.2s background"
      _hover={{
        bg: 'blue-gray.50',
      }}
    >
      <Square
        size="12"
        rounded="md"
        bg="secondary.500"
        color="white"
        fontSize="1.75rem"
      >
        {icon}
      </Square>
      <Box as="dl">
        <Text
          as="dt"
          fontWeight="semibold"
          transition="0.2s all"
          _groupHover={{
            color: 'secondary.500',
          }}
        >
          {title}
        </Text>
        <Text
          as="dt"
          fontSize="sm"
          fontWeight="medium"
          transition="0.2s all"
          _groupHover={{
            color: 'secondary.500',
          }}
        >
          {children}
        </Text>
      </Box>
    </HStack>
  );
};
