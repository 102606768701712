import React, { createContext, useState } from 'react';
import { auth } from '../api/api-wrapper';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { add } from 'date-fns';

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  //TODO: figure out persistent auth instead of isAuth local storage - cookie should help with this
  // refactor error handler
  // success handler with status enum
  //use this later for auto login along with exp dates
  const [isAuthenticated, setAuthenticated] = useLocalStorage(
    false,
    'isAuthenticated',
  );
  const [mfaData, setMfaData] = useLocalStorage({}, 'mfaData');

  //TODO: shouldn't store this in local storage
  const [token, setToken] = useLocalStorage('', 'token');
  const [authExpiration, setAuthExpiration] = useLocalStorage(
    '',
    'auth-expiration',
  );
  const [loginData, setLoginData] = useState(null);

  //TRY: login endpoint can take auth token as header instead of user/pass
  const login = async (data) => {
    localStorage.setItem('active-profile-id', JSON.stringify(''));

    try {
      const res = await auth.login(data);

      if (res.status === 400) {
        if (res.data.non_field_errors) {
          return { error: res.data.non_field_errors };
        }

        return { error: [res.data.error] };
      } else if (res.status >= 200 && res.status < 300) {
        let mfa = {};
        if (res.data.method) {
          // has set mfa preference
          mfa = {
            ephemeralToken: res.data.ephemeral_token,
            method: res.data.method,
          };
          setMfaData(mfa);
          setLoginData(data);
        } else {
          setToken(res.data.auth_token);
          setAuthenticated(true);
          setAuthExpiration(add(new Date(), { hours: 8 }));
        }

        return { ...mfa, msg: 'Successful Sign In!' };
      } else {
        //TODO: handle 404, 500, etc
        return {
          error:
            "We're having trouble with this, please try again in a few minutes",
        };
      }
    } catch (error) {
      console.log('auth context login error', error.response);
      throw new Error(
        "We're having trouble with this, please try again in a few minutes",
      );
    }
  };

  const logout = () => {
    try {
      setAuthenticated(false);
      setAuthExpiration('');
      setLoginData(null);
      localStorage.setItem('active-profile-id', JSON.stringify(''));
      auth.logout().then(() => {
        setToken('');
      });
      return { msg: 'Successful Sign Out' };
    } catch (error) {
      //TODO: handle error responses and then throw user friendly error msg
      console.log('auth context logout error', error.response);
      throw new Error('Logout error!');
    }
  };

  const register = async (data) => {
    try {
      const registerData = {
        email: data.username,
        username: data.username,
        password: data.password,
        re_password: data.password,
        first_name: data.firstName,
        last_name: data.lastName,
        phone_number: data.phoneNumber,
        is_researcher: data.isResearcher,
      };

      const res = await auth.register(registerData);

      if (res.status === 400) {
        let errors = Object.entries(res.data).reduce(
          (acc, [key, val]) => {
            return [...acc, `${key}: ${val}`];
          },
          [],
        );

        return { error: errors };
      } else {
        return { msg: 'Successful Registration!' };
      }
    } catch (error) {
      console.log('auth context sign up error', error.response);
      throw new Error(
        "We're having trouble with this, please try again in a few minutes",
      );
    }
  };

  //TODO: cleanup this error handling
  const activateAccount = async (data) => {
    try {
      const res = await auth.activateAccount(data);

      switch (res.status) {
        case 204:
          return { message: 'Successful Account Activation!' };
        case 400: //bad request
        case 403: //stale token
        default:
          throw new Error();
      }
    } catch (error) {
      console.log(
        'auth context account activation error',
        error.response,
      );
      throw new Error('Account activation error!');
    }
  };

  const resetPassword = async (data) => {
    try {
      await auth.resetPassword(data);
      return { message: 'Successful Reset Password!' };
    } catch (error) {
      console.log(
        'auth context reset password error',
        error.response,
      );
      throw new Error('Reset password error!');
    }
  };

  const resetPasswordConfirm = async ({
    password,
    confirmPassword,
    ...rest
  }) => {
    try {
      const res = await auth.resetPasswordConfirm({
        ...rest,
        new_password: password,
        re_new_password: confirmPassword,
      });

      if (res.status === 400) {
        let errors = Object.entries(res.data).reduce(
          (acc, [key, val]) => {
            return [...acc, `${key}: ${val}`];
          },
          [],
        );

        return { error: errors };
      } else if (res.status === 403) {
        return { error: [res.data.detail] };
      } else {
        return { message: 'Successful Reset Password Confirmation!' };
      }
    } catch (error) {
      console.log(
        'auth context reset password confirmation error',
        error.response,
      );
      throw new Error('Reset password confirmation error!');
    }
  };

  const setPassword = async (data) => {
    try {
      const res = await auth.setPassword({
        current_password: data.currentPassword,
        new_password: data.newPassword,
      });

      if (res.status === 400) {
        let errors = Object.entries(res.data).reduce(
          (acc, [key, val]) => {
            return [...acc, `${key}: ${val}`];
          },
          [],
        );

        return { error: errors };
      } else if (res.status === 403) {
        return { error: [res.data.detail] };
      } else {
        return { message: 'Successful Set Password!' };
      }
    } catch (error) {
      console.log('auth context set password error', error.response);
      throw new Error('Set password error!');
    }
  };

  const enableMfa = async (mfaType) => {
    try {
      //TODO: handle possible 400 responses?
      if (mfaType === 'email') {
        await auth.enableMfaEmail();
      } else if (mfaType === 'sms') {
        await auth.enableMfaSms();
      }

      setMfaData((prevState) => ({ ...prevState, method: mfaType }));
      return { message: 'Successful MFA Enable!' };
    } catch (error) {
      console.log('auth context MFA error', error.response);
      throw new Error('MFA error!');
    }
  };

  const confirmMfaCode = async (code) => {
    try {
      let res;
      let mfaCodeData = { code };

      if (mfaData.ephemeralToken) {
        res = await auth.confirmMfaLogin({
          ...mfaCodeData,
          ephemeral_token: mfaData.ephemeralToken,
        });
      } else {
        switch (mfaData.method) {
          case 'email':
            res = await auth.confirmMfaEmail(mfaCodeData);
            break;
          case 'sms':
            res = await auth.confirmMfaSms(mfaCodeData);
            break;
          default:
        }
      }

      if (res.status === 400) {
        return { error: res.data.non_field_errors };
      } else {
        if (res.data.auth_token) {
          setToken(res.data.auth_token);
        }
        setAuthenticated(true);
        setAuthExpiration(add(new Date(), { hours: 8 }));
        setMfaData({});
        setLoginData(null);
        return { message: 'Successful Sign In!' };
      }
    } catch (error) {
      console.log('auth context MFA error', error.response);
      throw new Error('MFA error!');
    }
  };

  const resendMfaCode = async () => {
    const res = await auth.login(loginData);

    if (res.status === 400) {
      return { error: res.data.non_field_errors };
    } else {
      const mfa = {
        ephemeralToken: res.data.ephemeral_token,
        method: res.data.method,
      };
      setMfaData(mfa);

      return { msg: 'Successful Resend MFA Code!' };
    }
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setAuthenticated,
        token,
        setToken,
        mfaData,
        authExpiration,
        setAuthExpiration,
        login,
        logout,
        register,
        activateAccount,
        resetPassword,
        resetPasswordConfirm,
        setPassword,
        enableMfa,
        confirmMfaCode,
        resendMfaCode,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
};

export { AuthProvider, useAuth };
