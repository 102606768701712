import axios from 'axios';

// API Axios client
export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_HW_API_URL,
  responseType: 'json',
  timeout: 180000,
  validateStatus: (status) => {
    // 201 created
    // 204 account activated
    // 400 bad request,
    // 403 happens in the case a user has survey questions,
    // 404 happens when no more matches on researcher side,
    // 404 happens on no more user mfa methods in account settings
    if (status === 401) {
      // localStorage.clear();
      localStorage.setItem('isAuthenticated', JSON.stringify(false));
      localStorage.setItem('token', JSON.stringify(''));
      localStorage.setItem('auth-expiration', JSON.stringify(''));
    }

    return (
      (status >= 200 && status < 300) ||
      status === 400 ||
      status === 403 ||
      status === 404
    );
  },
});

//TODO: switch to this when we concentrate on auth in prod.  can't use this with wildcard origin header from server
// apiClient.defaults.withCredentials = true;
apiClient.interceptors.request.use(
  function (config) {
    const token = JSON.parse(localStorage.getItem('token'));
    if (token) {
      config.headers.Authorization = `Token ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export const lambdaApiClient = axios.create({
  baseURL: process.env.REACT_APP_HW_LAMBDA_URL,
  responseType: 'json',
  timeout: 180000,
});

export const auth = {
  register: (data) => apiClient.post('/auth/users/', data),
  activateAccount: (data) =>
    apiClient.post('/auth/users/activation/', data),
  login: (data) => apiClient.post('/auth/login/', data),
  logout: () => apiClient.post('/auth/logout/'),
  enableMfaEmail: () => apiClient.post('/auth/email/activate/'),
  confirmMfaEmail: (data) =>
    apiClient.post('/auth/email/activate/confirm/', data),
  enableMfaSms: () => apiClient.post('/auth/sms_twilio/activate/'),
  confirmMfaSms: (data) =>
    apiClient.post('/auth/sms_twilio/activate/confirm/', data),
  activateMfaMethod: (data) =>
    apiClient.post(`/auth/${data}/activate/`),
  confirmMfaMethod: (method, data) =>
    apiClient.post(`/auth/${method}/activate/confirm/`, data),
  sendMfaCode: (data) => apiClient.post('/auth/code/request/', data),
  deactivateMfa: (data) =>
    apiClient.post(`/auth/${data}/deactivate/`),
  confirmMfaLogin: (data) =>
    apiClient.post('/auth/login/code/', data),
  resetPassword: (data) =>
    apiClient.post('/auth/users/reset_password/', data),
  resetPasswordConfirm: (data) =>
    apiClient.post('/auth/users/reset_password_confirm/', data),
  setPassword: (data) =>
    apiClient.post('/auth/users/set_password/', data),
};

export const diseases = {
  search: (query) =>
    lambdaApiClient.get('/searchDiseases?query=' + query),
};

export const onboarding = {
  sendDemographics: (data) =>
    apiClient.post('/userdemographicsprofile/', data),
  updateContact: (data) => apiClient.put('/usercontact/me/', data),
  sendCondition: (data) => apiClient.post('/userconditions/', data),
  postQuestionResponse: (data) =>
    apiClient.post('/userquestions/next_respond/', data),
};

export const studies = {
  sendSignUp: (uuid, data) =>
    apiClient.post(`/userstudies/${uuid}/signup/`, data),
  editStudy: (uuid, data) =>
    apiClient.put(`/userstudies/${uuid}/`, data),
  createNoteForStudy: (data) => apiClient.post(`/usernotes/`, data),
  editNoteForStudy: (uuid, data) =>
    apiClient.put(`/usernotes/${uuid}/`, data),
  findProfileStudyMatches: (uuid) =>
    apiClient.post(`/profiles/${uuid}/matches/`),
  linkResearcherToStudySite: (uuid, data) =>
    apiClient.post(`/studysites/${uuid}/join/`, data),
  createResearcherStudy: (data) => apiClient.post('/studies/', data),
  editResearcherStudy: (uuid, data) =>
    apiClient.put(`/studies/${uuid}/`, data),
  getStudyProtocolVersions: (studyId) =>
    apiClient.get(`/protocols/?study=${studyId}`),
  editStudySiteProtocolVersion: (studySiteId, data) =>
    apiClient.patch(`/studysites/${studySiteId}/`, data),
  createResearcherStudySite: (data) =>
    apiClient.post('/studysites/', data),
  createResearcherSite: (data) => apiClient.post('/sites/', data),
  createResearcherStudyProtocol: (data) =>
    apiClient.post('/protocols/', data),
  createResearcherArm: (data) => apiClient.post('/arms/', data),
  editResearcherStudyProtocol: (data) =>
    apiClient.put('/protocols/', data),
};

export const matches = {
  sendContactAttempt: (data) =>
    apiClient.post('/matches/next_match/', data),
  refresh: () => apiClient.post('participationrequests/refresh'),
};

export const admin = {
  createSeekerProfile: (data) => apiClient.post('/profiles/', data),
  updateSeekerProfile: (uuid, data) =>
    apiClient.put(`/profiles/${uuid}/`, data),
  createProfileCondition: (data) =>
    apiClient.post('/profiles/condition/', data),
};

export const fetcher = (url) =>
  apiClient.get(url).then((res) => res.data);
