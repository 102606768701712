import useSWR from 'swr';
import { client } from '../../api/graphql-client';
import { GetNextPersonalizationQuestion } from '../../api/graphql/screening-questions';

const fetcherGql = (query, variables) =>
  client.request(query, variables);

export const useNextQuestion = () => {
  const profileId = JSON.parse(
    localStorage.getItem('active-profile-id'),
  );
  //TODO: doesn't really need swr here
  const { data, error, mutate, isValidating } = useSWR(
    [GetNextPersonalizationQuestion, { profileUuid: profileId }],
    fetcherGql,
    {
      revalidateOnFocus: false,
    },
  );

  return {
    question: data?.nextPersonalizationQuestion,
    mutate,
    isLoading: !data && isValidating,
    isError: error,
  };
};
