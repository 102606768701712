import React, { createContext } from 'react';
import { apiClient } from '../api/api-wrapper';
import useSWR from 'swr';
import { useAuth } from './authContext';

const userUrl = 'auth/users/me/';
const profilesUrl = 'accounts/profile';

const fetcher = (...urls) => {
  const f = (u) => apiClient.get(u).then((r) => r.data);

  if (urls.length > 1) {
    return Promise.all(urls.map(f));
  }

  return f(urls);
};

const UserContext = createContext({});

const UserProvider = ({ children }) => {
  const { isAuthenticated, token } = useAuth();
  const { data, error, mutate } = useSWR(
    isAuthenticated && token ? [userUrl, profilesUrl] : null,
    fetcher,
    {
      revalidateOnFocus: false,
      // revalidateOnMount: false,
      // dedupingInterval: 900000, // 15 min
    },
  );

  let user = {};
  if (data?.length > 0) {
    user = {
      ...data[0],
      ...data[1],
      fullName: data[0].first_name + ' ' + data[0].last_name,
    };
  }

  return (
    <UserContext.Provider
      value={{
        user,
        isLoading: !error && !data,
        isError: error,
        mutate,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUser = () => {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`);
  }
  return context;
};

export { UserProvider, useUser };
