export const GetPrForUser = `
  query GetPrForUser {
    participationRequestsForUser {
      id
      isLocked
      isOpened
      isNeedsReview
      isConcluded
      updatedAt    
      status
      studySite {
        uuid
        site {
          name
        }
        currentProtocol {
          version
        }
        study {
          healthwalletId
          nctId
          formalName
          overallStatus
        }
      }
    }
  }`;

export const GetPrForResearcher = `
  query GetPrForResearcher {
    participationRequestsForResearcher {
      id
      isLocked
      isOpened
      isNeedsReview
      isConcluded
      updatedAt    
      status
      studySite {
        uuid
        site {
          name
        }
        currentProtocol {
          version
        }
        study {
          healthwalletId
          nctId
          formalName
          overallStatus
        }
      }
      contactInfo {
        fullName
        phoneNumber
        email
      }
    }
  }`;

export const GetContactAttemptsByPrId = `
  query GetContactAttemptsByPrId($prId: String) {
    contactAttemptsByPrId(prId: $prId) {
      participationRequest
      createdAt
      method
      result
    }
  }`;

export const CreatePr = `
  mutation CreatePr($input: CreateParticipationRequestMutationInput!) {
    createParticipationRequest(input: $input) {
      id
    }
  }`;

export const UpdateContactAttempt = ``;

export const UpdateParticipationRequest = ``;

export const NextResearcherParticipationRequest = `
  mutation NextResearcherPr() {
    createParticipationRequest(input: $input) {
      id
    }
  }`;
