export const CreateStudyNote = `
  mutation CreateStudyNote($profileUuid: UUID, $studyUuid: UUID, $text: String) {
    createStudyNote(profileUuid: $profileUuid, studyUuid: $studyUuid, text: $text) {
      studyNote {
        id
        text
      }
    }
  }`;

export const UpdateStudyNote = `
  mutation UpdateStudyNote($id: UUID, $text: String) {
    updateStudyNote(id: $id, text: $text) {
      studyNote {
        id
        text
      }
    }
  }`;
