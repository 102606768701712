import React from 'react';
import ConditionAutocomplete from './ConditionAutocomplete';
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
} from '@chakra-ui/react';
import { MinusCircle } from 'phosphor-react';
import { Controller } from 'react-hook-form';

//TODO: diagnosis date
const ConditionGroupInput = ({
  item,
  error,
  control,
  index,
  remove,
  removable,
}) => {
  return (
    <>
      <FormControl
        id={`condition[${index}]`}
        isInvalid={error?.name}
        isRequired
      >
        <FormLabel>Condition</FormLabel>
        <Flex
          id={`condition[${index}]-group-container`}
          sx={{
            '& div:first-of-type': {
              flex: 1,
            },
          }}
        >
          <Controller
            control={control}
            name={`condition[${index}].name`}
            defaultValue={item.name}
            render={({ value, onChange }) => {
              return (
                <ConditionAutocomplete
                  name={`condition[${index}].name`}
                  value={value}
                  onChange={onChange}
                  error={error?.name}
                />
              );
            }}
            rules={{
              validate: (value) => {
                if (!value || Array.isArray(value)) {
                  return 'Condition is required';
                }

                return value;
              },
            }}
          />
          <IconButton
            aria-label="Remove condition"
            variant="link"
            disabled={!removable}
            onClick={remove}
            icon={
              <Box as={MinusCircle} fontSize="2xl" color="red.600" />
            }
          />
        </Flex>
        <FormErrorMessage>{error?.name?.message}</FormErrorMessage>
      </FormControl>
    </>
  );
};

export default ConditionGroupInput;
