export const GetUserProfileMatches = `
  query GetUserProfileMatches($profileUuid: UUID!, $studyUuids: [UUID], $skip: Int, $limit: Int) {
    profileMatches(profileUuid: $profileUuid, studyUuids: $studyUuids, skip: $skip, limit: $limit) {
      count
      matches {
        study {
          healthwalletId
          nctId
          formalName
          description
          overallStatus
          protocols {
            arms {
              uuid
              name
              description
              isRecruiting
              isActive
              armType
              conditions {
                name
              }
              interventions {
                name
                category
              }
            }
            studysiteSet {
              uuid
              site {
                name
                address {
                  raw
                }
              }
            }
          }
        }
        notes {
          id
          text
          createdAt
          updatedAt
        }
        preferredStudySite {
          uuid
          site {
            name
            address {
              raw
            }
          }
          currentProtocol {
            arms {
              uuid
              name
              description
              isRecruiting
              isActive
              armType
              conditions {
                name
              }
              interventions {
                name
                category
              }
            }
          }
        }
        participationRequest {
          id
          isLocked
          isOpened
          isNeedsReview
          isConcluded
          studySite {
            uuid
            site {
              name
              address {
                raw
              }
            }
            study {
              healthwalletId
              nctId
              formalName
            }
          }
          updatedAt    
          status
        }
        screeningQuestionsAvailable
        screeningQuestionsUnanswered
      }
    }
  }`;

export const GetStudy = `
  query GetStudy($studyId: UUID!) {
    study(id: $studyId) {
      healthwalletId
      nctId
      formalName
      description
      overallStatus
      protocols {
        arms {
          uuid
          name
          description
          isRecruiting
          isActive
          armType
          conditions {
            name
          }
          interventions {
            name
            category
          }
        }
      }
    }
  }`;
