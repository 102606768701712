import useSWR from 'swr';
import { client } from '../../api/graphql-client';
import { GetPrForResearcher } from '../../api/graphql/matches';
import { useAuth } from '../../context/authContext';

const fetcher = (query, variables) =>
  client.request(query, variables);

export const useMatches = () => {
  const { isAuthenticated, token } = useAuth();
  const { data, error, mutate, isValidating } = useSWR(
    isAuthenticated && token ? GetPrForResearcher : null,
    fetcher,
    {
      revalidateOnFocus: false,
    },
  );

  return {
    matches: data?.participationRequestsForResearcher ?? [],
    isLoading: !error && !data,
    isError: error,
  };
};

// study site
// 8b910979-258c-49da-a88b-a813dd7533e2

// profile id
// 5a08231f-4983-4c00-ad13-121fae7efde7
