import { apiClient } from '../../api/api-wrapper';
import { useAuth } from '../../context/authContext';
import useSWR from 'swr';

const fetcher = (...args) =>
  apiClient.get(...args).then((res) => res.data);

export const useResearcherStudies = () => {
  const { isAuthenticated, token } = useAuth();
  const { data, error, isValidating, mutate } = useSWR(
    isAuthenticated && token ? `/researchers/` : null,
    fetcher,
    {
      revalidateOnFocus: false,
      dedupingInterval: 900000, // 15 min
    },
  );

  const resultsMergedMap =
    data?.results
      ?.map((result) => {
        return result.studysite_set.map((studysite) => {
          return {
            roles: [
              {
                value: result.role,
                name: result.role_name,
              },
            ],
            site: studysite.site,
            uuid: studysite.uuid,
            currentProtocol: studysite.current_protocol,
            study: {
              ...studysite.study,
              uuid: studysite.study_uuid,
            },
          };
        });
      })
      .flat()
      .reduce((obj, item) => {
        obj[item.uuid]
          ? obj[item.uuid].roles.push(...item.roles)
          : (obj[item.uuid] = { ...item });
        return obj;
      }, {}) || [];

  const reducedResultsArray = Object.values(resultsMergedMap);

  return {
    studies: reducedResultsArray,
    isLoading: !data || isValidating,
    isError: error,
    mutate,
  };
};
