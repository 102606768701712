import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import SortableTree, {
  addNodeUnderParent,
  changeNodeAtPath,
  getNodeAtPath,
  getVisibleNodeCount,
  removeNode,
} from '@nosferatu500/react-sortable-tree';
import HWSelect from './HWSelect';
import { EA_ANSWER_TYPES, EA_OPERATOR_TYPES } from '../data/types';
import { Controller, useForm, useWatch } from 'react-hook-form';
import ReactDatePicker from 'react-datepicker';
import { useParams } from 'react-router-dom';
import { SQ_PARENT_RELATION_TYPE } from '../api/types';
import { format } from 'date-fns';
import { useGetQuestions } from '../hooks/api/useQuestions';
import { client } from '../api/graphql-client';
import {
  CreateScreeningQuestion,
  DeleteScreeningQuestion,
} from '../api/graphql/screening-questions';
import '@nosferatu500/react-sortable-tree/style.css';
import { useUser } from '../context/userContext';

const TreeNode = ({ data }) => {
  return <Box>{data.node.title}</Box>;
};

const SqieTree = () => {
  const { armId } = useParams();
  const { questions, isLoading, error } = useGetQuestions(armId);

  if (isLoading) {
    return <Box>Loading...</Box>;
  }

  return <TreeList data={questions} />;
};

const TreeList = ({ data }) => {
  const { user } = useUser();
  const [treeList, setTreeList] = useState(data);

  const handleCreateTree = () => {
    const treeId = Date.now().toString();

    setTreeList([
      ...treeList,
      {
        treeId,
        treeData: [
          {
            title: 'New question',
            expanded: true,
            children: [],
          },
        ],
      },
    ]);
  };

  const handleUpdateTree = (treeId, treeData) => {
    const newTreeList = [...treeList];
    let treeToChangeIndex = treeList.findIndex(
      (tree) => tree.treeId === treeId,
    );
    newTreeList[treeToChangeIndex] = {
      ...treeList[treeToChangeIndex],
      treeData,
    };

    setTreeList(newTreeList);
  };

  return (
    <Flex direction="column" flex={1}>
      {user?.features.switches.sq_management && (
        <Flex justify="flex-end" mb={4}>
          <Button colorScheme="secondary" onClick={handleCreateTree}>
            Create Question Tree
          </Button>
        </Flex>
      )}
      {treeList.map((tree) => {
        return (
          <Box mb={4} key={tree.treeId}>
            <TreeComponent
              canEdit={user?.features.switches.sq_management}
              treeId={tree.treeId}
              treeData={tree.treeData}
              handleUpdateTree={handleUpdateTree}
            />
          </Box>
        );
      })}
    </Flex>
  );
};

//TODO: have a 'save tree' button that saves the tree to account for drag/drop changes - don't need to save every question then?
//TODO: delete should delete the node and all of its children?
const TreeComponent = ({
  treeId,
  treeData,
  handleUpdateTree,
  canEdit,
}) => {
  const {
    isOpen: isOpenDrawer,
    onOpen: onOpenDrawer,
    onClose: onCloseDrawer,
  } = useDisclosure();
  const [editingQuestion, setEditingQuestion] = useState(null);

  const handleTreeOnChange = (data) => {
    handleUpdateTree(treeId, data);
  };

  const handleAddOrNode = (path) => {
    const parentNode = getNodeAtPath({
      treeData,
      path,
      getNodeKey: ({ treeIndex }) => treeIndex,
    });

    const newTreeData = addNodeUnderParent({
      treeData: treeData,
      parentKey: path[path.length - 2],
      expandParent: true,
      getNodeKey: ({ treeIndex }) => treeIndex,
      newNode: {
        title: `New question`,
        parentRelation: SQ_PARENT_RELATION_TYPE.OR,
        parentSqId: parentNode.node.sqId,
        expanded: true,
        children: [],
      },
    });

    handleUpdateTree(treeId, newTreeData.treeData);
  };

  const handleAddAndNode = (path) => {
    const parentNode = getNodeAtPath({
      treeData,
      path,
      getNodeKey: ({ treeIndex }) => treeIndex,
    });

    const newTreeData = addNodeUnderParent({
      treeData: treeData,
      parentKey: path[path.length - 1],
      expandParent: true,
      getNodeKey: ({ treeIndex }) => treeIndex,
      newNode: {
        title: `New question`,
        parentRelation: SQ_PARENT_RELATION_TYPE.AND,
        parentSqId: parentNode.node.sqId,
        expanded: true,
        children: [],
      },
    });

    handleUpdateTree(treeId, newTreeData.treeData);
  };

  const handleDeleteNode = async (node) => {
    const newTreeData = removeNode({
      treeData: treeData,
      path: node.path,
      ignoreCollapsed: true,
      getNodeKey: ({ treeIndex }) => treeIndex,
    });

    try {
      if (node.node.sqId) {
        await client.request(DeleteScreeningQuestion, {
          id: node.node.sqId,
        });
      }
    } catch (error) {
      console.log(error);
    }

    handleUpdateTree(treeId, newTreeData.treeData);
  };

  const handleEditNode = (node) => {
    setEditingQuestion(node);
    onOpenDrawer();
  };

  const handleCloseDrawer = () => {
    setEditingQuestion(null);
    onCloseDrawer();
  };

  const handleSaveNode = (formData) => {
    const newTreeData = changeNodeAtPath({
      treeData: treeData,
      path: editingQuestion.path,
      newNode: {
        ...editingQuestion.node,
        ...formData,
      },
      getNodeKey: ({ treeIndex }) => treeIndex,
    });

    handleUpdateTree(treeId, newTreeData);
    handleCloseDrawer();
  };

  const count = getVisibleNodeCount({ treeData });
  return (
    <>
      <SortableTree
        rowHeight={100}
        style={{ height: count * 110 }}
        // isVirtualized={false}
        canDrag={false}
        treeData={treeData}
        onChange={handleTreeOnChange}
        generateNodeProps={(node) => {
          return {
            title: <TreeNode data={node} />,
            buttons: [
              <HStack spacing={4}>
                <Button
                  onClick={(e) => handleAddAndNode(node.path)}
                  aria-label="add and"
                  variant="link"
                  colorScheme="secondary"
                  disabled={!node.node.sqId || !canEdit}
                >
                  Add AND
                </Button>
                <Button
                  onClick={(e) => handleAddOrNode(node.path)}
                  aria-label="add or"
                  variant="link"
                  colorScheme="secondary"
                  disabled={!node.node.sqId || !canEdit}
                >
                  Add OR
                </Button>
                <Button
                  onClick={(e) => handleEditNode(node)}
                  aria-label="add or"
                  variant="link"
                  colorScheme="primary"
                  disabled={!canEdit}
                >
                  Edit
                </Button>
                <Button
                  onClick={(e) => handleDeleteNode(node)}
                  aria-label="add or"
                  variant="link"
                  colorScheme="red"
                  disabled={!canEdit}
                >
                  Delete
                </Button>
              </HStack>,
            ],
          };
        }}
      />
      <EditNodeDrawer
        nodeData={editingQuestion}
        isOpen={isOpenDrawer}
        onClose={handleCloseDrawer}
        size="md"
        onSave={handleSaveNode}
      />
    </>
  );
};

const EditNodeDrawer = ({ nodeData, isOpen, onClose, onSave }) => {
  console.log({ nodeData });
  let initValues = {
    title: nodeData?.node.title ?? '',
    questionHelperText: nodeData?.node.questionHelperText ?? '',
    answerType: nodeData?.node.answerType ?? {
      value: 'BOOL',
      label: 'Yes/No',
    },
    answerOperator: nodeData?.node.answerOperator ?? {
      value: 'EQ',
      label: 'Equals',
    },
    booleanAnswerValue: nodeData?.node.booleanAnswerValue ?? {
      value: 'True',
      label: 'Yes',
    },
    dateAnswerValue: nodeData?.node.dateAnswerValue ?? new Date(),
    decimalAnswerValue: nodeData?.node.decimalAnswerValue ?? 0,
    integerAnswerValue: nodeData?.node.integerAnswerValue ?? 0,
  };
  const { armId } = useParams();

  const {
    handleSubmit,
    errors,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: initValues,
  });

  const answerTypeWatch = useWatch({
    control,
    name: 'answerType',
  });

  useEffect(() => {
    reset(initValues);
  }, [nodeData, reset]);

  const handleSaveNode = async (data) => {
    let answerValue;
    switch (data.answerType?.value) {
      case 'BOOL':
        answerValue = data.booleanAnswerValue.value;
        break;
      case 'DATE':
        answerValue = format(data.dateAnswerValue, 'yyyy-MM-dd');
        break;
      case 'DEC':
        answerValue = data.decimalAnswerValue;
        break;
      case 'INT':
        answerValue = data.integerAnswerValue;
        break;
      default:
        answerValue = null;
    }

    const commonInput = {
      armUuid: armId,
    };

    const createInput = {
      ...commonInput,
      question: {
        displayText: data.title,
        helpText: data.questionHelperText, //TODO: required???
      },
      expectedAnswer: {
        operator: data.answerOperator.value,
        answerType: data.answerType.value,
        value: answerValue,
      },
      ...(nodeData.node.parentRelation && {
        parentUuid: nodeData.node.parentSqId,
        parentRelation: nodeData.node.parentRelation,
      }),
    };

    try {
      const req = await client.request(CreateScreeningQuestion, {
        input: createInput,
      });

      onSave({
        ...data,
        sqId: req.createScreeningQuestion.screeningQuestion.id,
      });
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size="md">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          Update Screening Question ([{nodeData?.path.toString()}])
        </DrawerHeader>

        <DrawerBody>
          <form
            id="update-question-form"
            onSubmit={handleSubmit(handleSaveNode)}
          >
            <Stack spacing={4} flex={1}>
              <FormControl isRequired>
                <FormLabel>Question</FormLabel>
                <Controller
                  as={Input}
                  control={control}
                  rules={{ required: 'Question is required' }}
                  name="title"
                  focusBorderColor="primary.400"
                  borderColor="primary.300"
                  shadow="sm"
                  _hover={{
                    borderColor: 'primary.400',
                  }}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Question Helper Text</FormLabel>
                <Controller
                  as={Input}
                  control={control}
                  name="questionHelperText"
                  focusBorderColor="primary.400"
                  borderColor="primary.300"
                  shadow="sm"
                  _hover={{
                    borderColor: 'primary.400',
                  }}
                />
              </FormControl>
              <Box>Answers required to be eligible</Box>
              <AnswerTypeInput
                control={control}
                answerType={answerTypeWatch?.value}
              />
            </Stack>
          </form>
        </DrawerBody>

        <DrawerFooter>
          <Button variant="outline" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            isLoading={isSubmitting}
            loadingText="Saving..."
            type="submit"
            form="update-question-form"
          >
            Save
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

const AnswerTypeInput = ({ answerType, control }) => {
  const renderControl = () => {
    switch (answerType) {
      case 'BOOL':
        return (
          <>
            <FormControl id={'boolean-answer'}>
              <FormLabel>Answer Value</FormLabel>
              <Controller
                control={control}
                name={'booleanAnswerValue'}
                defaultValue={{ value: 'True', label: 'Yes' }}
                render={({ value, onChange }) => {
                  return (
                    <HWSelect
                      options={[
                        {
                          value: 'True',
                          label: 'Yes',
                        },
                        {
                          value: 'False',
                          label: 'No',
                        },
                      ]}
                      value={value}
                      onChange={onChange}
                    />
                  );
                }}
              />
            </FormControl>
          </>
        );
      case 'DATE':
        return (
          <>
            <FormControl id={'date-answer'}>
              <FormLabel>Answer Value</FormLabel>
              <Controller
                control={control}
                name={'dateAnswerValue'}
                type="date"
                render={({ value, onChange }) => {
                  return (
                    <ReactDatePicker
                      dateFormat="yyyy-MM-dd"
                      placeholderText="Select date"
                      onChange={onChange}
                      selected={
                        value instanceof Date &&
                        !isNaN(value.valueOf())
                          ? value
                          : null
                      }
                      customInput={
                        <Input
                          focusBorderColor="primary.400"
                          borderColor="primary.300"
                          shadow="sm"
                          _hover={{
                            borderColor: 'primary.400',
                          }}
                        />
                      }
                      shouldCloseOnSelect
                      showPopperArrow={false}
                      popperPlacement="bottom-start"
                    />
                  );
                }}
              />
            </FormControl>
          </>
        );
      case 'DEC':
        return (
          <>
            <FormControl id={'decimal-answer'}>
              <FormLabel>Answer Value</FormLabel>
              <Controller
                as={Input}
                type="number"
                control={control}
                name={'decimalAnswerValue'}
              />
            </FormControl>
          </>
        );
      case 'INT':
        return (
          <>
            <FormControl id={'integer-answer'}>
              <FormLabel>Answer Value</FormLabel>
              <Controller
                as={Input}
                type="number"
                step="1"
                control={control}
                name={'integerAnswerValue'}
              />
            </FormControl>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <FormControl id={'answer-type'}>
        <FormLabel>Answer Type</FormLabel>
        <Controller
          control={control}
          name={'answerType'}
          render={({ value, onChange }) => {
            return (
              <HWSelect
                options={EA_ANSWER_TYPES}
                name={'answerType'}
                value={value}
                onChange={onChange}
              />
            );
          }}
        />
      </FormControl>
      <FormControl id={'answer-operator'}>
        <FormLabel>Answer Operator</FormLabel>
        <Controller
          control={control}
          name={'answerOperator'}
          render={({ value, onChange }) => {
            return (
              <HWSelect
                options={EA_OPERATOR_TYPES}
                name={'answerOperator'}
                value={value}
                onChange={onChange}
              />
            );
          }}
        />
      </FormControl>
      {renderControl()}
    </>
  );
};

export default SqieTree;
