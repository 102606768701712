import { diseases } from '../api/api-wrapper';
import React from 'react';
import { AsyncSelect } from 'chakra-react-select';
import { useDebouncedCallback } from 'use-debounce';

//TODO: error
const getOptions = (query) =>
  diseases.search(query).then((resp) => resp.data);

const ConditionAutocomplete = ({ onChange, value, name, error }) => {
  const loadOptions = useDebouncedCallback((inputText, callback) => {
    getOptions(inputText).then((options) => {
      const conditions = options.map((option) => ({
        value: option,
        label: option,
      }));

      callback(conditions);
    });
  }, 500);

  return (
    <AsyncSelect
      classNamePrefix="hw-condition-select"
      name={name}
      value={value}
      isClearable
      loadOptions={loadOptions}
      defaultValue={[]}
      onChange={onChange}
      focusBorderColor="primary.400"
      selectedOptionColor="primary"
      placeholder="Search for a condition..."
    />
  );
};

export default ConditionAutocomplete;
