import React, { useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useMfaMethods } from '../hooks/api/useMfaMethods';
import ChangePasswordModal from '../components/ChangePasswordModal';
import { useUser } from '../context/userContext';
import { MFA_ACTIONS } from '../api/types';
import UpdateMfaModal from '../components/UpdateMfaModal';
import LoadingScreen from '../components/LoadingScreen';

const Account = () => {
  const { user, isLoading: isUserLoading } = useUser();
  const { primaryMethod, isMfaActive } = useMfaMethods();
  const [mfaAction, setMfaAction] = useState(null);

  const {
    isOpen: isChangePasswordOpen,
    onOpen: onChangePasswordOpen,
    onClose: onChangePasswordClose,
  } = useDisclosure();

  const {
    isOpen: isChangeMfaOpen,
    onOpen: onChangeMfaOpen,
    onClose: onChangeMfaClose,
  } = useDisclosure();

  const handleUpdateMfa = (action) => {
    setMfaAction(action);
    onChangeMfaOpen();
  };

  return (
    <Box
      maxW={{ base: 'xl', md: '2xl' }}
      mx="auto"
      px={{
        base: 6,
        md: '12',
      }}
      py={{
        base: 3,
        md: 12,
      }}
    >
      {isUserLoading ? (
        <LoadingScreen loadingText={'Loading...'} show={true} />
      ) : (
        <>
          <Stack spacing={6} divider={<Divider />}>
            <Heading fontWeight="semibold">Account Settings</Heading>
            <Stack spacing={4}>
              <HStack spacing={6}>
                <Avatar name={user?.fullName} />
                <Box>
                  <Text>{user?.fullName}</Text>
                  <Text color="gray.500">{user?.email}</Text>
                  <Text color="gray.500">
                    {user?.usercontactprofile?.phone_number}
                  </Text>
                </Box>
              </HStack>
              <Button
                w="fit-content"
                variant="link"
                colorScheme="secondary"
                onClick={onChangePasswordOpen}
              >
                Change password
              </Button>
            </Stack>
            <Stack spacing={4}>
              <HStack spacing={1}>
                <Text as="span">
                  Multi-factor authentication is turned
                </Text>
                <Text as="span" fontWeight="bold" color="primary.600">
                  {isMfaActive ? 'ON' : 'OFF'}
                </Text>
              </HStack>
              {primaryMethod && (
                <HStack spacing={1}>
                  <Text>Primary device:</Text>
                  <Text
                    as="span"
                    fontWeight="bold"
                    color="primary.600"
                    textTransform="uppercase"
                  >
                    {primaryMethod?.name.includes('sms')
                      ? 'sms'
                      : 'email'}
                  </Text>
                </HStack>
              )}
              <Stack spacing={4}>
                <Button
                  w="fit-content"
                  variant="link"
                  colorScheme="secondary"
                  onClick={() =>
                    handleUpdateMfa(
                      isMfaActive
                        ? MFA_ACTIONS.DEACTIVATE
                        : MFA_ACTIONS.ACTIVATE,
                    )
                  }
                >
                  {isMfaActive ? 'Deactivate' : 'Activate'}{' '}
                  multi-factor-authentication
                </Button>
              </Stack>
            </Stack>
          </Stack>
          <ChangePasswordModal
            isOpen={isChangePasswordOpen}
            onClose={onChangePasswordClose}
          />
          <UpdateMfaModal
            isOpen={isChangeMfaOpen}
            onClose={onChangeMfaClose}
            action={mfaAction}
            activeMethod={primaryMethod}
          />
        </>
      )}
    </Box>
  );
};

export default Account;
