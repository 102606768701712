import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { AppProviders } from './context';
import 'focus-visible/dist/focus-visible';
import '@nosferatu500/react-sortable-tree/style.css';
import 'react-datepicker/dist/react-datepicker.css';

ReactDOM.render(
  <AppProviders>
    <App />
  </AppProviders>,
  document.getElementById('root'),
);

serviceWorker.unregister();
