import React from 'react';
import styled from 'styled-components/macro';

const FormLabel = ({ htmlFor, label }) => {
  return (
    <StyledFormLabel htmlFor={htmlFor}>{label}</StyledFormLabel>
  )
}

const StyledFormLabel = styled.label`
  color: ${({ theme }) => theme.colors.neutral.darkest};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
`;

export default FormLabel;