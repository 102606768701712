import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import ProtocolSelect from './ProtocolSelect';
import { studies } from '../api/api-wrapper';

const EditStudySiteModal = ({
  studySite,
  isOpen,
  onClose,
  mutate,
}) => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      value: studySite?.current_protocol?.id,
      label: studySite?.current_protocol?.version,
    },
  });

  const onSubmit = async ({ protocol }) => {
    try {
      const res = await studies.editStudySiteProtocolVersion(
        studySite.uuid,
        { current_protocol: protocol.value },
      );

      if (res.status !== 200) {
        console.log({ res });
      } else {
        mutate(); // can mutate with the local data here
        onClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Study</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <form id="join-form" onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={4}>
              <ProtocolSelect
                studyId={studySite?.study_uuid}
                control={control}
                defaultProtocol={{
                  value: studySite?.current_protocol?.id,
                  label: studySite?.current_protocol?.version,
                }}
              />
            </Stack>
          </form>
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose} mr={4}>
            Cancel
          </Button>
          <Button
            colorScheme="secondary"
            isLoading={isSubmitting}
            type="submit"
            form="join-form"
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditStudySiteModal;
