import { client } from '../../api/graphql-client';
import { useAuth } from '../../context/authContext';
import { EA_ANSWER_TYPES, EA_OPERATOR_TYPES } from '../../data/types';
import { SQ_PARENT_RELATION_TYPE } from '../../api/types';
import { getTreeFromFlatData } from '@nosferatu500/react-sortable-tree';
import { GetScreeningQuestions } from '../../api/graphql/screening-questions';
import useSWR from 'swr';

const fetcher = (query, variables) =>
  client.request(query, variables);

export const useGetQuestions = (armId) => {
  const { isAuthenticated, token } = useAuth();
  const { data, error, mutate, isValidating } = useSWR(
    isAuthenticated && token && armId
      ? [GetScreeningQuestions, { armUuid: armId }]
      : null,
    fetcher,
    {
      revalidateOnFocus: false,
    },
  );

  const flatData =
    data?.screeningQuestions?.map((item) => ({
      ...createNodeData(item),
    })) || [];

  //TODO: OR siblings don't translate correctly into flat data using the library, need to write a custom function
  const treeData = getTreeFromFlatData({
    flatData,
    getKey: ({ sqId }) => sqId,
    getParentKey: ({ parentSqId }) => parentSqId ?? 'root',
    rootKey: 'root',
  });

  const treeList = treeData.map((tree) => {
    return {
      treeId: tree.sqId,
      treeData: [tree],
    };
  });

  return {
    questions: treeList,
    isLoading: isValidating,
    isError: error,
    mutate,
  };
};

const getExpectedAnswerValues = (expectedAnswer) => {
  const answerOperator = EA_OPERATOR_TYPES.find(
    ({ value }) => value === expectedAnswer.operator,
  );
  const answerType = EA_ANSWER_TYPES.find(
    ({ value }) => value === expectedAnswer.answerType,
  );

  return {
    answerOperator,
    answerType,
    [answerType.valueKey]: expectedAnswer.value,
  };
};

const getParentInfo = (andSqs, orSqs) => {
  if (andSqs.length > 0) {
    return {
      parentRelation: SQ_PARENT_RELATION_TYPE.AND,
      parentSqId: andSqs[0].id,
    };
  } else if (orSqs.length > 0) {
    return {
      parentRelation: SQ_PARENT_RELATION_TYPE.OR,
      parentSqId: orSqs[0].id,
    };
  }

  return {};
};

const createNodeData = (data) => {
  return {
    sqId: data.id,
    questionId: data.question.id,
    title: data.question.displayText,
    questionHelperText: data.question.helpText,
    ...getExpectedAnswerValues(data.expectedAnswer),
    ...getParentInfo(data.andSqs, data.orSqs),
    expanded: true,
  };
};
