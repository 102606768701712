import React from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from '@chakra-ui/react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import ArmGroupInput from './ArmGroupInput';
import { Plus } from 'phosphor-react';
import { studies } from '../api/api-wrapper';

const initialValues = {
  version: '',
  arms: [
    {
      name: '',
      description: '',
      minAge: '',
      maxAge: '',
      type: { value: 0, label: 'In-Clinic' },
    },
  ],
};

const AddProtocolModal = ({ studyId, isOpen, onClose, mutate }) => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: initialValues,
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'arms',
  });

  const addArm = () => {
    append({
      name: '',
      description: '',
      minAge: '',
      maxAge: '',
      type: { value: 0, label: 'In-Clinic' },
    });
  };

  const removeArm = (index) => () => {
    remove(index);
  };

  const onSubmit = async (data) => {
    const armResponses = await Promise.all(
      data.arms.map((arm) =>
        studies.createResearcherArm({
          ...arm,
          type: arm.type.value,
        }),
      ),
    );

    const armIds = armResponses.map((res) => res.data.uuid);

    const req = {
      study: studyId,
      version: data.version,
      arms: armIds,
    };

    try {
      const res = await studies.createResearcherStudyProtocol(req);

      if (res.status !== 201) {
        console.log(res);
      } else {
        mutate();
        onClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Protocol and Arms</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <form
              id="add-protocol-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Stack spacing={4} mb={4}>
                <FormControl isRequired>
                  <FormLabel>Version Name</FormLabel>
                  <Controller
                    as={Input}
                    control={control}
                    name="version"
                    focusBorderColor="primary.400"
                    borderColor="primary.300"
                    shadow="sm"
                    _hover={{
                      borderColor: 'primary.400',
                    }}
                  />
                </FormControl>
                {fields.map((item, index) => {
                  return (
                    <ArmGroupInput
                      item={item}
                      control={control}
                      // error={
                      //   errors.condition?.length && errors.condition[index]
                      // }
                      key={item.id}
                      index={index}
                      removable={fields.length > 1}
                      remove={removeArm(index)}
                    />
                  );
                })}
              </Stack>
              <Button
                colorScheme="secondary"
                leftIcon={<Box as={Plus} fontSize="2xl" />}
                onClick={addArm}
              >
                Add Arm
              </Button>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose} mr={4}>
              Cancel
            </Button>
            <Button
              colorScheme="secondary"
              isLoading={isSubmitting}
              loadingText="Saving..."
              type="submit"
              form="add-protocol-form"
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddProtocolModal;
