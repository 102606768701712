import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import FormLabel from '../components/FormLabel';
import { useAuth } from '../context/authContext';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  chakra,
  CloseButton,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  Link,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import Logo from '../components/Logo';
import { Link as RouterLink } from 'react-router-dom';

const initialErrors = [];
const initialFormValues = {
  email: '',
};

const PasswordReset = () => {
  const { resetPassword } = useAuth();
  const {
    handleSubmit,
    errors,
    control,
    formState: { isSubmitting, isSubmitted },
  } = useForm({
    defaultValues: initialFormValues,
  });
  const [serverError, setServerError] = useState(initialErrors);

  const resetErrors = () => {
    setServerError(initialErrors);
  };

  const onSubmit = async (data) => {
    try {
      await resetPassword({ ...data });
    } catch (error) {
      console.log('activate account error', error.message);
      setServerError([error.message]);
    }
  };

  return (
    <Box
      minH="100vh"
      py={{ base: '4', md: '12' }}
      px={{ base: '4', lg: '8' }}
    >
      <Box maxW="md" mx="auto">
        <Logo
          mx="auto"
          h={{ base: '16', md: '32' }}
          mb={{ base: '4', md: '10' }}
        />
        {serverError.length > 0 && (
          <Alert status="error" mb="8">
            <Flex flexDirection="column">
              <Flex flexDirection="row">
                <AlertIcon />
                <AlertTitle>Sorry, there was an error</AlertTitle>
              </Flex>
              <UnorderedList px="8">
                {serverError.map((error, index) => (
                  <ListItem key={index}>{error}</ListItem>
                ))}
              </UnorderedList>
            </Flex>
            <CloseButton
              position="absolute"
              right="8px"
              top="8px"
              onClick={resetErrors}
            />
          </Alert>
        )}
        {isSubmitted ? (
          <>
            <Heading
              textAlign="center"
              size="md"
              fontWeight="bold"
              mb="8"
            >
              Thanks, check your email for instructions to reset your
              password
            </Heading>
            <Text maxW="md" fontWeight="medium" textAlign="center">
              Didn't get the email? Try checking your spam folder
            </Text>
          </>
        ) : (
          <>
            <Heading
              textAlign="center"
              size="lg"
              fontWeight="bold"
              mb={4}
            >
              Reset your password
            </Heading>
            <Text mb={4}>
              Enter the email address associated with your account and
              we'll send you a link to reset your password.
            </Text>
            <chakra.form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing={4} mb={8}>
                <FormControl id="email" isInvalid={errors.email}>
                  <FormLabel>Email</FormLabel>
                  <Controller
                    as={Input}
                    control={control}
                    rules={{ required: 'Email is required' }}
                    name="email"
                    type="email"
                    autoComplete="email"
                    focusBorderColor="primary.400"
                    borderColor="primary.300"
                    shadow="sm"
                    _hover={{
                      borderColor: 'primary.400',
                    }}
                  />
                  <FormErrorMessage>
                    {errors.email?.message}
                  </FormErrorMessage>
                </FormControl>
                <Button
                  w="full"
                  type="submit"
                  colorScheme="primary"
                  size="lg"
                  isLoading={isSubmitting}
                  loadingText="Submitting..."
                >
                  Submit
                </Button>
              </Stack>
            </chakra.form>
            <Text width="full" align="center" fontWeight="medium">
              <Link as={RouterLink} color="secondary.500" to="/login">
                Return to sign in
              </Link>
            </Text>
          </>
        )}
      </Box>
    </Box>
  );
};

export default PasswordReset;
