import React from 'react';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  useDisclosure,
  useMergeRefs,
} from '@chakra-ui/react';
import { Eye, EyeClosed } from 'phosphor-react';
import { Link as RouterLink } from 'react-router-dom';

//TODO: password restrictions,
//ENHANCEMENT: password strength indicator
const PasswordInput = React.forwardRef(
  (
    {
      name,
      control,
      errors,
      forgotPassword = true,
      label = 'Password',
      ...rest
    },
    ref,
  ) => {
    const { isOpen: showPassword, onToggle } = useDisclosure();
    const inputRef = React.useRef(null);
    const mergeRef = useMergeRefs(inputRef, ref);

    const onClickReveal = () => {
      onToggle();
      const input = inputRef.current;

      if (input) {
        input.focus({
          preventScroll: true,
        });
        const length = input.value.length * 2;
        requestAnimationFrame(() => {
          input.setSelectionRange(length, length);
        });
      }
    };

    return (
      <FormControl id={name} isInvalid={errors}>
        <Flex justify="space-between" align="center">
          <FormLabel>{label}</FormLabel>
          {forgotPassword && (
            <Link
              as={RouterLink}
              color="secondary.500"
              fontWeight="semibold"
              fontSize="sm"
              to="/reset-password"
            >
              Forgot Password?
            </Link>
          )}
        </Flex>
        <InputGroup>
          <Input
            ref={mergeRef}
            name={name}
            type={showPassword ? 'text' : 'password'}
            autoComplete="current-password"
            focusBorderColor="primary.400"
            borderColor="primary.300"
            shadow="sm"
            _hover={{
              borderColor: 'primary.400',
            }}
            {...rest}
          />
          <InputRightElement>
            <IconButton
              bg="transparent !important"
              variant="ghost"
              aria-label={
                showPassword ? 'Mask password' : 'Reveal password'
              }
              icon={
                showPassword ? (
                  <Eye size={24} />
                ) : (
                  <EyeClosed size={24} />
                )
              }
              onClick={onClickReveal}
            />
          </InputRightElement>
        </InputGroup>
        <FormErrorMessage>{errors?.message}</FormErrorMessage>
      </FormControl>
    );
  },
);
PasswordInput.displayName = 'PasswordInput';

export default PasswordInput;
