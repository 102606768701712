import React from 'react';
import { Select } from 'chakra-react-select';

const HWSelect = ({ name, options, ...props }) => {
  return (
    <Select
      {...props}
      name={name}
      options={options}
      className="hw-select"
      classNamePrefix="hw-select"
      chakraStyles={{
        dropdownIndicator: (provided) => ({
          ...provided,
          bg: 'transparent',
          px: 2,
          cursor: 'inherit',
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          display: 'none',
        }),
        container: (provided) => ({
          ...provided,
          borderColor: 'primary.300',
          shadow: 'sm',
          '.hw-select__control:hover': {
            borderColor: 'primary.400',
          },
        }),
      }}
      focusBorderColor="primary.400"
      selectedOptionColor="primary"
    />
  );
};

export default HWSelect;
