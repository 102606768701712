import React, { useState } from 'react';
import {
  Box,
  Heading,
  Stack,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { useTable } from 'react-table';
import LoadingScreen from '../components/LoadingScreen';
import { useMatches } from '../hooks/api/useMatches';
import { SCREENING_PR_STATUS } from '../data/types';

const MatchTableContent = ({ matches, columns }) => {
  const data = React.useMemo(() => matches, [matches]);
  const { onOpen, onClose } = useDisclosure();
  const [editMatch, setEditMatch] = useState(null);

  const { getTableProps, getTableBodyProps, rows, prepareRow } =
    useTable({ columns, data });

  const handleEditRow = (data) => {
    setEditMatch(data);
    onOpen();
  };

  const handleEditClose = () => {
    setEditMatch(null);
    onClose();
  };

  return (
    <>
      <Table borderWidth="1px" fontSize="sm" {...getTableProps()}>
        <Thead bg="gray.50">
          <Tr>
            {columns.map((column, index) => (
              <Th whiteSpace="nowrap" scope="col" key={index}>
                {column.Header}
              </Th>
            ))}
            <Th />
          </Tr>
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {(rows.length > 0 &&
            rows.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <Td {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </Td>
                    );
                  })}
                  {/*<Td>*/}
                  {/*  <Button*/}
                  {/*    colorScheme="secondary"*/}
                  {/*    onClick={() => handleEditRow(row.original)}*/}
                  {/*  >*/}
                  {/*    Update*/}
                  {/*  </Button>*/}
                  {/*</Td>*/}
                </Tr>
              );
            })) || (
            <Tr>
              <Td colSpan={6} textAlign="center">
                You don't have any matches right now
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      {/*{editMatch && (*/}
      {/*  <EditMatchModal*/}
      {/*    isOpen={isOpen}*/}
      {/*    onClose={handleEditClose}*/}
      {/*    profile={editMatch}*/}
      {/*  />*/}
      {/*)}*/}
    </>
  );
};

const Matches = () => {
  const { matches, isLoading } = useMatches();

  const columns = React.useMemo(
    () => [
      {
        Header: 'Contact Name',
        accessor: 'contactInfo[0].fullName',
      },
      {
        Header: 'Phone Number',
        accessor: 'contactInfo[0].phoneNumber',
      },
      {
        Header: 'Email',
        accessor: 'contactInfo[0].email',
      },
      {
        Header: 'Study Site',
        accessor: 'studySite.site.name',
      },
      {
        Header: 'Last Updated',
        accessor: 'updatedAt',
        Cell: ({ cell: { value } }) => {
          return new Date(value).toDateString();
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ cell: { value } }) => {
          return SCREENING_PR_STATUS.find(
            (status) => status.value === value,
          )?.label;
        },
      },
    ],
    [],
  );

  return (
    <Box w={'full'}>
      {isLoading ? (
        <LoadingScreen loadingText={'Loading...'} show={true} />
      ) : (
        <>
          <Stack spacing={4}>
            <Heading fontWeight="semibold" size="lg">
              You have {matches?.length ?? 0} matches
            </Heading>
            <MatchTableContent columns={columns} matches={matches} />
          </Stack>
        </>
      )}
    </Box>
  );
};

export default Matches;
