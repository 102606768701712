import React, { useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  IconButton,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import LoadingScreen from '../components/LoadingScreen';
import { useProfiles } from '../hooks/api/useProfiles';
import { Pencil, Plus } from 'phosphor-react';
import { useTable } from 'react-table';
import CreateProfileModal from '../components/CreateProfileModal';
import EditProfileModal from '../components/EditProfileModal';
import { gender } from '../data/types';
import { useHistory } from 'react-router-dom';

const Profiles = () => {
  const { profiles, isLoading: isProfilesLoading } = useProfiles();

  const columns = React.useMemo(
    () => [
      {
        Header: 'Label',
        accessor: 'label',
      },
      {
        Header: 'Age',
        accessor: 'age',
      },
      {
        Header: 'Sex At Birth',
        accessor: 'sexAtBirth',
        Cell: ({ cell: { value } }) => {
          return gender.find((gender) => gender.value === value)
            ?.label;
        },
      },
      {
        Header: 'Conditions',
        accessor: 'conditions',
        Cell: ({ cell: { value } }) => {
          return value.join(', ');
        },
      },
      {
        Header: 'State',
        accessor: 'state',
      },
    ],
    [],
  );

  return (
    <Box
      maxW={{ base: 'xl', md: '5xl' }}
      mx="auto"
      px={{
        base: 6,
        md: '12',
      }}
      py={{
        base: 3,
        md: 12,
      }}
    >
      {isProfilesLoading ? (
        <LoadingScreen loadingText={'Loading...'} show={true} />
      ) : (
        <>
          <Stack spacing={6}>
            <Heading fontWeight="semibold">Profiles</Heading>
            <Divider />
            <ProfileTableActions />
            <ProfileTableContent
              columns={columns}
              profiles={profiles}
            />
          </Stack>
        </>
      )}
    </Box>
  );
};

const ProfileTableActions = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex justify={'flex-end'}>
      <Button
        size="sm"
        variant="outline"
        colorScheme="secondary"
        iconSpacing="1"
        leftIcon={<Plus fontSize="1.25em" />}
        onClick={onOpen}
      >
        New Profile
      </Button>
      <CreateProfileModal isOpen={isOpen} onClose={onClose} />
    </Flex>
  );
};

const ProfileTableContent = ({ profiles, columns }) => {
  const { push } = useHistory();
  const data = React.useMemo(() => profiles, [profiles]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editProfile, setEditProfile] = useState(null);
  const [activeProfileId, setActiveProfileId] = useState(
    JSON.parse(localStorage.getItem('active-profile-id')),
  );

  const { getTableProps, getTableBodyProps, rows, prepareRow } =
    useTable({ columns, data });

  const handleEditRow = (data) => {
    setEditProfile(data);
    onOpen();
  };

  const handleEditClose = () => {
    setEditProfile(null);
    onClose();
  };

  const handleSetActiveProfileId = (id) => {
    localStorage.setItem('active-profile-id', JSON.stringify(id));
    setActiveProfileId(id);
    push('/');
  };

  return (
    <>
      <Table
        my="8"
        borderWidth="1px"
        fontSize="sm"
        {...getTableProps()}
      >
        <Thead bg="gray.50">
          <Tr>
            {columns.map((column, index) => (
              <Th whiteSpace="nowrap" scope="col" key={index}>
                {column.Header}
              </Th>
            ))}
            <Th>Activate</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {(rows.length > 0 &&
            rows.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <Td {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </Td>
                    );
                  })}
                  <Td>
                    {row.original.id === activeProfileId ? (
                      <Text fontWeight="semibold">Active</Text>
                    ) : (
                      <Button
                        colorScheme="secondary"
                        variant="link"
                        onClick={() =>
                          handleSetActiveProfileId(row.original.id)
                        }
                      >
                        Search Studies
                      </Button>
                    )}
                  </Td>
                  <Td>
                    <Tooltip label="Edit">
                      <IconButton
                        ml={{ base: 0, md: 'auto' }}
                        color="primary.300"
                        variant="ghost"
                        aria-label="Edit"
                        onClick={() => handleEditRow(row.original)}
                        icon={<Pencil size={32} />}
                        _hover={{
                          color: 'primary.500',
                        }}
                      />
                    </Tooltip>
                  </Td>
                </Tr>
              );
            })) || (
            <Tr>
              <Td colSpan={6} textAlign="center">
                Add a profile to get started
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      {editProfile && (
        <EditProfileModal
          isOpen={isOpen}
          onClose={handleEditClose}
          profile={editProfile}
        />
      )}
    </>
  );
};

export default Profiles;
