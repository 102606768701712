import { useAuth } from '../../context/authContext';
import useSWR from 'swr';
import { client } from '../../api/graphql-client';
import { GetUserProfileMatches } from '../../api/graphql/studies';

const fetcherGql = (query, variables) =>
  client.request(query, variables);

export const useStudySeekerStudy = (studyId) => {
  const profileId = JSON.parse(
    localStorage.getItem('active-profile-id'),
  );
  const { isAuthenticated, token } = useAuth();
  const { data, error, isValidating, mutate } = useSWR(
    isAuthenticated && token && studyId
      ? [
          GetUserProfileMatches,
          { profileUuid: profileId, studyUuids: [studyId] },
        ]
      : null,
    fetcherGql,
    {
      revalidateOnFocus: false,
    },
  );

  const match = data?.profileMatches?.matches[0];

  return {
    match,
    isLoading: !match && isValidating,
    isError: error,
    mutate,
  };
};
