import React, { useState } from 'react';
import LoadingScreen from '../components/LoadingScreen';

export const useLoader = () => {
  const [isVisible, setIsVisible] = useState(false);

  const RenderLoader = ({ loadingText = 'Loading...' }) => {
    return (
      <LoadingScreen show={isVisible} loadingText={loadingText} />
    );
  };

  return {
    show: () => setIsVisible(true),
    hide: () => setIsVisible(false),
    RenderLoader,
  };
};
