import React, { useRef, useState } from 'react';
import {
  Box,
  ButtonGroup,
  Divider,
  Flex,
  Icon,
  IconButton,
  Link,
  Text,
  Textarea,
  Tooltip,
} from '@chakra-ui/react';
import {
  CaretRight,
  FloppyDisk,
  Pencil,
  XSquare,
} from 'phosphor-react';
import ReadMoreText from './ReadMoreText';
import { CreatePr } from '../api/graphql/matches';
import { client } from '../api/graphql-client';
import { Link as RouterLink } from 'react-router-dom';
import {
  CreateStudyNote,
  UpdateStudyNote,
} from '../api/graphql/study-notes';
// import MatchStatusBadge from './MatchStatusBadge';

const Card = ({ match }) => {
  const notesRef = useRef();
  const [notes, setNotes] = useState(match?.notes[0]?.text || '');
  const [isEditNotes, setEditNotes] = useState(false);

  if (!match) {
    return;
  }

  const handleNotesChange = (e) => {
    setNotes(e.target.value);
  };

  const handleSaveNotes = async () => {
    setEditNotes(false);
    try {
      if (match.notes[0]?.id) {
        await client.request(UpdateStudyNote, {
          id: match.notes[0]?.id,
          text: notes,
        });
      } else {
        const profileId = await JSON.parse(
          localStorage.getItem('active-profile-id'),
        );

        await client.request(CreateStudyNote, {
          profileUuid: profileId,
          studyUuid: match.study.healthwalletId,
          text: notes,
        });
      }
    } catch (error) {
      console.log({ error });
      //TODO: on error, need to retry saving note?
    }
  };

  const handleEditNotesClick = () => {
    setEditNotes(true);
    const notesInput = notesRef.current;
    notesInput.focus({
      preventScroll: true,
    });

    const length = notesInput.value?.length * 2 ?? 0;
    requestAnimationFrame(() => {
      notesInput.setSelectionRange(length, length);
    });
  };

  const handleCancelNotes = () => {
    //TODO: possible situation where they save note, then edit again and cancel? mutate should preserve the saved note...probably won't do this fast enough anyway
    setNotes(match?.notes[0] ?? '');
    setEditNotes(false);
  };

  const handleCreatePr = async (armUuid) => {
    const profileUuid = JSON.parse(
      localStorage.getItem('active-profile-id'),
    );

    const req = await client.request(CreatePr, {
      input: {
        armUuid,
        profileUuid,
      },
    });

    console.log({ req });
  };

  return (
    <Box
      rounded="lg"
      bg="white"
      maxW="5xl"
      mx="auto"
      boxShadow="0 0 0 1px #e4e7f0"
      overflow="hidden"
      _notLast={{
        marginBottom: '20px',
      }}
    >
      <Link
        as={RouterLink}
        to={`/study-seeker/study/${match.study.healthwalletId}`}
        _hover={{
          color: 'secondary.400',
        }}
      >
        <Flex
          alignItems={{ base: 'flex-start', md: 'center' }}
          px={{ base: 3, md: 6 }}
          py={{ base: 3, md: 4 }}
          cursor="pointer"
        >
          <Box width={{ base: 'full', md: 'calc(100% - 200px)' }}>
            <Text
              as="h3"
              fontWeight="bold"
              fontSize="lg"
              noOfLines={2}
            >
              {match.study.formalName}
            </Text>
          </Box>
          <Icon as={CaretRight} ml="auto" weight="bold" boxSize={5} />
        </Flex>
      </Link>
      <Divider />
      {match.study.description && (
        <ReadMoreText
          id={match.study.nctId}
          text={match.study.description}
          numLines={3}
          px={{ base: 3, md: 6 }}
          py={{ base: 3, md: 4 }}
        />
      )}
      <Divider />
      <Flex
        px={{ base: 3, md: 6 }}
        py={{ base: 3, md: 4 }}
        direction={{ base: 'column', md: 'row' }}
      >
        <ButtonGroup
          w={24}
          mr={4}
          variant="none"
          isAttached
          display="flex"
          alignItems={isEditNotes ? 'flex-end' : 'flex-start'}
        >
          {isEditNotes ? (
            <>
              <Tooltip label="Save" placement="top">
                <IconButton
                  color="secondary.300"
                  aria-label="Save"
                  onClick={handleSaveNotes}
                  icon={<FloppyDisk size={32} />}
                  _hover={{
                    color: 'secondary.500',
                  }}
                />
              </Tooltip>
              <Tooltip label="Cancel" placement="top">
                <IconButton
                  color="red.300"
                  aria-label="Cancel"
                  onClick={handleCancelNotes}
                  icon={<XSquare size={32} />}
                  _hover={{
                    color: 'red.500',
                  }}
                />
              </Tooltip>
            </>
          ) : (
            <Tooltip label="Edit Notes">
              <IconButton
                ml={{ base: 0, md: 'auto' }}
                color="primary.300"
                aria-label="Edit Notes"
                onClick={handleEditNotesClick}
                icon={<Pencil size={32} />}
                _hover={{
                  color: 'primary.500',
                }}
              />
            </Tooltip>
          )}
        </ButtonGroup>
        <Textarea
          ref={notesRef}
          value={notes}
          onChange={handleNotesChange}
          placeholder="Add notes about this study"
          resize="none"
          isReadOnly={!isEditNotes}
          borderColor="primary.100"
          focusBorderColor="primary.500"
          color={isEditNotes ? 'inherit' : 'gray.400'}
          _hover={{
            borderColor: 'primary.200',
          }}
        />
      </Flex>
    </Box>
  );
};

export default Card;
