import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  HStack,
  Input,
  Link,
  Text,
} from '@chakra-ui/react';
import { useSearchStudies } from '../hooks/api/useSearchStudies';

//TODO: doesn't keep state on browser back?
const SearchStudies = () => {
  const [search, setSearch] = useState('');
  const [isNct, setIsNct] = useState(null);
  const [shouldSearch, setShouldSearch] = useState(false);

  const { searchStudies, isValidating } = useSearchStudies(
    search,
    isNct,
    shouldSearch,
  );

  const handleSearch = () => {
    setShouldSearch(false);
    //TODO: is this too naive? make more explicit with a checkbox?
    const hasNct = search.toUpperCase().includes('NCT');
    setIsNct(hasNct);
    setShouldSearch(search.length > 3);
  };

  const handleSearchChange = (e) => {
    setShouldSearch(false);
    setSearch(e.target.value.toUpperCase());
  };

  //TODO: logic is slightly off
  const renderResults = () => {
    if (shouldSearch && !isValidating && searchStudies.length === 0) {
      return (
        <Text fontSize="lg" textAlign="center">
          Didn't find the right study? Try searching again or{' '}
          <Link
            as={RouterLink}
            color="secondary.500"
            fontWeight="semibold"
            fontSize="lg"
            to={`/researcher/study/create/${search}/${isNct}`}
          >
            Create A Study
          </Link>
        </Text>
      );
    }

    if (searchStudies.length > 0) {
      return (
        <>
          <Box
            rounded="lg"
            bg="white"
            boxShadow="0 0 0 2px #8CABAA"
            overflow="hidden"
          >
            <Flex
              alignItems={{ base: 'space-between' }}
              px={{ base: 3, md: 6 }}
              py={{ base: 3, md: 4 }}
            >
              <Box width={{ base: 'full', md: 'calc(100% - 160px)' }}>
                <Text
                  as="h3"
                  fontWeight="bold"
                  fontSize="lg"
                  noOfLines={2}
                >
                  {searchStudies?.[0]?.nickname ??
                    searchStudies?.[0]?.formal_name}
                </Text>
              </Box>
              <Link
                as={RouterLink}
                color="secondary.500"
                fontWeight="semibold"
                to={`/researcher/study/${searchStudies?.[0]?.healthwallet_id}`}
              >
                Go to Study
              </Link>
            </Flex>
          </Box>
          <Text fontSize="lg" textAlign="center" mt={12}>
            Didn't find the right study? Try searching again or{' '}
            <Link
              as={RouterLink}
              color="secondary.500"
              fontWeight="semibold"
              fontSize="lg"
              to={`/researcher/study/create/${search}/${isNct}`}
            >
              Create A Study
            </Link>
          </Text>
        </>
      );
    }
  };

  return (
    <Flex flexDirection="column" w="full">
      <HStack spacing={4}>
        <Input
          placeholder="e.g. NCT12345678"
          size="lg"
          value={search}
          onChange={handleSearchChange}
          focusBorderColor="primary.400"
          borderColor="primary.300"
          shadow="sm"
          _hover={{
            borderColor: 'primary.400',
          }}
        />
        <Box>
          <Button
            colorScheme="secondary"
            size="lg"
            onClick={handleSearch}
            isLoading={isValidating}
            loadingText="Searching..."
          >
            Search
          </Button>
        </Box>
      </HStack>
      <Box mt={8}>{renderResults()}</Box>
    </Flex>
  );
};

export default SearchStudies;
