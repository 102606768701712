import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../context/authContext';
import Logo from '../components/Logo';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  CloseButton,
  Flex,
  Heading,
  ListItem,
  Stack,
  UnorderedList,
} from '@chakra-ui/react';
import ButtonRadioGroup from '../components/ButtonRadioGroup';
import { ChatDots, Envelope } from 'phosphor-react';

const initialErrors = [];
const initialMfaSelectValues = {
  mfaType: 'email',
};

const MfaSelect = () => {
  const { push } = useHistory();
  const { enableMfa } = useAuth();
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: initialMfaSelectValues,
  });
  const [serverError, setServerError] = useState(initialErrors);
  const [redirect, setRedirect] = useState('');

  useEffect(() => {
    if (redirect) {
      push(redirect);
    }
  }, [redirect, push]);

  const resetErrors = () => {
    setServerError(initialErrors);
  };

  const onSubmit = async (data) => {
    try {
      const res = await enableMfa(data.mfaType);

      if (res.error) {
        setServerError(res.error);
      } else {
        setRedirect('/mfa-code');
      }
    } catch (error) {
      console.log(error);
      setServerError([error.message]);
    }
  };

  return (
    <Box
      minH="100vh"
      py={{ base: '4', md: '12' }}
      px={{ base: '4', lg: '8' }}
    >
      <Box maxW="md" mx="auto">
        <Logo
          mx="auto"
          h={{ base: '16', md: '32' }}
          mb={{ base: '4', md: '10' }}
        />
        {serverError.length > 0 && (
          <Alert status="error" mb="8">
            <Flex flexDirection="column">
              <Flex flexDirection="row">
                <AlertIcon />
                <AlertTitle>Sorry, there was an error</AlertTitle>
              </Flex>
              <UnorderedList px="8">
                {serverError.map((error, index) => (
                  <ListItem key={index}>{error}</ListItem>
                ))}
              </UnorderedList>
            </Flex>
            <CloseButton
              position="absolute"
              right="8px"
              top="8px"
              onClick={resetErrors}
            />
          </Alert>
        )}
        <Heading size="md" fontWeight="bold" mb={4}>
          Set the primary device where you will receive authentication
          codes
        </Heading>
        <form id="change-mfa-form" onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={4}>
            <Controller
              control={control}
              name="mfaType"
              render={({ value, onChange }) => (
                <ButtonRadioGroup
                  name="mfaType"
                  value={value}
                  onChange={onChange}
                  options={[
                    {
                      label: 'Email',
                      icon: <Envelope />,
                      value: 'email',
                    },
                    {
                      label: 'SMS Text',
                      icon: <ChatDots />,
                      value: 'sms_phonenumber',
                    },
                  ]}
                />
              )}
            />
            <Button
              w="full"
              type="submit"
              colorScheme="secondary"
              isLoading={isSubmitting}
              disabled={isSubmitting}
              loadingText="Sending..."
            >
              Submit
            </Button>
          </Stack>
        </form>
      </Box>
    </Box>
  );
};

export default MfaSelect;
