import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  Heading,
  IconButton,
  Link,
  Stack,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { useResearcherStudy } from '../hooks/api/useResearcherStudy';
import { useLoader } from '../hooks/useLoader';
import { useGetStudySitesByStudyId } from '../hooks/api/useGetStudySitesByStudyId';
import { Pencil } from 'phosphor-react';
import { useTable } from 'react-table';
import EditStudyModal from '../components/EditStudyModal';
import JoinStudySiteModal from '../components/JoinStudySiteModal';
import { useGetProtocolsByStudyId } from '../hooks/api/useGetProtocols';
import AddStudySiteModal from '../components/AddStudySiteModal';
import AddProtocolModal from '../components/AddProtocolModal';

const StudyDetail = () => {
  const { studyId } = useParams();
  const { study, isLoading, isError, mutate } =
    useResearcherStudy(studyId);
  const { show, hide, RenderLoader } = useLoader();

  useEffect(() => {
    if (isError || isLoading) {
      show();
    } else {
      hide();
    }
  }, [isLoading, isError, show, hide]);

  return (
    <>
      <Flex flexDirection="column" w="full">
        <Breadcrumb
          spacing="8px"
          mb={4}
          fontWeight="medium"
          fontSize="sm"
          color="secondary.400"
        >
          <BreadcrumbItem>
            <BreadcrumbLink as={RouterLink} to="/researcher">
              Dashboard
            </BreadcrumbLink>
          </BreadcrumbItem>

          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink
              as={RouterLink}
              to={`/researcher/study/${studyId}`}
            >
              Study
            </BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
        <Overview study={study} mb={8} mutate={mutate} />
        <Tabs w="full" isLazy={true} lazyBehavior="keepMounted">
          <TabList>
            <Tab>Study Sites</Tab>
            <Tab>Protocols</Tab>
          </TabList>
          <TabPanels w="full">
            <TabPanel px={0}>
              <SitesPanel studyId={studyId} />
            </TabPanel>
            <TabPanel px={0}>
              <ProtocolPanel studyId={studyId} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
      <RenderLoader loadingText="Getting study information..." />
    </>
  );
};

const Overview = ({ study, mutate, ...rest }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const displayName = study?.nickname
    ? study?.nickname
    : study?.formal_name
    ? study?.formal_name
    : study?.nct_id ?? 'Untitled Study';

  return (
    <>
      <Stack direction="column" spacing={4} {...rest}>
        <Flex alignItems="flex-start">
          <Box>
            <Heading as="h3">{displayName}</Heading>
          </Box>
          <ButtonGroup ml={{ base: 0, md: 'auto' }} variant="none">
            <Tooltip label="Edit">
              <IconButton
                color="primary.300"
                aria-label="More Info"
                onClick={onOpen}
                icon={<Pencil size={32} />}
                _hover={{
                  color: 'primary.500',
                }}
              />
            </Tooltip>
          </ButtonGroup>
        </Flex>
        {study?.nickname && (
          <>
            <TextSet
              title={'Formal Study Name'}
              text={study?.formal_name}
            />
          </>
        )}
        {(study?.nickname || study?.formal_name) && (
          <TextSet
            title={'ID'}
            text={study?.nct_id ?? 'Untitled Study'}
          />
        )}
        <TextSet title={'Description'} text={study?.description} />
        {study?.layman_description && (
          <TextSet
            title={'Layman Description'}
            text={study?.layman_description}
          />
        )}
      </Stack>
      <EditStudyModal
        study={study}
        isOpen={isOpen}
        onClose={onClose}
        mutate={mutate}
      />
    </>
  );
};

const TextSet = ({ title, text }) => {
  return (
    <>
      <Divider />
      <Stack spacing={1}>
        <Text fontSize="xs" fontWeight="bold" color="primary.400">
          {title.toUpperCase()}
        </Text>
        {text ? <Text>{text}</Text> : null}
      </Stack>
    </>
  );
};

const SitesPanel = ({ studyId }) => {
  const {
    isOpen: isOpenJoin,
    onOpen: onOpenJoin,
    onClose: onCloseJoin,
  } = useDisclosure();
  const {
    isOpen: isOpenAdd,
    onOpen: onOpenAdd,
    onClose: onCloseAdd,
  } = useDisclosure();
  const { studySites, mutate } = useGetStudySitesByStudyId(studyId);
  const [joinStudySiteData, setJoinStudySiteData] = useState(null);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Site',
        accessor: 'site',
        Cell: ({ value, row }) => {
          return (
            <Link
              as={RouterLink}
              color="secondary.500"
              to={`/researcher/study/site/${row.original.uuid}`}
            >
              {value.properties.name}
            </Link>
          );
        },
      },
      {
        Header: 'Researchers',
        accessor: 'researchers',
        Cell: ({ value }) => {
          //TODO: show the rest in drawer or whatever
          const numberToShow = 5;
          const namesToShow = value.slice(0, numberToShow);
          const remaining = value.length - numberToShow;
          return (
            <span>
              {namesToShow.join(', ') +
                (remaining > 0 ? ` and ${remaining} more...` : '')}
            </span>
          );
        },
      },
      {
        //TODO: only show this if they're not already joined - need BE
        Header: '',
        accessor: 'uuid',
        Cell: ({ value, row }) => {
          return (
            <Button
              onClick={() =>
                handleJoinStudySiteModalOpen(
                  value,
                  row.original.site.properties.name,
                )
              }
            >
              Join this study site
            </Button>
          );
        },
      },
    ],
    [],
  );

  const { getTableProps, getTableBodyProps, rows, prepareRow } =
    useTable({ columns, data: studySites });

  const handleJoinStudySiteModalOpen = (value, siteName) => {
    setJoinStudySiteData({ id: value, siteName });
    onOpenJoin();
  };

  const handleCloseJoin = () => {
    setJoinStudySiteData(null);
    onCloseJoin();
  };

  //TODO: loading state
  return (
    <>
      <Button onClick={onOpenAdd}>Add Study Site</Button>
      <Table
        borderWidth="1px"
        fontSize="sm"
        mt={4}
        {...getTableProps()}
      >
        <Thead bg="gray.50">
          <Tr>
            {columns.map((column, index) => (
              <Th whiteSpace="nowrap" scope="col" key={index}>
                {column.Header}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {(rows.length > 0 &&
            rows.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <Td {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })) || (
            <Tr>
              <Td colSpan={5} textAlign="center">
                No results
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      <JoinStudySiteModal
        isOpen={isOpenJoin}
        onClose={handleCloseJoin}
        studySiteToJoin={joinStudySiteData}
        mutate={mutate}
      />
      <AddStudySiteModal
        isOpen={isOpenAdd}
        onClose={onCloseAdd}
        mutate={mutate}
        studyId={studyId}
      />
    </>
  );
};

const ProtocolPanel = ({ studyId }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { protocols, mutate } = useGetProtocolsByStudyId(studyId);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Version',
        accessor: 'version',
        Cell: ({ value, row }) => {
          return (
            <Link
              as={RouterLink}
              color="secondary.500"
              to={`/researcher/study/protocol/${row.original.id}`}
            >
              {value}
            </Link>
          );
        },
      },
    ],
    [],
  );

  const { getTableProps, getTableBodyProps, rows, prepareRow } =
    useTable({ columns, data: protocols });

  return (
    <>
      <Button onClick={onOpen}>Add Protocol</Button>
      <Table
        borderWidth="1px"
        fontSize="sm"
        mt={4}
        {...getTableProps()}
      >
        <Thead bg="gray.50">
          <Tr>
            {columns.map((column, index) => (
              <Th whiteSpace="nowrap" scope="col" key={index}>
                {column.Header}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {(rows.length > 0 &&
            rows.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <Td {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </Td>
                    );
                  })}
                  {/*<Td>*/}
                  {/*TODO: create new protocol from this version action*/}
                  {/*</Td>*/}
                </Tr>
              );
            })) || (
            <Tr>
              <Td colSpan={5} textAlign="center">
                No results
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      <AddProtocolModal
        isOpen={isOpen}
        onClose={onClose}
        mutate={mutate}
        studyId={studyId}
      />
    </>
  );
};

export default StudyDetail;
