import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

const EmptyState = ({ icon, text }) => {
  return (
    <Box
      maxW={{ base: 'xl', md: '7xl' }}
      mx="auto"
      px={{
        base: 0,
        md: '12',
      }}
      py={{
        base: 3,
        md: 12,
      }}
    >
      <Flex direction="column" align="center" justify="center">
        <Box
          as={icon}
          fontSize="7xl"
          color="primary.600"
          bgColor="primary.300"
          p={2}
          rounded="50%"
          mb={4}
        />
        <Text fontSize="lg">{text}</Text>
      </Flex>
    </Box>
  );
};

export default EmptyState;
