import React from 'react';
import { Box, Flex, Heading, Link, Text } from '@chakra-ui/react';
import Logo from '../components/Logo';
import { Link as RouterLink } from 'react-router-dom';

const RegistrationSuccess = () => (
  <Box
    minH="100vh"
    py={{ base: '4', md: '12' }}
    px={{ base: '4', lg: '8' }}
  >
    <Box maxW="md" mx="auto">
      <Logo
        mx="auto"
        h={{ base: '16', md: '32' }}
        mb={{ base: '4', md: '10' }}
      />
      <Heading textAlign="center" size="lg" fontWeight="bold" mb="8">
        Registration successful!
      </Heading>
      <Text maxW="md" fontWeight="medium">
        An activation email that has just been sent to you in order to
        finalize your account creation.
      </Text>
      <Text mt="4" maxW="md" fontWeight="medium">
        Please allow up to 10 minutes for the activation email to
        arrive.
      </Text>
      <Text mt="4" maxW="md" fontWeight="medium">
        If you do not receive the confirmation message, please check
        your spam folder.
      </Text>
      <Flex justify="center">
        <Text mt="4" maxW="md" fontWeight="medium">
          <Link as={RouterLink} color="secondary.500" to="/login">
            Sign in
          </Link>
        </Text>
      </Flex>
    </Box>
  </Box>
);

export default RegistrationSuccess;
