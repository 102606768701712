import React, { useState } from 'react';
import { useAuth } from '../context/authContext';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Controller, useForm, useWatch } from 'react-hook-form';
import Logo from '../components/Logo';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  chakra,
  CloseButton,
  Flex,
  Heading,
  Link,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import PasswordInput from '../components/PasswordInput';

const initialErrors = [];
const initialFormValues = {
  password: '',
  confirmPassword: '',
};

const PasswordResetConfirm = () => {
  const { uid, token } = useParams();
  const { resetPasswordConfirm } = useAuth();
  const {
    handleSubmit,
    errors,
    control,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: initialFormValues,
  });
  const [serverError, setServerError] = useState(initialErrors);
  const passwordWatch = useWatch({
    control,
    name: 'password',
    defaultValue: '',
  });
  const [resetSuccess, setResetSuccess] = useState(false);

  const resetErrors = () => {
    setServerError(initialErrors);
  };

  const onSubmit = async (data) => {
    try {
      const res = await resetPasswordConfirm({ ...data, uid, token });

      if (res.error) {
        setServerError(res.error);
      } else {
        setResetSuccess(true);
      }
    } catch (error) {
      console.log('reset password error', error.message);
      setServerError(error.message);
    }
  };

  return (
    <Box
      bg="gray.50"
      minH="100vh"
      py="12"
      px={{ base: '4', lg: '8' }}
    >
      <Box maxW="md" mx="auto">
        <Logo mx="auto" h="32" mb={{ base: '10' }} />
        {serverError.length > 0 && (
          <Alert status="error" mb="8">
            <Flex flexDirection="column">
              <Flex flexDirection="row">
                <AlertIcon />
                <AlertTitle>Sorry, there was an error</AlertTitle>
              </Flex>
              <UnorderedList px="8">
                {serverError.map((error, index) => (
                  <ListItem key={index}>{error}</ListItem>
                ))}
              </UnorderedList>
            </Flex>
            <CloseButton
              position="absolute"
              right="8px"
              top="8px"
              onClick={resetErrors}
            />
          </Alert>
        )}
        {resetSuccess ? (
          <Heading
            textAlign="center"
            size="lg"
            fontWeight="bold"
            mb="8"
          >
            You've successfully reset your password!
          </Heading>
        ) : (
          <Box
            bg="white"
            py="8"
            px={{
              base: '4',
              md: '10',
            }}
            shadow="base"
            rounded={{
              sm: 'lg',
            }}
          >
            <chakra.form onSubmit={handleSubmit(onSubmit)}>
              <Stack spacing="4">
                <Controller
                  as={PasswordInput}
                  forgotPassword={false}
                  control={control}
                  rules={{ required: 'Password is required' }}
                  name="password"
                  focusBorderColor="primary.400"
                  borderColor="primary.300"
                  shadow="sm"
                  _hover={{
                    borderColor: 'primary.400',
                  }}
                  errors={errors.password}
                />
                <Controller
                  mb={4}
                  as={PasswordInput}
                  forgotPassword={false}
                  label="Confirm Password"
                  control={control}
                  rules={{
                    required: 'Password is required',
                    validate: (value) =>
                      value === passwordWatch ||
                      'Passwords must match',
                  }}
                  name="confirmPassword"
                  focusBorderColor="primary.400"
                  borderColor="primary.300"
                  shadow="sm"
                  _hover={{
                    borderColor: 'primary.400',
                  }}
                  errors={errors.confirmPassword}
                />
                <Button
                  type="submit"
                  colorScheme="primary"
                  size="lg"
                  isLoading={isSubmitting}
                  loadingText="Submitting..."
                >
                  Reset Password
                </Button>
              </Stack>
            </chakra.form>
          </Box>
        )}
        <Text mt="4" align="center" maxW="md" fontWeight="medium">
          <Link as={RouterLink} color="secondary.500" to="/login">
            Return to Sign in
          </Link>
        </Text>
      </Box>
    </Box>
  );
};

export default PasswordResetConfirm;
