import { apiClient } from '../../api/api-wrapper';
import { useAuth } from '../../context/authContext';
import useSWR from 'swr';

const fetcher = (...args) =>
  apiClient.get(...args).then((res) => res.data);

export const useGetStudySitesById = (studySiteId) => {
  const { isAuthenticated, token } = useAuth();
  const {
    data: studySite,
    error,
    isValidating,
    mutate,
  } = useSWR(
    isAuthenticated && token && studySiteId
      ? `/studysites/${studySiteId}/`
      : null,
    fetcher,
    {
      revalidateOnFocus: false,
    },
  );
  const { data: protocols } = useSWR(
    () => '/protocols/?study=' + studySite.study_uuid,
    fetcher,
    {
      revalidateOnFocus: false,
    },
  );

  const currentProtocol = protocols?.results.find(
    (p) => p.id === studySite.current_protocol,
  );

  const studySiteMerged = {
    ...studySite,
    current_protocol: currentProtocol,
  };

  return {
    studySite: studySiteMerged,
    isLoading: isValidating,
    isError: error,
    mutate,
  };
};
