import React from 'react';
import { Controller } from 'react-hook-form';
import { FormControl, FormLabel } from '@chakra-ui/react';
import { AsyncSelect } from 'chakra-react-select';
import { studies } from '../api/api-wrapper';

const ProtocolSelect = ({ studyId, control, defaultProtocol }) => {
  const loadOptions = () => {
    return studies
      .getStudyProtocolVersions(studyId)
      .then((response) => {
        return response.data.results.map((protocol) => ({
          label: protocol.version,
          value: protocol.uuid,
        }));
      });
  };

  return (
    <>
      <FormControl id="protocol">
        <FormLabel>Protocol Versions</FormLabel>
        <Controller
          as={AsyncSelect}
          control={control}
          name="protocol"
          loadOptions={loadOptions}
          defaultOptions={true}
          cacheOptions={true}
          focusBorderColor="primary.400"
          selectedOptionColor="primary"
          defaultValue={defaultProtocol}
        />
      </FormControl>
    </>
  );
};

export default ProtocolSelect;
