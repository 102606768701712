import React from 'react';
import { Controller } from 'react-hook-form';
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Stack,
  Textarea,
} from '@chakra-ui/react';
import { MinusCircle } from 'phosphor-react';
import { armTypes } from '../data/types';
import HWSelect from './HWSelect';

//TODO: error handling
const ArmGroupInput = ({
  item,
  error,
  control,
  index,
  remove,
  removable,
}) => {
  return (
    <>
      <Flex id={`arms[${index}]`}>
        <Stack spacing={4} flex={1}>
          <FormControl isRequired>
            <FormLabel>Arm Name</FormLabel>
            <Controller
              as={Input}
              control={control}
              name={`arms[${index}].name`}
              defaultValue={''}
              focusBorderColor="primary.400"
              borderColor="primary.300"
              shadow="sm"
              _hover={{
                borderColor: 'primary.400',
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Description</FormLabel>
            <Controller
              as={Textarea}
              control={control}
              name={`arms[${index}].description`}
              defaultValue={''}
              focusBorderColor="primary.400"
              borderColor="primary.300"
              shadow="sm"
              _hover={{
                borderColor: 'primary.400',
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Arm Type</FormLabel>
            <Controller
              control={control}
              name={`arms[${index}].type`}
              defaultValue={item.type}
              render={({ value, onChange }) => (
                <HWSelect
                  name={`arms[${index}].type`}
                  value={value}
                  onChange={onChange}
                  options={armTypes}
                />
              )}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Min Age</FormLabel>
            <Controller
              as={Input}
              control={control}
              name={`arms[${index}].minAge`}
              defaultValue={''}
              type="number"
              focusBorderColor="primary.400"
              borderColor="primary.300"
              shadow="sm"
              _hover={{
                borderColor: 'primary.400',
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Max Age</FormLabel>
            <Controller
              as={Input}
              control={control}
              name={`arms[${index}].maxAge`}
              defaultValue={''}
              type="number"
              focusBorderColor="primary.400"
              borderColor="primary.300"
              shadow="sm"
              _hover={{
                borderColor: 'primary.400',
              }}
            />
          </FormControl>
        </Stack>
        <IconButton
          aria-label="Remove condition"
          variant="link"
          disabled={!removable}
          onClick={remove}
          icon={
            <Box as={MinusCircle} fontSize="2xl" color="red.600" />
          }
        />
      </Flex>
    </>
  );
};

export default ArmGroupInput;
