import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './authContext';
import { ThemeProvider } from 'styled-components/macro';
import { UserProvider } from './userContext';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { colors } from '../styles/theme';

const chakraTheme = extendTheme({ colors });
//TODO: design theme, look into lighten/darken tool?
// rename this file
// accessibility
export const theme = {
  colors: {
    primary: {
      main: '#4D7E7B',
      light: '#6D9593',
      dark: '#275855',
    },
    secondary: {
      main: '#3F51B5',
      light: '#5C6BC0',
      dark: '#303F9F',
    },
    neutral: {
      main: '#a39e93',
      light: '#d3cec4',
      lightest: '#faf9f7',
      dark: '#625d52',
      darkest: '#27241d',
    },
    error: {
      main: '#e12d39',
      light: '#ed81884d',
      dark: '#871b22',
    },
    warning: {
      main: '#f0b429',
      light: '#f6d27f',
      dark: '#906c19',
    },
    info: {
      main: '#2bb0ed',
      light: '#80d0f4',
      dark: '#1a6a8e',
    },
  },
  fontSizes: {
    small: '1em',
    medium: '2em',
    large: '3em',
  },
  navbar: {
    height: '66px',
  },
  app: {},
};

function AppProviders({ children }) {
  return (
    <Router>
      <ChakraProvider theme={chakraTheme}>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <UserProvider>{children}</UserProvider>
          </AuthProvider>
        </ThemeProvider>
      </ChakraProvider>
    </Router>
  );
}

export { AppProviders };
