import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../context/authContext';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  chakra,
  CloseButton,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { mutate } from 'swr';
import Logo from '../components/Logo';

const initialErrors = [];
const initialMfaCodeValues = {
  mfaCode: '',
};

const MfaCodeEntry = () => {
  const { push } = useHistory();
  const {
    confirmMfaCode,
    mfaData: { method },
    resendMfaCode,
  } = useAuth();
  const {
    handleSubmit,
    errors,
    control,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: initialMfaCodeValues,
  });
  const [serverError, setServerError] = useState(initialErrors);
  const [redirect, setRedirect] = useState('');

  useEffect(() => {
    if (redirect) {
      push(redirect);
    }
  }, [redirect, push]);

  const resetErrors = () => {
    setServerError(initialErrors);
  };

  const onSubmit = async (data) => {
    try {
      const res = await confirmMfaCode(data.mfaCode);

      if (res.error) {
        setServerError(res.error);
      } else {
        mutate('userstudies/', null, true);
        mutate('researcher/studies/', null, true);
        setRedirect('/');
      }
    } catch (error) {
      console.log(error);
      setServerError([error.message]);
    }
  };

  const handleResendCode = async () => {
    try {
      const res = await resendMfaCode();

      if (res.error) {
        setServerError(res.error);
      }
    } catch (error) {
      console.log(error);
      setServerError([error.message]);
    }
  };

  return (
    <Box
      minH="100vh"
      py={{ base: '4', md: '12' }}
      px={{ base: '4', lg: '8' }}
    >
      <Box maxW="md" mx="auto">
        <Logo
          mx="auto"
          h={{ base: '16', md: '32' }}
          mb={{ base: '4', md: '10' }}
        />
        {serverError.length > 0 && (
          <Alert status="error" mb="8">
            <Flex flexDirection="column">
              <Flex flexDirection="row">
                <AlertIcon />
                <AlertTitle>Sorry, there was an error</AlertTitle>
              </Flex>
              <UnorderedList px="8">
                {serverError.map((error, index) => (
                  <ListItem key={index}>{error}</ListItem>
                ))}
              </UnorderedList>
            </Flex>
            <CloseButton
              position="absolute"
              right="8px"
              top="8px"
              onClick={resetErrors}
            />
          </Alert>
        )}
        <Heading
          textAlign="center"
          size="md"
          fontWeight="bold"
          mb={4}
        >
          Please enter the 6-digit code you received by{' '}
          {method?.includes('sms') ? 'SMS' : 'email'}
        </Heading>
        <Text mb={4} textAlign="center">
          It could take up to 10 minutes to receive your code.
        </Text>
        <Flex direction="column">
          <chakra.form onSubmit={handleSubmit(onSubmit)}>
            <FormControl
              id="mfaCode"
              isInvalid={errors.mfaCode}
              mb={8}
            >
              <Controller
                as={Input}
                control={control}
                name="mfaCode"
                focusBorderColor="primary.400"
                borderColor="primary.300"
                shadow="sm"
                _hover={{
                  borderColor: 'primary.400',
                }}
                rules={{
                  required: 'Code is required',
                  minLength: {
                    value: 6,
                    message: 'Must be 6 digits',
                  },
                  maxLength: {
                    value: 6,
                    message: 'Must be 6 digits',
                  },
                }}
              />
              <FormErrorMessage>
                {errors.mfaCode?.message}
              </FormErrorMessage>
            </FormControl>
            <Flex>
              <Button
                w="full"
                type="submit"
                colorScheme="primary"
                size="lg"
                isLoading={isSubmitting}
                loadingText="Submitting..."
                disabled={isSubmitting}
              >
                Submit
              </Button>
            </Flex>
          </chakra.form>
          <Text
            width="full"
            mt="4"
            align="center"
            fontWeight="medium"
          >
            <Text as="span">Didn't receive a code?</Text>{' '}
            <Button
              variant="link"
              color="secondary.500"
              onClick={handleResendCode}
            >
              Resend
            </Button>
          </Text>
        </Flex>
      </Box>
    </Box>
  );
};

export default MfaCodeEntry;
