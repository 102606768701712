import React from 'react';
import {
  Box,
  ListItem,
  OrderedList,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';

const TextParagraph = ({ children, ...rest }) => {
  return (
    <Text as="p" pl={4} {...rest}>
      {children}
    </Text>
  );
};

const TermsSection = ({ title, children }) => {
  return (
    <Stack as="section" spacing={4}>
      {title && <Text fontWeight="bold">{title}</Text>}
      {children}
    </Stack>
  );
};

const TermsSubsection = ({ title, children }) => {
  return (
    <Stack as="section" spacing={4}>
      <SubsectionTitle title={title} />
      {children}
    </Stack>
  );
};

const SubsectionTitle = ({ title, ...rest }) => {
  return (
    <Text fontWeight="bold" fontStyle="italic" {...rest}>
      {title}
    </Text>
  );
};

const TermsOfService = () => {
  return (
    <Box
      maxW={{ base: 'lg', md: '3xl' }}
      mx="auto"
      px={{
        base: '6',
        md: '12',
      }}
      py={{
        base: '8',
        md: '16',
      }}
      letterSpacing="wide"
    >
      <Stack spacing={6}>
        <Text
          as="h4"
          fontSize="xl"
          textAlign="center"
          fontWeight="bold"
        >
          HEALTH WALLET LLC TERMS OF SERVICE AGREEMENT
        </Text>

        <Text>Last updated: September 28, 2021</Text>

        <TermsSection>
          <TextParagraph>
            This Terms of Service Agreement (“<b>Agreement</b>”) sets
            forth the terms and conditions upon which you may create a{' '}
            <b>Health Wallet</b> account and/or use any services,
            updates, enhancements, or upgrades (collectively, the “
            <b>Service</b>”), whether on mobile or non-mobile hardware
            platforms, offered by Health Wallet LLC (hereinafter “
            <b>Health Wallet</b>”, “<b>Health Wallet LLC</b>”, and/or
            “<b>Service</b>") and/or its authorized distributors.
            “You”, “your”, “yourself”, “user”, and “member” means you,
            as an individual.
          </TextParagraph>

          <TextParagraph fontStyle="italic">
            Please read this agreement carefully in its entirety
            before using the service. If you do not agree to be bound
            by this agreement, you may not use the service.
          </TextParagraph>

          <TextParagraph>
            This agreement contains warranty disclaimers and other
            provisions that limit Health Wallet’s liability to you. By
            using the service, you acknowledge and agree that you have
            read and understand this agreement, the provisions,
            disclosures, and disclaimers set forth in this agreement
            are fair and reasonable, and your agreement to follow and
            be bound by this agreement is voluntary and is not the
            result of fraud, duress, or undue influence exercised upon
            you by any person or entity.
          </TextParagraph>
        </TermsSection>

        <TermsSection title="1. IN GENERAL">
          <TextParagraph>
            The terms of this Agreement will govern all aspects of the
            Service unless an upgrade is accompanied by a separate
            license in which case the terms of that license will
            govern. You agree to comply with all terms, conditions,
            and restrictions set forth in this Agreement. You
            acknowledge that any use of the Service not in compliance
            with this Agreement invalidates this license and may be
            prosecuted to the full extent of the law.
            <Text as="p" fontWeight="bold" fontStyle="italic">
              By using this Service, you represent and warrant that
              you are at least 18 years old.
            </Text>
          </TextParagraph>
        </TermsSection>

        <TermsSection title="2. PERMITTED USE AND RESTRICTIONS">
          <TermsSubsection title="2.1 Grant of License and Reservations for Users.">
            <TextParagraph>
              Subject to the terms of this Agreement, Health Wallet
              grants you a limited, non-exclusive, revocable,
              non-transferable license to use the Service and any
              future fixes, updates, and upgrades provided to you
              solely for use in helping you learn about clinical trial
              options, including transmitting, accessing, managing,
              collecting, and displaying User Information (defined in
              clause 3 below) by and among authorized Clinical
              Professionals. This Service is licensed, not sold, to
              you by Health Wallet for use only under the terms of
              this Agreement. Health Wallet reserves all rights not
              expressly granted to you. The rights granted in this
              Agreement are limited to Health Wallet’s intellectual
              property rights in the Service and do not include any
              other patents or intellectual property rights.
            </TextParagraph>
          </TermsSubsection>

          <TermsSubsection title="2.2 Reverse Engineering.">
            <TextParagraph>
              You agree not to reverse engineer, de-compile,
              disassemble, alter, duplicate, modify, rent, lease,
              loan, sublicense, make copies, create derivative works
              from, distribute or provide others with the Service, in
              whole or part, or transmit or communicate the Service
              over a network or to any Third Party (defined herein
              Section 7). Further, you agree not to develop, sell, or
              distribute applications that are capable of launching,
              being launched from, or are otherwise integrated with,
              the Service or content accessed through the Service
              without Health Wallet’s express written permission.
            </TextParagraph>
          </TermsSubsection>

          <TermsSubsection title="2.3 Further Restrictions.">
            <TextParagraph>
              You further agree to not use the Service to do the
              following:
            </TextParagraph>
            <OrderedList styleType="lower-roman" spacing={4} pl={16}>
              <ListItem>
                In general, break any of the laws of the United States
                as a whole and/or any of the laws of its fifty (50)
                individual States.
              </ListItem>
              <ListItem>
                Transmit, access, or communicate any data that you do
                not have the right to transmit, access, or communicate
                under applicable Law (as defined in clause 15(b)
                below), including the Health Insurance Portability and
                Accountability Act of 1996 as modified by the Health
                Information Technology for Economic and Clinical
                Health Act and all rules, regulations, and related
                laws and acts promulgated under and in connection
                therewith (collectively, “HIPAA”), under similar more
                stringent state laws, or under a contractual or
                fiduciary relationship;
              </ListItem>
              <ListItem>
                Infringe any patent, trademark, trade secret,
                copyright, or other proprietary rights of any party;
              </ListItem>
              <ListItem>
                Transmit or communicate any data that contains
                software viruses or any other computer code, files, or
                programs designed, intended, or likely to interrupt,
                destroy or limit the functionality of any computer
                software or hardware or any telecommunications
                equipment;
              </ListItem>
              <ListItem>
                Interfere with or disrupt or circumvent the Service;
              </ListItem>
              <ListItem>
                Intentionally or unintentionally violate any
                applicable local, state, national or international
                Law, including laws relating to securities exchange
                and any regulations, requirements, procedures or
                policies in force from time to time relating to the
                Service;
              </ListItem>
              <ListItem>
                Transmit or communicate any data that is unlawful,
                harmful, threatening, abusive, harassing, defamatory,
                vulgar, obscene, invasive of another’s privacy,
                hateful or racially, ethnically or otherwise
                objectionable;
              </ListItem>
              <ListItem>Use the Service while driving;</ListItem>
              <ListItem>Endanger yourself or others;</ListItem>
              <ListItem>
                Engage in any act that we deem conflicts with the
                spirit or intent of the Service;
              </ListItem>
              <ListItem>
                Solicit or attempt to solicit personal information
                from other users or collect or post anyone’s private
                information, including personally identifiable
                information (whether in text, image, or video form),
                identification documents, or financial information
                through the Service.
              </ListItem>
            </OrderedList>
          </TermsSubsection>

          <TermsSubsection title="2.4 Account Suspension and Termination.">
            <OrderedList styleType="lower-roman" spacing={4} pl={16}>
              <ListItem>
                Terminations by You
                <TextParagraph>
                  You may stop using the Service at any time. Follow
                  the instructions provided in your account settings
                  page to delete your account with Health Wallet and
                  remove your data from our database, or email
                  support@healthwallet.app.
                </TextParagraph>
              </ListItem>
              <ListItem>
                Terminations and Suspensions by Health Wallet for
                Cause
                <TextParagraph>
                  Health Wallet may suspend or terminate your access
                  to all or part of the Service if (a) you materially
                  or repeatedly breach this Agreement; (b) we are
                  required to do so to comply with a legal requirement
                  or a court order; or (c) we believe there has been
                  conduct that creates (or could create) liability or
                  harm to any user, other third party, Health Wallet,
                  or our Affiliates.
                </TextParagraph>
              </ListItem>
              <ListItem>
                Terminations by Health Wallet for Service Changes
                <TextParagraph>
                  Health Wallet may terminate your access to all or
                  part of the Service if Health Wallet believes, in
                  its sole discretion, that provision of the Service
                  to you is no longer commercially viable.
                </TextParagraph>
              </ListItem>
              <ListItem>
                Notice for Termination or Suspension
                <TextParagraph>
                  We will notify you with the reason for termination
                  or suspension by Health Wallet unless we reasonably
                  believe that to do so: (a) would violate the law or
                  the direction of a legal enforcement authority, or
                  would otherwise risk legal liability for Health
                  Wallet or our Affiliates; (b) would compromise an
                  investigation or the integrity or operation of the
                  Service; or (c) would cause harm to any user, other
                  third party, Health Wallet or our Affiliates. Where
                  Health Wallet is terminating your access for Service
                  changes, where reasonably possible, you will be
                  provided with sufficient time to export your Content
                  from the Service.
                </TextParagraph>
              </ListItem>
            </OrderedList>
          </TermsSubsection>
        </TermsSection>

        <TermsSection title="3. USER INFORMATION">
          <TextParagraph>
            You acknowledge and agree that the Service may be used to
            transmit, collect, access, manage, and display Protected
            Health Information (“PHI” hereinafter) by and among you,
            only as authorized through express consent in this
            agreement. For each entity outside of Health Wallet that
            you affirmatively authorize, -- such as your authorized
            Clinical Professionals-- their employees and contractors
            may also have access to your PHI as authorized by this
            agreement and Health Wallet’s Privacy Policy, and it is
            solely the responsibility of such Third Party (defined
            herein Section 7) to protect and stay within such
            authorization per their policies. You acknowledge and
            agree that PHI may be stored by Health Wallet and/or its
            licensees and service providers in connection with
            providing the Service and its related services, as well as
            shared among other users of the Service and its related
            services as authorized by you. You acknowledge and agree
            that the PHI stored by Health Wallet shall not serve as
            sole or primary system of record for any User, personal
            representative of a User, health care provider, any
            business associate of a healthcare provider, or any
            affiliates of the foregoing.
          </TextParagraph>
          <TermsSubsection title="3.1 Protected Health Information">
            <TextParagraph>
              “Protected health information” means, collectively,
              information and data related to the provision of health
              care to Users, their health status, medical records,
              survey questions, survey data, and related information
              and documents, including consent to treatment forms,
              authorization to disclose medical information forms,
              Medicare forms, Medicaid forms, living wills, Directives
              to Physicians and Family or Surrogates, Medical Powers
              of Attorney, Out-of-Hospital Do-Not-Resuscitate Orders,
              Declarations of Mental Health Treatment, images,
              reports, and lab and test results, medical treatments
              performed by you and/or other Clinical Professionals,
              and other “Protected Health Information,” as defined
              under HIPAA and similar terms as defined by state,
              national, or international law. You agree that your
              provision and use of all PHI will be in compliance with
              HIPAA and all other applicable Laws.
            </TextParagraph>
          </TermsSubsection>
          <TermsSubsection title="3.2 Clinical Professionals and Prior Diagnosis">
            <TextParagraph>
              “Clinical Professional” as used herein refers to any
              duly licensed or unlicensed provider of research or care
              that may interact with User as a consequence of User’s
              Membership with Health Wallet. Health Wallet works to
              ensure, but cannot guarantee, that all Clinical
              Professionals provide accurate and complete professional
              licensure information, verifiable with their various
              professional boards and public record at time of
              enrollment with Health Wallet, and to keep the
              information updated thereafter.
            </TextParagraph>
            <TextParagraph>
              User guarantees that any PHI provided in their User
              Profile regarding prior diagnosis, conditions,
              medications, and any other data pertinent to their usage
              of Health Wallet is correct and complete. Health Wallet
              is not liable beyond a non-heightened standard of care
              to ensure that this information is complete and correct,
              although they may do so at their sole discretion.
            </TextParagraph>
          </TermsSubsection>
        </TermsSection>

        <TermsSection title="4. MEDICAL ADVICE AND TREATMENT">
          <TextParagraph>
            Health Wallet does not provide medical advice, diagnosis,
            or treatment. You acknowledge and agree that the Service
            is merely a conduit of information related to Users and
            the provision of potential clinical studies by independent
            Third Party (defined herein Section 7) Clinical
            Professionals including yourself, researchers,
            coordinators, physicians, physician assistants, nurses,
            paramedics, emergency care responders, other physician
            extenders, healthcare systems, healthcare facilities, or
            other providers of healthcare services (collectively,
            “Clinical Professional(s)”). You acknowledge and agree
            that the Clinical Professionals are solely responsible for
            and will have complete authority, responsibility,
            supervision, and control over the provision of all
            research, clinical services, advice, instructions,
            treatment decisions, and other professional services
            performed, and that all diagnoses, treatments, procedures,
            and other professional services will be provided and
            performed exclusively by or under the supervision of
            Clinical Professionals as they, in their sole discretion,
            deem appropriate. You further acknowledge and agree that
            Health Wallet does not provide or endorse any medical
            advice on or through the Service and no information
            obtained through the Service can be so construed or used.
            Health Wallet will have and exercise absolutely no
            control, authority, or supervision over the provision of
            any clinical services or other professional services. The
            use of the Service, all text, graphics, images, audio
            content, audiovisual content, data, other materials, and
            any other information provided on, entered into, or made
            available through the Service, including all clinically
            related information, whether provided by you, the entity
            or organization with which you are employed or otherwise
            affiliated ("Organization"), other Clinical Professionals,
            or other third parties (collectively, “Content”) is solely
            your responsibility. Health Wallet will make all
            reasonable efforts in accordance with applicable laws and
            agreements to safeguard the integrity and availability of
            the Content. Further, when using the Service, information
            may be transmitted over a medium that may be beyond the
            control and jurisdiction of Health Wallet and its
            suppliers. Accordingly, Health Wallet assumes no liability
            for or relating to the delay, failure, interruption, or
            corruption of any data or other information transmitted in
            connection with use of the Service that is reasonably
            determined to be beyond Health Wallet’s control.
            Accordingly, you acknowledge and agree that you are solely
            responsible for all clinical services, advice,
            instructions, diagnoses, treatments, procedures, and other
            services in connection with using the Service or
            otherwise.
          </TextParagraph>
        </TermsSection>

        <TermsSection title="5. REGISTRATION">
          <TextParagraph>
            Upon registration, for any and all information you choose
            to provide, you agree to provide current, accurate, and
            complete information about you, your research, and/or your
            Organization as is required in using the Service. Possible
            examples include personal information (name, location,
            health information, etc) and study information
            (interventions, eligibility criteria, arms, etc). Further,
            you agree to maintain and update your data as required to
            keep it current, accurate, and complete. You agree that
            Health Wallet may store and use the data you provide in
            connection with your use of the Service in accordance with
            the Privacy Policy. Health Wallet has the right, but not
            the duty, to confirm or otherwise verify or check, in its
            sole discretion, the truth and accuracy of any registered
            information at any time. For paid (researcher)
            subscriptions, verification of your registered
            information, specifically, your name, address and/or tax
            identification number, against a third-party database may
            be considered to constitute a “credit check” under certain
            laws. Health Wallet is not making, and will not otherwise
            make, any type of inquiry to any Third Party (defined
            herein Section 7) regarding any individual’s credit
            history and personal financial information without first
            obtaining such individual’s express authorization to do
            so. Health Wallet may terminate your rights to the entire
            Service, if any information you provide is false or
            inaccurate.
          </TextParagraph>
        </TermsSection>

        <TermsSection title="6. ACCOUNT REGISTRATION SECURITY AND USER WARRANTIES TO HEALTH WALLET">
          <TextParagraph>
            To use the Services, you will be required to create an
            account with Health Wallet ( “Account”). As part of the
            Account creation process, you will provide an email
            address and password. Your email address and password are
            your credentials to access the Services. You are
            responsible for keeping your password and log-in
            information secure.{' '}
            <b>
              If you believe that your password has been stolen or
              compromised, it is your responsibility to change your
              password right away, either from within the Services or
              by contacting us at support@healthwallet.app.
            </b>
          </TextParagraph>
          <TextParagraph>
            As part of the Account creation process, you will also
            provide certain personal information. It is your
            responsibility to keep your information accurate and up to
            date.
          </TextParagraph>
          <TextParagraph>
            User warrants that User will not:
          </TextParagraph>
          <UnorderedList spacing={4} pl={16}>
            <ListItem>Create multiple Accounts;</ListItem>

            <ListItem>
              Provide false personal information to us or create any
              Account for anyone other than the User without such
              person’s permission;
            </ListItem>

            <ListItem>
              Use a username that is the name of another person with
              the intent to impersonate that person; or
            </ListItem>

            <ListItem>
              Use another User’s Account unless that person has
              authorized you to do so.
            </ListItem>
          </UnorderedList>
        </TermsSection>

        <TermsSection title="7. THIRD PARTY TECHNOLOGY">
          <TextParagraph>
            Any and all Third Party technology provided, made
            available, linked to, or otherwise accessible through the
            Service (“Third Party”) is provided solely as a
            convenience to you and is not under the control of Health
            Wallet. Health Wallet does not endorse, recommend, or
            otherwise make any representations or warranties with
            respect to any Third Party technology. Health Wallet does
            not have any responsibility or liability to you for any
            Third Party technology which you access and you use at
            your own risk. Further, you agree to comply with any and
            all terms and conditions applicable to the use of Third
            Party technology and otherwise ensure that you have
            obtained all rights, licenses, and clearances that may be
            necessary to use such Third Party technology.
          </TextParagraph>
        </TermsSection>

        <TermsSection title="8. THIRD PARTY WEBSITES">
          <TextParagraph>
            This Service may contain links to other independent Third
            Party (defined herein Section 7) websites (“Linked
            Websites”). These Linked Websites are provided solely as a
            convenience to our visitors. Such Linked Websites are not
            under the control of Health Wallet, and Health Wallet is
            not responsible for and does not endorse the content of
            such Linked Websites, including any information or
            materials contained on such Linked Websites. Health Wallet
            does not have any responsibility or liability for any
            information, data, communications, or materials available
            on such third-party sites. You therefore access these
            Linked Websites at your own risk.
          </TextParagraph>
        </TermsSection>

        <TermsSection title="9. COLLECTION OF INFORMATION">
          <TextParagraph>
            You grant Health Wallet the perpetual, non-exclusive,
            worldwide, royalty-free irrevocable license to use, copy,
            print, display, reproduce, modify, publish, post,
            transmit, distribute, and preserve any transmittal,
            communication, or other Content provided by you through
            the Service, or any other service offered on or through
            the Service, and data related to your use of the Service,
            including your name, profile, biography, and treatment and
            response times.
          </TextParagraph>
          <TermsSubsection title="9.1 Express Consent to Sharing of Personal Data">
            <TextParagraph>
              Health Wallet may disclose any such information and
              content to other users of the Service, Health Wallet’s
              licensees, service providers, clients, researchers, and
              other third parties in accordance with applicable Law,
              agreements, HIPAA requirements, and the Privacy Policy.{' '}
              <b>
                This sharing of information will be predicated on
                affirmative acceptance of such dissemination of data,
                subject to the conditions stated herein.
              </b>{' '}
              Health Wallet may also use or disclose such data if
              required to do so by Law or Health Wallet determines
              that such use or disclosure is reasonably necessary to
              (1) comply with legal process, (2) enforce this
              Agreement, (3) respond to claims that any such data
              violates the rights of others, or (4) protect the
              rights, property, or personal safety of Health Wallet,
              its employees, and users of the Service. For further
              information regarding your privacy, please review our
              Privacy Policy. Notwithstanding the foregoing, the
              Privacy Policy may be changed from time to time without
              amending this Agreement. To the extent that there is a
              conflict between the Privacy Policy and this Agreement,
              this Agreement will control with respect to your use of
              the Service; the Privacy Policy controls with respect to
              the collection, use, disclosure, and disposal of your
              information.
            </TextParagraph>
          </TermsSubsection>
        </TermsSection>

        <TermsSection title="10. AUTHORIZED USE">
          <TextParagraph>
            Except as expressly set forth in this Agreement, you will
            protect the confidentiality of the Service, and will not
            distribute or otherwise make available the Service, or any
            portion of the Service, in any form to any Third Party
            (defined herein Section 7). Any rights you may possess in
            the Service expire upon expiration or termination of this
            Agreement. You agree that you will employ the security
            measures necessary to prevent unauthorized users from
            accessing the Service including your user ID(s) and
            password(s) (hereinafter “Login Information”). You are
            solely responsible for the maintenance and protection of
            your Login Information. You accept responsibility for, and
            will be liable for, all access to the Service in
            connection with your Login Information. Without the prior
            written consent of Health Wallet, you will not utilize the
            services of any Third Party (defined herein Section 7) to
            assist you in using the Service. Further, you will be
            responsible for all activities that occur under or in
            connection with your account and your use of the Service.
          </TextParagraph>
        </TermsSection>

        <TermsSection title="11. EXPORT LAW ASSURANCES">
          <TextParagraph>
            You will not use or otherwise export or re-export this
            Service except as authorized by United States law and the
            laws of the jurisdiction in which the Service was
            obtained. In particular, but without limitation, the
            Service will not be exported or re-exported (1) into (or
            to a national or resident of) any U.S. embargoed
            countries, or (2) to anyone on the U.S. Treasury
            Department’s list of Specially Designated Nationals or the
            U.S. Department of Commerce Denied Persons’ List or Entity
            List. By using the Service, you represent and warrant that
            you are not located in, under control of, or a national or
            resident of any such country or on any such list.
          </TextParagraph>
        </TermsSection>

        <TermsSection title="12. TERRITORY">
          <TextParagraph>
            Presently, the Service is available to citizens of the
            United States only, for use in the United States only. You
            understand and acknowledge that should you sign up or use
            the Service from a jurisdiction outside of the United
            States, Health Wallet provides no warranty with regard to
            language or legal support. Further, any clinical studies
            that you choose to engage in outside of the United States
            are not impliedly or expressly supported by this agreement
            or Health Wallet. Health Wallet may use technologies to
            verify your compliance. You agree to abide by United
            States, and other applicable export control laws and not
            to transfer, by electronic transmission or otherwise, any
            content or software subject to restrictions under such
            laws to a national destination or person prohibited under
            such laws.
          </TextParagraph>
        </TermsSection>

        <TermsSection title="13. TRADEMARKS">
          <TextParagraph>
            Trademarks, service marks, graphics and logos used in
            connection with the Service are the trademarks of their
            respective owners. Health Wallet™ and the trademarks
            related to the Service are trademarks of Health Wallet
            LLC. You are not granted any right or license with respect
            to any of the trademarks mentioned above and any use of
            such trademarks.
          </TextParagraph>
          <TextParagraph>
            “Health Wallet”, and all logos related to the Health
            Wallet services are either trademarks or registered
            trademarks of Health Wallet or Health Wallet’s licensors.
            You may not copy, imitate, modify, or use them without
            Health Wallet’s prior written consent. In addition, all
            page headers, custom graphics, button icons, and scripts
            are service marks, trademarks, and/or trade dress of
            Health Wallet. You may not copy, imitate, modify, or use
            them without our prior written consent. You may use
            publicly available logos provided directly or indirectly
            as owned by Health Wallet for the purpose of providing
            awareness of Health Wallet or referring user traffic to
            Health Wallet services. You may not alter, modify, or
            change these logos in any way, use them in a manner that
            mischaracterizes Health Wallet or the Health Wallet
            services or display them in any manner that implies Health
            Wallet’s sponsorship or endorsement. All right, title and
            interest in and to the Health Wallet websites, any content
            thereon, Health Wallet services, the technology related to
            Health Wallet services, and any and all technology and any
            content created or derived from any of the foregoing is
            the exclusive property of Health Wallet and its licensors.
          </TextParagraph>
        </TermsSection>

        <TermsSection title="14. COPYRIGHT INFRINGEMENT">
          <TextParagraph>
            Health Wallet respects copyright and other laws. Health
            Wallet requires all Service users to comply with copyright
            and other laws. Health Wallet does not, by the supply of
            the Service, authorize you to infringe the copyright or
            other rights of third parties. As a condition to use the
            Service, you agree that you must not use the Service to
            infringe upon the intellectual property or other rights of
            others in any way. The unauthorized reproduction,
            distribution, modification, public display, communication
            to the public or public performance of copyrighted works
            is an infringement of copyright. You are entirely
            responsible for your conduct and for ensuring that it
            complies with all applicable copyright and data-protection
            laws. In the event you fail to comply with laws regarding
            copyrights or other intellectual property rights, data
            protection and privacy, you may be exposed to civil and
            criminal liability, including possible fines and jail
            time. Health Wallet will not indemnify you or your agents
            against liability in any of these matters.
          </TextParagraph>
        </TermsSection>

        <TermsSection title="15. OWNERSHIP AND TITLE">
          <TextParagraph>
            All title to and the rights in the Service, including
            ownership rights to patents (registrations, renewals, and
            pending applications), copyrights, trademarks, service
            marks, domain names, trade dress, trade secrets, Health
            Wallet’s or Third Party (defined herein Section 7) other
            technology, any derivatives of and all goodwill associated
            with the foregoing is the exclusive property of Health
            Wallet and/or Third Parties ( defined herein Section 7).
          </TextParagraph>
        </TermsSection>

        <TermsSection title="16. REPRESENTATIONS & WARRANTIES">
          <TextParagraph>
            In addition to the other representations and warranties
            contained in this Agreement, you further represent,
            warrant, and covenant to Health Wallet the following:
          </TextParagraph>
          <SubsectionTitle title="16.1 All information you provide to Health Wallet as part of the registration process or otherwise will be truthful and accurate, irrespective of any independent verification or other determination made by Health Wallet;" />
          <SubsectionTitle title="16.2 You, your practice, and all goods and services provided in connection with your use of the Service will comply with all applicable international, national, federal, state, and local laws, regulations, ordinances, and judicial decisions in courts and tribunals of competent jurisdiction within the United States as applicable (collectively, “Law”);" />
          <SubsectionTitle title="16.3 You are legally authorized in accordance with applicable Law to provide any and all User Information that you provide to the Service for all uses contemplated under this Agreement;" />
          <SubsectionTitle title="16.4 This Agreement has been duly and validly authorized, accepted, agreed to, and delivered by you (or your authorized representative) and constitutes your legal, valid, and binding obligation, enforceable against you in accordance with this Agreement. You represent that you have full power, capacity, and authority to enter into this Agreement. If you are accepting on behalf of your employer or an entity, you represent that you have full legal authority to bind your employer or such entity to this Agreement; and" />
          <SubsectionTitle title="16.5 The performance by you of this Agreement and your use of the Service does not and will not conflict with or violate (1) any law, rule, regulation, order, judgment, decree, agreement, instrument, or obligation applicable to you, or (2) if you are an entity, any provision of your organizational or governing documents." />
        </TermsSection>

        <TermsSection title="17. DISCLAIMER OF WARRANTIES">
          <TextParagraph>
            The Service, and any Third Party (defined herein Section
            7) technology are provided on an “as is” and “as
            available” basis without warranties of any kind, either
            express or implied, including warranties of title,
            non-infringement, and implied warranties of
            merchantability or fitness for a particular purpose.
            without limiting the generality of the foregoing, Health
            Wallet, its licensors, and suppliers make no warranty,
            representation, or guaranty:
          </TextParagraph>
          <SubsectionTitle title="17.1 As to the sequence, accuracy, timeliness, relevance, or completeness of the Service;" />
          <SubsectionTitle title="17.2 As to any information offered or provided within or through the service regarding treatment of medical conditions, actions, diagnoses, procedures, servicelication of medication, or other provision of healthcare services;" />
          <SubsectionTitle title="17.3 That the Service  may be relied upon for any reason, that the use of the Service  will be uninterrupted or error free. Further, your use of the Service, and any Third Party (defined herein Section 7) technology is at your own risk. Health Wallet does not warrant that the Service or Third Party (defined herein Section 7) technology will meet your specific requirements. To the extent that Health Wallet may not disclaim any warranty as a matter of law, the scope and duration of such warranty will be the minimum permitted under such law." />
          <SubsectionTitle title="17.4 Health Wallet does not provide any warranty regarding the efficacy of any medical/clinical trial or service provided by a Third Party ( defined herein Section 7)." />
        </TermsSection>

        <TermsSection title="18. LIMITATION OF LIABILITY">
          <TextParagraph>
            Except where prohibited by law, under no circumstances
            will Health Wallet be liable to you or any other person
            for direct, consequential, special, indirect, incidental,
            punitive, or exemplary damages, costs, expenses, losses,
            or lost profits in connection with the Service or
            otherwise related to this agreement. The provisions of
            this section will service regardless of the form of
            action, damage, claim, liability, cost, expense, or loss,
            whether in contract, statute, tort (including negligence),
            failure of essential purpose or otherwise, and even if
            advised of the likelihood of such damages. Health Wallet
            will not be liable for any failure to perform its
            obligations under this agreement because of circumstances
            beyond its control, including (but not limited to) natural
            disaster (“force majeure”), terrorism, Third Party
            (defined herein Section 7) labor disputes, war,
            declarations of governments, transportation delays,
            failure of hardware, equipment, or telecommunications
            failure. Health Wallet will not be liable for any failure
            to perform its obligations under this agreement because of
            your misuse of the Service by you or any other person.
            this section will be given full effect even in the event
            that any exclusive remedy provided has failed of its
            essential purpose. further, in no event will Health Wallet
            be liable to you or anyone else for any claims, losses, or
            damages arising out of any use or misuse of any Third
            Party (defined herein Section 7) technology.{' '}
            <i>
              The parties acknowledge that the terms of this section
              reflect the allocation of risk set forth in this
              agreement and that the parties would not enter into this
              agreement without these limitations of liability.
            </i>
          </TextParagraph>
        </TermsSection>

        <TermsSection title="19. INDEMNIFICATION">
          <TextParagraph>
            You will indemnify, defend, and hold indemnitees (as
            defined below) harmless from and against any and all
            claims and losses arising from or attributable to (1) your
            provision of medical services and treatment of Users in
            connection with your use of the Service or otherwise; (2)
            your breach of any of your representations, warranties,
            covenants, or other agreements made under this agreement;
            (3) any claims by or disputes related to your use of the
            Service ; (4) any content you provide to the Service or
            otherwise transmit using the Service; and (5) breach of
            confidentiality related to your use of the Service.
          </TextParagraph>
          <TermsSubsection title="19.1 Procedures.">
            <TextParagraph>
              The Indemnitee will give you written notice of any Claim
              for which indemnification is sought. However, failure to
              provide such notice will not relieve you from your
              liability or obligations under this Agreement, except to
              the extent you are materially prejudiced as a direct
              result of such failure. The Indemnitee will cooperate
              with you at your expense in connection with the defense
              and settlement of any claim. You may not settle any
              indemnified Claim in a manner that adversely affects the
              Indemnitee without its prior written consent. Further,
              the Indemnitee may participate in the defense of the
              Claim through counsel of its own choosing at its own
              cost and expense. If you fail to promptly assume the
              defense and employ counsel reasonably satisfactory to
              Indemnitee, or the Indemnitee has been advised by
              counsel that there exist actual or potential conflicting
              interests between you or your counsel and such
              Indemnitee, the Indemnitee may employ separate counsel,
              in addition to local counsel, to represent or defend
              such Indemnitee in such action or proceeding, and you
              agree to pay the fees and disbursements of such separate
              counsel. To the extent indemnification requires the
              payment of monies owed, such indemnification will occur
              as soon as reasonably possible after the determination
              of monies owed, and payment to the Indemnitee will be
              made within 30 days of a final determination of monies
              owed. Your obligations under this Section are in
              addition to any rights that any Indemnitee may have at
              common law or otherwise.
            </TextParagraph>
          </TermsSubsection>
          <TermsSubsection title="19.2 Defined Terms.">
            <TextParagraph>
              “<b>Claim</b>” means each and every claim, request,
              accusation, allegation, assertion, complaint, petition,
              demand, suit, action, proceeding, and cause of action of
              every kind and description. “<b>Indemnitee</b>” means
              Health Wallet, its affiliates, and its and their
              respective officers, directors, shareholders, managers,
              members, agents, employees, representatives, successors,
              and assigns. “<b>Loss</b>” means each and every
              liability, loss, damage, and injury (including injury or
              damage to any property right, and injury, damage, or
              death to any Person), wound, wrong, hurt, harm, expense,
              deficiency, diminution in value, obligation, expenditure
              and disbursement of any kind or nature (including all
              fees, costs, and expenses of investigation, travel
              expenses, and value of time expended by personnel),
              settlement, fine, fee, cost, cost of court, and all
              expenses of litigation (including reasonable attorneys’
              fees) incident to any of the foregoing.
            </TextParagraph>
          </TermsSubsection>
        </TermsSection>

        <TermsSection title="20. RELEASE">
          <TextParagraph>
            In the event that you have a dispute with one or more
            users of the Service for any reason, you release Health
            Wallet (and its officers, directors, managers, members,
            agents, subsidiaries, representatives, and employees) from
            all claims, demands and damages (actual and consequential)
            of every kind and nature, known and unknown, suspected and
            unsuspected, disclosed and undisclosed, arising out of or
            in any way connected with such disputes. If you are a
            California resident, you waive California Civil Code
            §1542, which provides: “a general release does not extend
            to claims which the creditor does not know or suspect to
            exist in his favor at the time of executing the release,
            which if known by him must have materially affected his
            settlement with the debtor.”
          </TextParagraph>
        </TermsSection>

        <TermsSection title="21. TERM">
          <TextParagraph>
            Unless otherwise terminated by Health Wallet as provided
            for in this Agreement, this Agreement will remain in
            effect for so long as Health Wallet licenses the use of
            the Service to you.
          </TextParagraph>
        </TermsSection>

        <TermsSection title="22. TERMINATION">
          <TextParagraph>
            Health Wallet may, in its sole discretion and without
            prior notice, terminate your access to the Service for
            violations of this Agreement or other agreements or
            guidelines, which may be associated with your use of the
            Service, or if Health Wallet deems it necessary in its
            sole discretion. Further, Your Organization may terminate,
            or request that Health Wallet terminate your access to the
            Service. In any such event, you must destroy all copies of
            the Service and all of its component parts. Failure to
            abide by this Agreement is a material breach of this
            Agreement for which Health Wallet may pursue all rights
            and remedies it has pursuant to this Agreement, and any
            other rights and remedies it may have at law or in equity.
            You also agree that any violation by you of this Agreement
            will constitute an unlawful and unfair business practice
            and will cause irreparable harm to Health Wallet, for
            which monetary damages would be inadequate, and you
            consent to Health Wallet obtaining any injunctive or
            equitable relief that Health Wallet deems necessary or
            appropriate in such circumstances without the need to post
            a bond or satisfy any similar requirements. These remedies
            are in addition to any other remedies Health Wallet may
            have at law or in equity.
          </TextParagraph>
        </TermsSection>

        <TermsSection title="23. GOVERNMENT END USERS">
          <TextParagraph>
            The Service is a “commercial item” as that term is defined
            at FAR 2.101, consisting of “commercial computer software”
            and “commercial computer software documentation” as such
            terms are used in 48 C.F.R. 12.212 and is provided to the
            U.S. Government only as a commercial end item. Consistent
            with FAR 12.212 and DFARS 227.7202, all U.S. Government
            End Users acquire the Service with only those rights
            expressly set forth in this Agreement.
          </TextParagraph>
        </TermsSection>

        <TermsSection title="24. GENERAL PROVISIONS">
          <TermsSubsection title="24.1 Entire Agreement.">
            <TextParagraph>
              This Agreement, the Privacy Policy, and any other terms
              of use, or other guidelines (collectively, “Other
              Terms”) provided by Health Wallet through or in
              connection with the Service contain the entire
              understanding of the parties with respect to the subject
              matter of this Agreement and supersede all previous
              verbal and written agreements between the parties
              concerning the subject matter of this Agreement. To the
              extent that any Other Terms conflict with any provision
              of this Agreement, this Agreement will control. The
              Service is the property of Health Wallet. Health Wallet
              reserves the right to change, add or remove portions of
              this Agreement or the Service at any time and at its
              sole discretion. Your continued use of the Service
              following the posting or delivery to you of any changes
              means that you accept and agree to such changes.
            </TextParagraph>
          </TermsSubsection>
          <TermsSubsection title="24.2 Assignment.">
            <TextParagraph>
              This Agreement, and any rights or obligations in this
              Agreement will not be assigned by you without the prior
              written consent of Health Wallet. Any attempt to assign
              or transfer this Agreement other than in accordance with
              this provision will be null and void. Subject to the
              foregoing, this Agreement and its terms and provisions
              inure to the benefit of and are binding upon the parties
              and their respective successors, heirs, personal
              representatives, and assigns.
            </TextParagraph>
          </TermsSubsection>
          <TermsSubsection title="24.3 Governing Law/Waiver of Trial by Jury/Contract survival">
            <TextParagraph>
              You agree that all matters relating to your access to or
              use of the Service, including all disputes, will be
              governed by the laws of the United States and by the
              laws of the State of California without regard to its
              conflicts of laws provisions. You agree to the personal
              jurisdiction by and venue in the state and federal
              courts in San Mateo County, California, and waive any
              objection to such jurisdiction or venue. This Agreement
              will not be governed by the United Nations Convention on
              Contracts for the International Sale of Goods, the
              application of which is expressly excluded.
            </TextParagraph>
            <TextParagraph fontStyle="italic">
              If for any reason a court of competent jurisdiction
              finds any provision, or portion of any provision, to be
              unenforceable, the remainder of this Agreement will
              continue in full force and effect. Except where
              prohibited by law, the parties expressly waive trial by
              jury in any judicial proceeding involving any dispute,
              controversy, or claim to arise out of or relating to
              this agreement.
            </TextParagraph>
          </TermsSubsection>
          <TermsSubsection title=" 24.4 Notices.">
            <TextParagraph>
              All notices, requests, or consents sent to Health Wallet
              that are required or permitted under this Agreement must
              be in writing (including electronic form) and must be
              delivered to the address designated below in a notice
              served in a manner congruent with the rules for process
              of service in the state of California. Each notice,
              request, consent, or other communication will be given
              and will be effective: (1) if delivered by hand, when so
              delivered; (2) if delivered by nationally recognized
              overnight courier service or sent by United States
              Express Mail, upon confirmation of delivery; (3) if
              delivered by certified or registered mail, on the third
              following day after deposit with the United States
              Postal Service; or (4) if delivered by facsimile, upon
              confirmation of successful transmission, and if
              delivered by email, upon confirmation of receipt by the
              other party in writing by return email.
            </TextParagraph>
            <TextParagraph p={4} fontStyle="italic">
              Health Wallet LLC
              <br />
              101 Hickey Blvd, Ste A247, South San Francisco, CA, USA
              94080
            </TextParagraph>
          </TermsSubsection>
          <TermsSubsection title="24.5 Severability.">
            <TextParagraph>
              The provisions of this Agreement are severable. The
              invalidity, in whole or in part, of any provision of
              this Agreement will not affect the validity or
              enforceability of any other of its provisions. If one or
              more provisions of this Agreement are declared invalid
              or unenforceable, the remaining provisions will remain
              in full force and effect and will be construed in the
              broadest possible manner to effectuate the purposes of
              this Agreement. The parties further agree to replace
              such void or unenforceable provisions of this Agreement
              with valid and enforceable provisions that will achieve,
              to the extent possible, the economic, business, and
              other purposes of the void or unenforceable provisions.
            </TextParagraph>
          </TermsSubsection>
          <TermsSubsection title="24.6 Captions.">
            <TextParagraph>
              The headings and captions of this Agreement are inserted
              for reference convenience and do not define, limit, or
              describe the scope or intent of this Agreement or any
              section, paragraph, or provision of this Agreement.
              Unless otherwise expressly provided, the words
              “include(s),” “included,” or “including” do not limit
              the preceding words or terms. Pronouns will refer to the
              masculine, feminine, neuter, singular, or plural as the
              context will require.
            </TextParagraph>
          </TermsSubsection>
          <TermsSubsection title="24.7 Waiver.">
            <TextParagraph>
              The failure or delay of Health Wallet to exercise or
              enforce any rights or provision of this Agreement does
              not constitute a waiver of such right or provision.
            </TextParagraph>
          </TermsSubsection>
          <TermsSubsection title="24.8 Survival.">
            <TextParagraph>
              All provisions which must survive in order to give
              effect to their meaning will survive any expiration or
              termination of this Agreement, including without
              limitation, Sections 2, 15, 16, 17, 18 and 19 and all of
              your representations, warranties, and indemnification
              obligations, which will survive any expiration or
              termination of this Agreement indefinitely.
            </TextParagraph>
          </TermsSubsection>
        </TermsSection>
      </Stack>
      <AddendumA mt={16} />
    </Box>
  );
};

const AddendumA = (props) => {
  return (
    <Stack spacing={6} {...props}>
      <Text
        textAlign="center"
        fontWeight="bold"
        textDecorationLine="underline"
      >
        ADDENDUM A
      </Text>

      <Text fontWeight="bold">PRIVACY POLICY</Text>
      <TextParagraph>
        Health Wallet LLC (hereinafter “Health Wallet” and/or "health
        wallet" ) always makes its best efforts to protect the
        personal information of its users (hereinafter “user” and/or
        “member”) as it considers the protection of personal
        information saved under Health Wallet ™ service (hereinafter
        "Service") is significant. This Privacy Policy, effective from
        February 1 2020, explains why this service collects
        information as well as what measures are taken to handle the
        collection and contents of user data.
      </TextParagraph>

      <TermsSection title="1. PERSONAL INFORMATION COLLECTION ITEMS AND COLLECTION MEASURES">
        <TextParagraph>
          Health Wallet collects and saves information to provide a
          better service to the user.
        </TextParagraph>
        <TermsSubsection title="1.1 Information provided by users.">
          <TextParagraph>
            When joining as a member, personal information may be
            saved from intake questionnaires of varying length and
            detail which will be determined by the members choice of
            engagement in options provided by Health Wallet. The
            following is a sample of the basic information that may be
            collected and saved by Health Wallet for member
            convenience and Service use in record keeping.
          </TextParagraph>
          <TextParagraph fontStyle="italic">
            -email address, password, name, telephone number, intake
            question data, demographic information, to help provide
            you with good service and ensure identity fidelity and
            security
          </TextParagraph>
          <TextParagraph fontStyle="italic">
            -medical information, survey responses, health questions,
            to help our service provide possible clinical trial
            options for patients
          </TextParagraph>
          <TextParagraph fontStyle="italic">
            -clinical trial information, inclusion and exclusion
            criteria, and information from public databases, to help
            our service provide possible clinical trial options for
            patients
          </TextParagraph>
          <TextParagraph>
            These examples of information are neither necessarily
            complete nor required for use of Service.{' '}
            <b>
              This information will not be used for any marketing
              purpose, shared with any Third Party (defined as stated
              in Section 7 of the Terms of Service){' '}
              <i>unless expressly consented to by you.</i>{' '}
            </b>
            Further any such information will not be used outside of
            compliance with HIPAA. All patient information will be
            held anonymously using a user identification system.
          </TextParagraph>
        </TermsSubsection>
        <TermsSubsection title="1.2 Information collected during use of service">
          <Text fontWeight="bold">Device information</Text>
          <TextParagraph>
            A member’s device (mobile/PC), OS type and version,
            browser type, usage history, IP address, cookies, access
            token, session ID, country, language, route of entry,
            program installation, operation of major functions, and
            basic information of usage quantity are collected. This
            information is intended to be used to protect your
            privacy, by authorizing your logins, and ensuring our
            security protects all users.
          </TextParagraph>
          <Text fontWeight="bold">Contents information</Text>
          <TextParagraph>
            Files and their content in the user’s Health Wallet folder
            are collected and stored in accordance with HIPAA
            compliance, and are intended for the service selected by
            the user, and will not be used for any other services or
            users or be provided to any third parties without written
            or electronic consent.
          </TextParagraph>
          <Text fontWeight="bold">Payment information</Text>
          <TextParagraph>
            The browser type, usage history, IP address, cookies,
            country, language, program installation, operation of
            major functions, and basic information of usage quantity
            may be collected. Also, transaction information required
            during the payment process, such as credit card
            information, billing address, country, currency unit, and
            other related information, may be collected.
          </TextParagraph>
          <Text fontWeight="bold">
            Third party partner account information
          </Text>
          <TextParagraph>
            If the user allows others to access their own personal
            information via Google, Facebook, or any other service
            provider, the service stores such information in the
            server, so that the user may access the information later.
            This is governed by the Third Party defined herein Section
            7 of the referenced End User License Agreement.
          </TextParagraph>
        </TermsSubsection>
      </TermsSection>

      <TermsSection title="2. OBJECTIVES OF PERSONAL INFORMATION COLLECTION AND LEGAL BASIS">
        <TextParagraph>
          The company processes the user's personal information based
          on the following legal backgrounds.
        </TextParagraph>
        <TermsSubsection title="2.1 Contract execution">
          <TextParagraph>
            The company can process the necessary personal information
            for contract execution with the user. Health Wallet may
            request confirmation of user intention to create a new
            account.
          </TextParagraph>
          <TextParagraph>
            Health Wallet may use personal information to identify
            authorized users and to prevent dishonest use by illegal
            users and unauthorized utilization.
          </TextParagraph>
          <TextParagraph>
            Health Wallet may use personal information to provide a
            promised service to the users and settle fares if the
            users purchase or use the charged services. Such
            information will be held and disseminated in compliance
            with HIPAA.
          </TextParagraph>
          <TextParagraph>
            Health Wallet may use personal information to confirm that
            it is the valid user for the partner's service in case
            that there exists some special issues due to partnerships
            in a specific device in use or a specific route of access.
          </TextParagraph>
        </TermsSubsection>
      </TermsSection>

      <TermsSection title="3. RETENTION AND USE PERIOD OF PERSONAL INFORMATION">
        <TermsSubsection title="3.1 Health Wallet uses personal information in a limited capacity while providing Service. The personal information will be deleted without delay">
          <OrderedList styleType="lower-roman" spacing={4} pl={16}>
            <ListItem>
              if users request for the cancellation and withdraw
              consent to the collection and use of personal
              information,
            </ListItem>
            <ListItem>or the period of use expires.</ListItem>
          </OrderedList>
        </TermsSubsection>
        <TermsSubsection title="3.2 Health Wallet stores personal data in compliance with relevant local, state, and federal United States Laws. Examples of such follow.">
          <Text fontWeight="bold" pl={4}>
            The act of consumer protection in electronic commerce
          </Text>
          <UnorderedList spacing={4} pl={16}>
            <ListItem>
              Contract and revocation records: 5 years
            </ListItem>
            <ListItem>
              Payment and fund supply record: 5 years
            </ListItem>
            <ListItem>
              Consumer's complaint or legal disputes record: 3 years
              <br />
              <b>Electronic commerce transaction act</b>
            </ListItem>
            <ListItem>
              Electronic commerce record: 5 years
              <br />
              <b>Communication secret protection act</b>
            </ListItem>
            <ListItem>Login record: 3 months</ListItem>
          </UnorderedList>
        </TermsSubsection>
      </TermsSection>

      <TermsSection title="4. PERSONAL INFORMATION DISPOSAL PROCEDURES AND MEASURES">
        <TextParagraph>
          The disposal procedures and measures of personal information
          are as follows:
        </TextParagraph>
        <TermsSubsection title="4.1 Disposal procedures">
          <TextParagraph>
            A user's personal information is stored for a certain time
            period due to the information protection goals of Health
            Wallet according to the internal policy, and/or other laws
            and then disposed of once the goals are achieved.
            Information will not be used for any company purposes
            during this time outside of statutory or contractual
            requirements.
          </TextParagraph>
        </TermsSubsection>
        <TermsSubsection title="4.2 Disposal measures">
          <TextParagraph>
            The personal data printed on paper can be disposed of by
            destruction or incineration and an electrical format of
            personal data can be deleted by using a technical measure
            that prevents restoration of data.
          </TextParagraph>
        </TermsSubsection>
      </TermsSection>

      <TermsSection title="5. USER’S RIGHTS">
        <TextParagraph>
          Every user has the following rights (legal guardian for a
          child below the age of 14)
        </TextParagraph>
        <TermsSubsection title="5.1  Rights to receive information">
          <TextParagraph>
            Health Wallet will notify the user of legal issues and
            obtain consent before processing the personal information.
          </TextParagraph>
        </TermsSubsection>
        <TermsSubsection title="5.2  Rights to view and correct">
          <TextParagraph>
            A user can view or correct their personal information
            through their user portal, or send a support ticket for
            data that cannot be updated by the user. User access to
            independently correct and/or delete data on their account
            is not guaranteed at all times, but will be available
            through contact with Health Wallet within a reasonable
            period.
          </TextParagraph>
        </TermsSubsection>
        <TermsSubsection title="5.3  Rights to delete and revoke consents">
          <TextParagraph>
            A user can delete their personal information in their
            portal anytime or revoke consent on the personal
            information collection and use. Until the consent is
            revoked, all the items processed based on the consent are
            deemed to be lawful.
          </TextParagraph>
          <TextParagraph>
            A user can use the membership withdrawal menu in their
            portal to withdraw their membership. Once the membership
            is withdrawn, the personal information is processed
            according to "Retention and Use Period of Personal
            Information" above.
          </TextParagraph>
        </TermsSubsection>
        <TermsSubsection title="5.4  Rights to transfer data">
          <TextParagraph>
            A user can request a full download of their own data in
            their portal and transfer it to any other service
            provider.
          </TextParagraph>
        </TermsSubsection>
        <TermsSubsection title="5.5 Rights to object">
          <TextParagraph>
            A user has the right to object against direct marketing
            (should such ever occur) and can set rejection of
            marketing email receiving in their portal. Such initiation
            of direct marketing will require express consent from the
            user prior to initiation.
          </TextParagraph>
        </TermsSubsection>
      </TermsSection>

      <TermsSection title="6. PROVISION OF PERSONAL INFORMATION TO THIRD PARTIES">
        <TermsSubsection title="6.1 Agreement made by the users in advance">
          <TextParagraph>
            When personal data is provided for a Third Party (defined
            as referenced in Section 7 of the Terms of Service), by or
            through Health Wallet, Health Wallet may request explicit
            and individual agreements after notifying a person who
            receives personal information, purpose, items, possession,
            and period of use in advance.
          </TextParagraph>
        </TermsSubsection>
        <SubsectionTitle title="6.2 In case that relevant state and federal laws require Health Wallet compliance with sharing of the requested data Health Wallet warrants and guarantees full protection and defense of user data unless compelled affirmatively by a state or federal court." />
      </TermsSection>

      <TermsSection title="7. PRIVACY POLICY CHANGES">
        <TextParagraph>
          To reflect the changes in the service or the laws, Health
          Wallet can modify its privacy policy. In case of addition,
          deletion, or modification in the current privacy policy, the
          company will post the reason and details on the web site as
          well as notice at login, as necessary.
        </TextParagraph>
      </TermsSection>

      <TermsSection title="8. SECURITY">
        <TextParagraph>
          Health Wallet devotes its effort to protect information from
          illegal access, change, exposure, or deletion for the
          service users.The password requirements that are mandated to
          access user data for the service, and sensitive data (credit
          card information etc.) inputted to pay a charged service
          will be encrypted by SSL.
        </TextParagraph>
        <TextParagraph>
          Nevertheless, it is not guaranteed that the information sent
          to others by your choice is completely safe because wired
          and wireless networks do not provide complete security.
          Accordingly, information being accessed, exposed, changed,
          or damaged is possible because physical, technical, or
          management safety devices may be attacked and destroyed.
        </TextParagraph>
      </TermsSection>

      <TermsSection title="9. LANGUAGE VARIANCE">
        <TextParagraph>
          The content of this Privacy Policy may vary per language as
          countries have different legal requirements. If there is any
          conflict between the Privacy Policy in different languages,
          the policy in the language of the country shall prevail, and
          if such a policy does not exist, the policy in English shall
          prevail.
        </TextParagraph>
      </TermsSection>
    </Stack>
  );
};

export default TermsOfService;
