import React, { useState } from 'react';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  chakra,
  CloseButton,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Input,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { MFA_ACTIONS } from '../api/types';
import { mutate } from 'swr';
import { auth } from '../api/api-wrapper';
import ButtonRadioGroup from './ButtonRadioGroup';
import { ChatDots, Envelope } from 'phosphor-react';

const Activate = ({
  handleClose,
  setServerError,
  setSendCodeSuccess,
  setChosenMethod,
}) => {
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: { method: 'email' },
  });

  const handleActivate = async (data) => {
    try {
      const res = await auth.activateMfaMethod(data.method);

      if (res.status !== 200) {
        setServerError(['There was an error.  Please try again.']);
      } else {
        setChosenMethod({ name: data.method });
        setSendCodeSuccess(true);
      }
    } catch (error) {
      console.log(error);
      // setServerError([error]);
    }
  };

  return (
    <>
      <Heading size="md" fontWeight="bold">
        Set the primary device where you will receive authentication
        codes
      </Heading>
      <form
        id="change-mfa-form"
        onSubmit={handleSubmit(handleActivate)}
      >
        <Stack spacing={4}>
          <Controller
            control={control}
            name="method"
            render={({ value, onChange }) => (
              <ButtonRadioGroup
                name="method"
                value={value}
                onChange={onChange}
                options={[
                  {
                    label: 'Email',
                    icon: <Envelope />,
                    value: 'email',
                  },
                  {
                    label: 'SMS Text',
                    icon: <ChatDots />,
                    value: 'sms_phonenumber',
                  },
                ]}
              />
            )}
          />
          <Button
            w="full"
            type="submit"
            colorScheme="secondary"
            isLoading={isSubmitting}
            disabled={isSubmitting}
            loadingText="Sending..."
          >
            Send Code
          </Button>
        </Stack>
      </form>
      <Button
        variant="outline"
        disabled={isSubmitting}
        onClick={handleClose}
      >
        Cancel
      </Button>
    </>
  );
};

const Deactivate = ({
  handleClose,
  activeMethod,
  setServerError,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleDeactivate = async () => {
    try {
      setIsSubmitting(true);
      const res = await auth.deactivateMfa(activeMethod.name);

      setIsSubmitting(false);
      if (res.status !== 204) {
        setServerError(['There was an error.  Please try again.']);
      } else {
        mutate('auth/mfa/user-active-methods/', []);
        handleClose();
      }
    } catch (error) {
      setIsSubmitting(false);
      console.log(error);
      // setServerError([error]);
    }
  };

  return (
    <>
      <Heading size="md" fontWeight="bold">
        Deactivate multi-factor authentication?
      </Heading>
      <Button
        colorScheme="secondary"
        onClick={handleDeactivate}
        isLoading={isSubmitting}
        disabled={isSubmitting}
      >
        Deactivate
      </Button>
      <Button
        variant="outline"
        disabled={isSubmitting}
        onClick={handleClose}
      >
        Cancel
      </Button>
    </>
  );
};

const EnterCode = ({ activeMethod, setServerError, handleClose }) => {
  const {
    handleSubmit,
    errors,
    control,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: { code: '' },
  });

  const onSubmit = async (data) => {
    try {
      const res = await auth.confirmMfaMethod(
        activeMethod.name,
        data,
      );

      if (res.status !== 200 && res.status !== 204) {
        setServerError(['There was an error.  Please try again.']);
      } else {
        mutate('auth/mfa/user-active-methods/', []);
        handleClose();
      }
    } catch (error) {
      console.log(error);
      setServerError([error.message]);
    }
  };

  const handleSendCode = async () => {
    try {
      const res = await auth.sendMfaCode({
        method: activeMethod.name,
      });

      if (res.status !== 200) {
        setServerError(['There was an error.  Please try again.']);
      }
    } catch (error) {
      console.log(error);
      // setServerError([error]);
    }
  };

  return (
    <>
      <Heading size="md" fontWeight="bold">
        Please enter the 6-digit code you received by{' '}
        {activeMethod?.name.includes('sms') ? 'SMS' : 'email'}
      </Heading>
      <Text>
        It could take up to 10 minutes to receive your code.
      </Text>
      <chakra.form onSubmit={handleSubmit(onSubmit)}>
        <FormControl id="code" isInvalid={errors.code} mb={4}>
          <Controller
            as={Input}
            control={control}
            name="code"
            focusBorderColor="primary.400"
            borderColor="primary.300"
            shadow="sm"
            _hover={{
              borderColor: 'primary.400',
            }}
            rules={{
              required: 'Code is required',
              minLength: {
                value: 6,
                message: 'Must be 6 digits',
              },
              maxLength: {
                value: 6,
                message: 'Must be 6 digits',
              },
            }}
          />
          <FormErrorMessage>{errors.code?.message}</FormErrorMessage>
        </FormControl>
        <Button
          w="full"
          type="submit"
          colorScheme="secondary"
          isLoading={isSubmitting}
          disabled={isSubmitting}
          loadingText="Submitting..."
        >
          Submit
        </Button>
      </chakra.form>
      <Button
        variant="outline"
        disabled={isSubmitting}
        onClick={handleClose}
      >
        Cancel
      </Button>
      {activeMethod && (
        <Text width="full" align="center" fontWeight="medium">
          <Text as="span">Didn't receive a code?</Text>{' '}
          <Button
            variant="link"
            color="secondary.500"
            onClick={handleSendCode}
          >
            Resend
          </Button>
        </Text>
      )}
    </>
  );
};

const initialErrors = [];
const UpdateMfaModal = ({
  isOpen,
  onClose,
  activeMethod,
  action,
}) => {
  const [serverError, setServerError] = useState(initialErrors);
  const [sendCodeSuccess, setSendCodeSuccess] = useState(false);
  const [chosenMethod, setChosenMethod] = useState(null);

  const resetErrors = () => {
    setServerError(initialErrors);
  };

  const handleClose = () => {
    setServerError(initialErrors);
    setSendCodeSuccess(false);
    onClose();
  };

  const renderBody = () => {
    switch (action) {
      case MFA_ACTIONS.DEACTIVATE:
        return (
          <Deactivate
            activeMethod={activeMethod}
            handleClose={handleClose}
            setServerError={setServerError}
          />
        );
      case MFA_ACTIONS.ACTIVATE:
        return (
          <Activate
            handleClose={handleClose}
            setServerError={setServerError}
            setSendCodeSuccess={setSendCodeSuccess}
            setChosenMethod={setChosenMethod}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Update Multi-factor authentication</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Stack spacing={4}>
            {serverError.length > 0 && (
              <Alert status="error">
                <Flex flexDirection="column">
                  <Flex flexDirection="row">
                    <AlertIcon />
                    <AlertTitle>Sorry, there was an error</AlertTitle>
                  </Flex>
                  <UnorderedList px="8">
                    {serverError.map((error, index) => (
                      <ListItem key={index}>{error}</ListItem>
                    ))}
                  </UnorderedList>
                </Flex>
                <CloseButton
                  position="absolute"
                  right="8px"
                  top="8px"
                  onClick={resetErrors}
                />
              </Alert>
            )}
            {sendCodeSuccess ? (
              <EnterCode
                activeMethod={activeMethod ?? chosenMethod}
                handleClose={handleClose}
                setServerError={setServerError}
              />
            ) : (
              renderBody()
            )}
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UpdateMfaModal;
